import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { OpenLoginSignUp } from "../../../../../../Actions/ToggleSignupA";
import SignedOutRightSide from "./Components/SignedOutRightSide";
import SignedUpRightSide from "./Components/SignedUpRightSide";
import "./RightSide.css";
import { useEffect, useState, useContext } from "react";
import { useRef } from "react";
import { useHideOnClickOutside } from "../../../../../../Helpers/CustomHooks/Hide Elements/useOutsideChecker";
import CustomDropDown from '../../../../../custom-drop-down/CustomDropDown'
import { useLocationContext } from "../../../../../../context/locationContext";
import LocationSelector from "./LocationDropdowns";
import { signOut } from "firebase/auth";
import { auth, provider } from "../../../../../../Pages/LoginAndRegister/Components/UserSigning/Components/LeftSide/ContinueWIth/config";
import { LogOut } from "../../../../../../Actions/UserA";
import { SocketContext } from "../../../../../../App";

function StudentRightSide({ setShowSidebar, ShowSidebar }) {
    const Dispatch = useDispatch();
    const { UserInfo } = useSelector((store) => store.LoginSignupReducer);

    const { location, setLocation } = useLocationContext();
    const currentLocation = useLocation();

    // Function to handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocation((prev) => ({ ...prev, [name]: value }));
    };

    const LogOutUser = () => {
        signOut(auth, provider)
            .then(() => {
                console.log("Sign-out successful.2");
                socket?.emit("offline", { UserId: UserInfo?.UserId });
            })
            .catch((error) => {
                // An error happened.
            });
        Dispatch(LogOut());
    };

    const socket = useContext(SocketContext);

    return (
        <nav
            style={{ left: ShowSidebar ? "-48px" : "-2000px" }}
            // className="RightSideAndSideBar SideBarBG"
            className="RightSideAndSideBar SideBarBG"
        >

            <CustomDropDown buttonText={"Location"}>
                {/* Wrap the LocationSelector in a dropdown to save space */}
                <div className="space-y-[10px] p-[10px]">
                    <LocationSelector />
                </div>
            </CustomDropDown>
       {/*  <div className="w-[600px] flex gap-[10px] z-[9999]">
             <LocationSelector /> 
        </div> */}

                       {/*  <div className=" flex gap-[10px]">
                            <input
                                type="text"
                                name="country"
                                value={location.country}
                                onChange={handleChange}
                                placeholder="Country"
                                className="border-[2px] border-black text-[18px] h-[40px] w-[150px] pl-[15px]"
                            />
                            <input
                                type="text"
                                name="state"
                                value={location.state}
                                onChange={handleChange}
                                placeholder="State/Province"
                               className="border-[2px] border-black text-[18px] h-[40px] w-[150px] pl-[15px]"
                            />
                             <input
                                type="text"
                                name="city"
                                value={location.city}
                                onChange={handleChange}
                                placeholder="City"
                               className="border-[2px] border-black text-[18px] h-[40px] w-[150px] pl-[15px]"
                            />
                            <input
                                type="text"
                                name="postalCode"
                                value={location.postalCode}
                                onChange={handleChange}
                                placeholder="Postal Code"
                               className="border-[2px] border-black text-[18px] h-[40px] w-[150px] pl-[15px]"
                            />
                        </div> */}


            <CustomDropDown buttonText={"Knowledge"}>
                <div className="space-y-1">
                    <Link
                        className="SemiBold DropDownBorder flex  w-full justify-between gap-2 whitespace-nowrap py-4 px-6 text-left text-3xs text-[#4D4F5C] no-underline hover:text-[#A1A3EF] "
                        to="/get-ready"
                    >
                        Get Ready
                    </Link>
                    <Link
                        className="SemiBold DropDownBorder flex w-full justify-between gap-2 whitespace-nowrap py-4 px-6 text-left text-3xs text-[#4D4F5C] no-underline hover:text-[#A1A3EF] "
                        to="/get-ready/knowledge-test"
                    >
                        Knowledge Test
                    </Link>
                    <Link
                        className="SemiBold DropDownBorder flex  w-full justify-between gap-2 whitespace-nowrap py-4 px-6 text-left text-3xs text-[#4D4F5C] no-underline hover:text-[#A1A3EF] "
                        to="/get-ready/knowledge-test-selection"
                    >
                        Knowledge Test Quiz
                    </Link>
                    <Link
                        className="SemiBold DropDownBorder flex  w-full justify-between gap-2 whitespace-nowrap py-4 px-6 text-left text-3xs text-[#4D4F5C] no-underline hover:text-[#A1A3EF] "
                        to="#"
                    >
                        Road Test
                    </Link>
                </div>
            </CustomDropDown>

            {/* <Link
                className="HeaderRightSide-LinkStyle SemiBold cursor-pointer whitespace-nowrap text-sm"
                to="/Ebooks"
            >
                E-Books
            </Link> */}
            <Link
                className="HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                to="/contact-us"
            >
                Contact
            </Link>

            {UserInfo && UserInfo.User === "Student" && (
            <Link
                     className="HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                     to="/mycourses/enrolledcourses"
                >
                <div className="flex items-center gap-[30px] cursor-pointer lg:hidden">
                    <p  className="HeaderRightSide-LinkStyle SemiBold">My Courses</p>
                    <img 
                    src={require("./Assets/MyCoursesIcon.svg").default} 
                    alt="courses Icon"
                    className="w-[20x] h-[20px]" />
                </div>
            </Link>
            )}

            {UserInfo && UserInfo.User === "Student" && (
            <Link
                     className="HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                    //  to="/mycourses/enrolledcourses"
                >
                <div className="flex items-center gap-[30px] cursor-pointer lg:hidden">
                    <p  className="HeaderRightSide-LinkStyle SemiBold">English</p>
                    <img 
                    src={require("./Assets/TranslationIcon.svg").default} 
                    alt="courses Icon"
                    className="w-[20x] h-[20px]" />
                </div>
            </Link>
            )}

            {UserInfo && UserInfo.User === "Student" && (
            <Link
                     className="HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                    //  to="/mycourses/enrolledcourses"
                >
                <div className="flex items-center gap-[30px] cursor-pointer lg:hidden">
                    <p  className="HeaderRightSide-LinkStyle SemiBold">USD</p>
                    <img 
                    src={require("./Assets/DollarIcon.svg").default} 
                    alt="courses Icon"
                    className="w-[20x] h-[20px]" />
                </div>
            </Link>
            )}

            {UserInfo && UserInfo.User === "Student" && (
            <Link
                     className="HeaderRightSide-LinkStyle SemiBold cursor-pointer lg:hidden"
                     to="/student/settings"
                    //  onClick={LogOutUser}
                >
                    <p  className="HeaderRightSide-LinkStyle SemiBold">Settings</p>
            </Link>
            )}

            {UserInfo && UserInfo.User === "Student" && (
                <Link
                        className="HeaderRightSide-LinkStyle SemiBold cursor-pointer lg:hidden"
                        onClick={LogOutUser}
                    >
                        <p  className="HeaderRightSide-LinkStyle SemiBold">Logout</p>
                </Link>
            )}
            {/* <Link
                className=" HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                to="/Institutes"
            >
                Institutes
            </Link> */}
            {/* <Link
                className=" HeaderRightSide-LinkStyle SemiBold relative cursor-pointer"
                to="/Courses"
            >
                {" "}
                Courses
                <sub style={{ fontSize: 30, position: "absolute", bottom: "-17px", right: "-18px" }}>&#8963;</sub>
            </Link> */}

            {UserInfo && UserInfo.User === "Student" ? (
                <SignedUpRightSide setShowSidebar={setShowSidebar} />
            ) : (
                <SignedOutRightSide setShowSidebar={setShowSidebar} />
            )}
            {!UserInfo?.UserName ? (
                <>
                    <button
                        className="HeaderRightSide-LinkStyle SemiBold lg:hidden"
                        type="button"
                        onClick={() => {
                            setShowSidebar(false);
                            Dispatch(OpenLoginSignUp("Sign In", true));
                        }}
                    >
                        Login
                    </button>
                    <button
                        className="HeaderRightSide-LinkStyle SemiBold lg:hidden"
                        type="button"
                        onClick={() => {
                            setShowSidebar(false);
                            Dispatch(OpenLoginSignUp("Sign Up", true));
                        }}
                    >
                        Sign up
                    </button>
                </>
            ) : null}
            <button
                type="button"
                className="CrossButton"
                onClick={() => setShowSidebar(false)}
            >
                <img src={require("./Assets/CrossIcon.svg").default} alt="" />
            </button>
        </nav>
    );
}

export default StudentRightSide;
// function Dropdown({ children }) {
//     const [isOpen, setIsOpen] = useState(false);
//     const dropdownRef = useRef(null);

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (
//                 dropdownRef.current &&
//                 !dropdownRef.current.contains(event.target)
//             ) {
//                 setIsOpen(false);
//             }
//         }

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [dropdownRef]);

//     return (
//         <div className="relative  z-20 " ref={dropdownRef}>
//             <span
//                 className=" HeaderRightSide-LinkStyle SemiBold cursor-pointer"
//                 onClick={() => setIsOpen(!isOpen)}
//             >
//                 GetReady
//             </span>
//             {isOpen && (
//                 <div className="absolute -left-16 top-10 w-fit  bg-white p-2 shadow-[0px_2px_10px_#00000030]">
//                     <span>
//                         <img
//                             className=" absolute -top-2 left-20 z-10 drop-shadow "
//                             src={
//                                 require("../RightSide/Assets/PointIcon.svg")
//                                     .default
//                             }
//                             alt=""
//                         />
//                     </span>
//                     <div onClick={(e) => e.stopPropagation()}>{children}</div>
//                 </div>
//             )}
//         </div>
//     );
// }
