import React from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import InstructorInfo from "./Components/InstructorInfo/InstructorInfo";
import "./AddInstructor.css";
import { useState } from "react";
import { AddInstructorA, AddInstructorWithMultipleRoleA, GetStaffDataforInstructor, UpdateRoleToInstructor } from "../../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetLicenseTypes } from "../../../Actions/CategoryA";
import InstructorPopup from "./Components/Popup/InstructorPopup";
import {
    ScrollElement,
    ScrollSingleElement,
} from "../../../Helpers/ScrolltoElement";
import { useLocation, useParams } from "react-router-dom";


const AddInstructorChild = () => {
    const { error, loading, StaffTableData } = useSelector((Store) => Store.InstructorReducer);
    console.log("StaffTableData is:",StaffTableData)

    const StaffIdCardNumber = StaffTableData?.data?.staff?.StaffIdCardNo || ""
    console.log("StaffIdCardNumber is:",StaffIdCardNumber)

    const [InstructorData, setInstructorData] = useState({
        FirstName: "",
        LastName: "",
        Address: "",
        PostalCode: "",
        Province: "",
        City: "",
        Country: "",
        Email: "",
        PhoneNumber: "",
        EmergencyPhoneNumber: "",
        Gender: "",
        DOB: "",
        ProfileImage: "",
        TrainerPermitImage: "",
    });
    const [LicenseImages, setLicenseImages] = useState([]);
    const [SLicenseImages, setSLicenseImages] = useState([]);
    const [SpecialityName, setSpecialityName] = useState([
        // InstructorData?.Speciality,
    ]);

    // console.log("InstructorData", InstructorData);
    // console.log("SpecialityName", SpecialityName);

    const [Success, setSuccess] = useState();
    const [Err, setErr] = useState({});
    const Dispatch = useDispatch();
    // console.log('InstructorData', InstructorData)
    // console.log("LicencenseImage", LicenseImages);
    // console.log("LicencenseImage", SLicenseImages);

    // console.log("error while creating Instructor", error);
    useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);
    useEffect(() => {
        Dispatch(GetLicenseTypes());
    }, [Dispatch]);

    const location = useLocation();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const roles = queryParams.get("roles");
    const userStaffIdNo = queryParams.get("userStaffIdNo");
    const UserFK = queryParams.get("UserFK");

    console.log("Roles:", roles); 
    console.log("userStaffIdNo:", userStaffIdNo); 
    console.log("UserFK:", UserFK);      

    const SubmitForm = (e) => {
        // e.preventDefault();
        SubmitInstructorData(
            e,
            Dispatch,
            setSuccess,
            InstructorData,
            Err,
            setErr,
            LicenseImages,
            SLicenseImages,
            SpecialityName,
            setLicenseImages,
            setSLicenseImages,
            roles,
            userStaffIdNo,
            UserFK,
            StaffIdCardNumber
        );
    };

    useEffect(()=>{
        if(userStaffIdNo && UserFK){
            console.log("userUniqueIdNo")
            Dispatch(GetStaffDataforInstructor(userStaffIdNo,UserFK))
        }
    },[])

    useEffect(() => {
        if (StaffTableData?.data) {
            const { 
                staff: {
                    Address, 
                    PostalCode, 
                    Province, 
                    City, 
                    Country, 
                    PhoneNumber, 
                    EmergencyPhoneNumber, 
                    Gender, 
                    DOB, 
                    ProfileImage
                }, 
                user: {
                    FirstName, 
                    LastName, 
                    Email
                }
            } = StaffTableData.data;
    
            // Update InstructorData state with the matching fields
            setInstructorData((prevState) => ({
                ...prevState, // Retain any existing values in case some fields are not mapped
                Address: Address || prevState.Address,
                PostalCode: PostalCode || prevState.PostalCode,
                Province: Province || prevState.Province,
                City: City || prevState.City,
                Country: Country || prevState.Country,
                PhoneNumber: PhoneNumber || prevState.PhoneNumber,
                EmergencyPhoneNumber: EmergencyPhoneNumber || prevState.EmergencyPhoneNumber,
                Gender: Gender || prevState.Gender,
                DOB: DOB || prevState.DOB,
                ProfileImage: ProfileImage || prevState.ProfileImage,
                FirstName: FirstName || prevState.FirstName,
                LastName: LastName || prevState.LastName,
                Email: Email || prevState.Email,
            }));
        }
    }, [StaffTableData]); // Trigger this effect when StaffTableData updates
    
        
    useEffect(() => {
        if (error?.response?.data) {
            setErr(error?.response?.data);
            if (error?.response?.data)
                for (const [key] of Object.entries(error?.response?.data)) {
                    if (key) {
                        ScrollSingleElement(key, 200);
                        break;
                    }
                }
        }
    }, [error]);
    // console.log("ErrErr", Err)
    // console.log(Object.entries(InstructorData))

    return !loading ? (
        <form
            // className="flex w-full flex-col items-center gap-10 bg-[#F7F7F7] py-10"
            className="mb-5 mt-5 flex w-full flex-col  items-center bg-[#FFFFFF] "
            onSubmit={SubmitForm}
        >
            {/* <div className=" "> */}
            {/* <InstructorTop /> */}
            <div className="flex w-full justify-center ">
                <InstructorInfo
                    LicenseImages={LicenseImages}
                    InstructorData={InstructorData}
                    setInstructorData={setInstructorData}
                    Err={Err}
                    setErr={setErr}
                    setLicenseImages={setLicenseImages}
                    SLicenseImages={SLicenseImages}
                    setSLicenseImages={setSLicenseImages}
                    setSpecialityName={setSpecialityName}
                    SpecialityName={SpecialityName}
                />
            </div>
            {/* </div> */}

            <button type="submit" className="Instructor-SubmitBtn">
                Add Instructor
            </button>
            {Success ? <InstructorPopup /> : null}
        </form>
    ) : (
        // <LoadingSpinner />
        <h1>eror</h1>
    );
};
export function validateEmail(email) {
    if (!email) return false;
    // const regex =
    //     /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)/;

    // const regex = /^[a-zA-Z0-9._]+@[a-z]+\.[a-z]{2-6}$/;
    const regex =
        /^(([^<>()[\]\\.,;?:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
}
export function validatePhoneNumber(phoneNumber) {
    if (!phoneNumber) return false;
    const pattern = /^\+1-\d{3}-\d{3}-\d{4}$/;
    return pattern.test(phoneNumber);
}
const SubmitInstructorData = (
    e,
    Dispatch,
    setSuccess,
    InstructorData,
    Err,
    setErr,
    LicenseImages,
    SLicenseImages,
    SpecialityName,
    setLicenseImages,
    setSLicenseImages,
    roles,
    userStaffIdNo,
    UserFK,
    StaffIdCardNumber
) => {
    e.preventDefault();
    console.log("role in submit instructor data is:",roles)
    console.log("InstructorDatain submit instructor data is:",InstructorData)


    // console.log("setSuccess", setSuccess);
    // console.log("InstructorData", InstructorData);
    // console.log("Err", Err);
    // console.log("setErr", setErr);

    // console.log("LicenseImages", LicenseImages);
    // console.log("setLicenseImages", setLicenseImages);
    // console.log("LINE~144: INSTRUCTOR DATA", InstructorData);
    // let dataToBeSent = JSON.parse(JSON.stringify(InstructorData));

    let Errors = { ...Err };

    // if (SpecialityName && SpecialityName.lenght < 0) {
    //     setErr({ ...Err, SpecialityName: "Speciality* is requiredddd" });
    //     ScrollSingleElement("SpecialityName", 200);
    //     return;
    // }

    if (
        InstructorData.PhoneNumber &&
        !validatePhoneNumber(InstructorData.PhoneNumber)
    ) {
        setErr({ ...Err, PhoneNumber: "Invalid Phone Number" });
        ScrollSingleElement("PhoneNumber", 200);
        return;
    }

    if (
        InstructorData.EmergencyPhoneNumber &&
        !validatePhoneNumber(InstructorData.EmergencyPhoneNumber)
    ) {
        setErr({ ...Err, EmergencyPhoneNumber: "Invalid Phone Number" });
        ScrollSingleElement("EmergencyPhoneNumber", 200);
        return;
    }

    if (InstructorData.Email && !validateEmail(InstructorData.Email)) {
        setErr({ ...Err, Email: "Invalid email address" });
        ScrollSingleElement("Email", 200);
        return;
    }

    if (InstructorData.Address && InstructorData?.Address === "") {
        setErr({ ...Err, Address: "Address* is requiredddd" });
        ScrollSingleElement("Address", 200);
        return;
    }

    const InstructorFormData = new FormData();
    let InstructorDataNoImage = {};

    for (let [key, value] of Object.entries(InstructorData)) {
        // console.log("key", key);
        // console.log("value", value);

        if (
            !value ||
            (typeof value === "string" && value?.trim() === "") ||
            (Array.isArray(value) && value.length === -1)
        ) {
            Errors[key] = true;
        } else if (typeof Errors[key] !== "string") {
            Errors[key] = undefined;
        }
    }

    setErr({ ...Errors });

    ScrollElement(Errors);

    // console.log("before Error", Errors);

    if (CheckEmptyObj(Errors)) {
        console.log("before Error11", Errors);
        // return;
    }
    // console.log("After Error", Errors);

    // -------------

    for (let [key, value] of Object.entries(InstructorData)) {
        console.log("key:", key, "value:", value);
        if (key.indexOf("Image") > -1) {
            InstructorFormData.append(key, value);
        } else
            InstructorDataNoImage = { ...InstructorDataNoImage, [key]: value };
    }

    //-----

    InstructorFormData.append(
        "IntructorInfo",
        JSON.stringify(InstructorDataNoImage)
    );
    // console.log("LINE~253: InstructorData", dataToBeSent);
    // const formData = new FormData();
    // formData.append("FirstName", dataToBeSent.FirstName);
    // formData.append("LastName", dataToBeSent.LastName);
    // formData.append("Address", dataToBeSent.Address);
    // formData.append("PostalCode", dataToBeSent.PostalCode);
    // formData.append("Province", dataToBeSent.Province);
    // formData.append("City", dataToBeSent.City);
    // formData.append("Country", dataToBeSent.Country);
    // formData.append("Email", dataToBeSent.Email);
    // formData.append("PhoneNumber", dataToBeSent.PhoneNumber);
    // formData.append("EmergencyPhoneNumber", dataToBeSent.EmergencyPhoneNumber);
    // formData.append("Gender", dataToBeSent.Gender);
    // formData.append("DOB", dataToBeSent.DOB);
    // formData.append("Speciality", dataToBeSent.Speciality);
    // // console.log("PROFILE IMAGE", dataToBeSent.ProfileImage);
    // formData.append("ProfileImage", dataToBeSent.ProfileImage);
    // formData.append("TrainerPermitImage", dataToBeSent.TrainerPermitImage);

    // dataToBeSent?.InstructorLicenseDetails?.forEach((license, index) => {
    //     // formData.append("LicenseImage", license.LicenseImage);
    //     formData.append("LicenseNumber", license.LicenseNumber);
    //     formData.append("LicenseExpiry", license.LicenseNumber);
    //     formData.append("LicenseNumber", license.LicenseTypeFK);
    // });

    // formData.append(
    //     "InstructorLicenseDetails",
    //     JSON.stringify(dataToBeSent?.InstructorLicenseDetails)
    // );
    for (let i = 0; i < LicenseImages.length; i++) {
        console.log("Single LicencenseImage", LicenseImages[i]);
        InstructorFormData.append("LicenseImages", LicenseImages[i]);
    }
    for (let i = 0; i < SLicenseImages.length; i++) {
        console.log("Single LicencenseImage", SLicenseImages[i]);
        InstructorFormData.append("SLicenseImages", SLicenseImages[i]);
    }
    console.log("InstructorFormData", InstructorFormData);

    // InstructorData.InstructorLicenseDetails.forEach((license, index) => {
    //     formData.append(`LicenseImage_${index}`, license.LicenseImage);
    //     formData.append(`LicenseNumber`, license.LicenseNumber);
    //     formData.append(`LicenseExpiry_${index}`, license.LicenseExpiry);
    //     formData.append(`LicenseTypeFK_${index}`, license.LicenseTypeFK);
    // });

    console.log("Final FormData before dispatch:");
        for (let [key, value] of InstructorFormData.entries()) {
            console.log(key, value);
        }

    if(roles==="Staff" || roles==="Supervisor" || roles==="Admin"){
        Dispatch(AddInstructorWithMultipleRoleA(InstructorFormData, setSuccess, roles));
    }else if ( userStaffIdNo && UserFK){
        Dispatch(UpdateRoleToInstructor(InstructorFormData,setSuccess,StaffIdCardNumber,UserFK,userStaffIdNo))
    }else{
        // Dispatch(AddInstructorA(InstructorFormData, setSuccess));
        Dispatch(AddInstructorA(InstructorFormData, setSuccess))
        .then(() => {
            // Handle success, e.g., show a success message
           console.log("Instructor added successfully!");
        })
        .catch((error) => {
             // Check if the error contains specific fields
        let errorMessage = "Something went wrong";
        
        if (error?.errorType === "ValidationError") {
            // Concatenate all validation errors
            const licenseErrors = error?.LicenseNumbers?.join(", ") || "";
            const specialLicenseErrors = error?.SpecialLicenseNumbers?.join(", ") || "";

            errorMessage = `Validation Errors: ${licenseErrors} ${specialLicenseErrors}`.trim();
        } else if (typeof error === "string") {
            errorMessage = error;
        }

        alert(errorMessage); // Show the meaningful error message
            // alert(`Error: ${error}`);
        });
    }
    
    setLicenseImages([]);
    setSLicenseImages([]);
    // Dispatch(AddInstructorA(formData, setSuccess));
};

const AddInstructor = () => <InstituteTemplate Element={AddInstructorChild} />;

function CheckEmptyObj(OBJ = {}) {
    let Check = false;
    for (const [, value] of Object.entries(OBJ)) {
        // console.log("valuevaluevalue", value);
        if (
            value ||
            (typeof value === "string" && value?.trim() !== "") ||
            (Array.isArray(value) && value.length === 0)
        )
            Check = true;
    }
    return Check;
}

// function CheckEmptyObj(OBJ = {}) {
//     let Check = false;
//     for (const [, value] of Object.entries(OBJ)) {
//         if (value !== undefined && value !== null && (value !== '' || (Array.isArray(value) && value.length > -1))) {
//             return Check = true;
//         }
//     }
//     return Check;
// }
// function CheckEmptyObj(OBJ = {}) {
//     for (const [, value] of Object.entries(OBJ)) {
//         if (value !== undefined && value !== null && (value !== '' || (Array.isArray(value) && value.length > 0))) {
//             return false; // Return true if any non-empty value is found
//         }
//     }
//     return true; // Return false if all values are empty
// }

export default AddInstructor;
