import React,{useState, useEffect} from "react";
import HomeIcon from "./Assets/homeIcon.svg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SOSBuildingIcon from "./Assets/SOSBuildingIcon.svg"
import PhoneFeature from "./Assets/PhoneFeatureIcon.svg";
import HarassmentIcon from "./Assets/HarassmentIcon.svg";
import SOSDetailsAccordion from "./SOSAccordionSection";
import ShareLocationBeacon from "./Assets/ShareLocationBeacon.svg"
import DrivingSchool from "./Assets/DrivingSchool.svg"
import AlertTriangle from "./Assets/AlertTriangle.svg"
import SOSMobileIcon from "./Assets/SOSMobileIcon.svg"

function SOSMobileFeaturePopup({setOpenSOSMobilePopup,setOpenSOSEmergencyMobileTimerPopup,
     setSelectedSOSProvidedFeatures, setOpenSOSMobileBeaconClickPopup, type}) {
        const [startY, setStartY] = useState(0); // Track the starting Y position
        const [currentY, setCurrentY] = useState(0); // Track the current Y position
        const [isDragging, setIsDragging] = useState(false);
      
        // Handle touch start
        const handleTouchStart = (e) => {
          setStartY(e.touches[0].clientY);
          setIsDragging(true);
        };
      
        // Handle touch move
        const handleTouchMove = (e) => {
          if (!isDragging) return;
          setCurrentY(e.touches[0].clientY);
        };
      
        // Handle touch end
        const handleTouchEnd = () => {
          setIsDragging(false);
          const dragDistance = currentY - startY;
      
          // Close popup if dragged down beyond the threshold
          if (dragDistance > 70) {
            setOpenSOSMobilePopup(false);
          }
        };

    const handleSOSFeatureClick = value =>{
        setOpenSOSEmergencyMobileTimerPopup(true)
        setSelectedSOSProvidedFeatures(value)
        setOpenSOSMobilePopup(false)
    }
    const handleSOSBeaconClick = value =>{
        setOpenSOSMobileBeaconClickPopup(true)
        setSelectedSOSProvidedFeatures(value)
        setOpenSOSMobilePopup(false)
    }

    // Handle outside click
    const handleOverlayClick = () => {
        setOpenSOSMobilePopup(false);
    };
    return(
        <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex justify-center items-end"
        onClick={handleOverlayClick} // Close popup on overlay click
        >
            <div className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-white to-[#D6D8FF] rounded-tr-[24px] rounded-tl-[24px] z-[9999] pb-[10px]"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                >
                <div className="w-full flex flex-col justify-center items-center mt-[10px] gap-[10px]">
                    <div className="w-[61px] h-[5px] bg-[#E6E6E6] rounded-full">
                    </div>
                    {type == "Student" && (
                        <img src={SOSMobileIcon} alt="SOSMobileIcon" />
                    )}
                </div>

                <div className="flex flex-col gap-[20px] px-[14px] py-[15px]">
                    {type !== "Student" && (
                    <p className="text-[20px] font-bold">Beacon</p>
                    )}

                    {type === "Student" ? ( 
                        <p className="text-[14px] text-[#000000] text-center font-bold">Use SOS In Case Of Emergency Only</p>
                    ) :
                    (
                        <p className="text-[14px] text-[#636363]">Select an option below for immediate help.</p>
                    )}

                    {type === "Student" && (
                        <SOSDetailsAccordion />
                    )}

                    <div className="flex flex-wrap gap-[7px]">
                        <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                        onClick={() => handleSOSFeatureClick('911')} // Pass "911" as the value
                        >
                            <p className="text-[13px] font-Poppins font-semibold">Call 911 Emergency</p>
                            <div className="flex w-full justify-between items-end px-[6px] ">
                                <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                <img src={PhoneFeature} alt="HomeIcon" />
                            </div>
                        </div>

                        {type === "Student" && ( 
                        <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[5px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                        // onClick={() => handleSOSBeaconClick('Location With Beacon')}
                        onClick={() => handleSOSFeatureClick('Location With Beacon')}>
                            <p className="text-[13px] font-Poppins font-semibold">Send Emergency alert to beacon</p>
                            <div className="flex w-full justify-between items-end px-[6px] mt-[10px] ">
                                <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                <img src={ShareLocationBeacon} alt="ShareLocationBeacon" />
                            </div>
                        </div> 
                        )}

                        <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                        onClick={() => handleSOSFeatureClick('Call Driving School')} >
                            <p className="text-[13px] font-Poppins font-semibold">Call Driving Institute</p>
                            <div className="flex w-full justify-between items-end px-[6px] ">
                                <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                <img src={DrivingSchool} alt="DrivingSchool" />
                            </div>
                        </div>
                        
                        {type !== "Student" && (
                            <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                            onClick={() => handleSOSFeatureClick('Harassment')}>
                                <p className="text-[13px] font-Poppins font-semibold">Report Harassment</p>
                                <div className="flex w-full justify-between items-end px-[6px] ">
                                    <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                    <img src={HarassmentIcon} alt="HarassmentIcon" />
                                </div>
                            </div>
                        )}

                        {type === "Student" && ( 
                        <div className="w-full flex justify-center items-center gap-[10px]">
                            <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                            onClick={() => handleSOSFeatureClick('Harassment')}>
                                <p className="text-[13px] font-Poppins font-semibold">Report Harassment</p>
                                <div className="flex w-full justify-between items-end px-[6px] ">
                                    <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                    <img src={HarassmentIcon} alt="HarassmentIcon" />
                                </div>
                            </div>

                            <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[5px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                                onClick={() => handleSOSBeaconClick('Live Location Link')}
                                // onClick={() => handleSOSFeatureClick('Location With Beacon')}
                            >
                                <p className="text-[13px] font-Poppins font-semibold">Share Live Location Link</p>
                                <div className="flex w-full justify-between items-end px-[6px] mt-[10px]">
                                    <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                                    <img src={AlertTriangle} alt="AlertTriangle" />
                                </div>
                            </div> 
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SOSMobileFeaturePopup;
