/* import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerF,
  Polyline,
  PolylineF,
} from "@react-google-maps/api";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useMap } from "../../../Components/InVehicleClass/GoogleMap/mapHook";
import { getCompletedLocation } from "../api";

const TrackAdminGoogleMap = (props) => {
  const {
    onLoadMap,
    currentPosition,
    currentRoute,
    locationKey,
    modal: { open, onClose },
  } = props;
  const mapObj = useMap();
  const [savedCurrentPosition, setSavedCurrentPosition] = useState();
  const [savedCurrentRoute, setSavedCurrentRoute] = useState([]);

  useEffect(() => {
    if (currentPosition && currentRoute) {
      setSavedCurrentPosition(currentPosition);
      setSavedCurrentRoute(currentRoute);
    } else {
      getSavedLocation();
    }
  }, [currentPosition]);

  const getSavedLocation = async () => {
    getCompletedLocation(locationKey)
      .then((res) => {
        if (res.data?.liveLocation.length > 0) {
          const updateRoute = (index) => {
            if (index < res.data?.liveLocation.length) {
              setSavedCurrentRoute((oldRoute) => [
                ...oldRoute,
                res.data?.liveLocation[index],
              ]);
              setSavedCurrentPosition(res.data?.liveLocation[index]);
              setTimeout(() => {
                updateRoute(index + 1);
              }, 10000);
            }
          };

          updateRoute(0);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
        {mapObj.isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: 650 }}
            center={savedCurrentPosition}
            onLoad={onLoadMap}
            zoom={12}
          >
            {savedCurrentRoute.length > 0 && (
              <PolylineF
                path={savedCurrentRoute}
                options={{ strokeColor: "#36454F" }}
              />
            )}

            {savedCurrentPosition && (
              <MarkerF position={savedCurrentPosition} />
            )}
          </GoogleMap>
        ) : (
          <p>Map Loading...</p>
        )}
     </div>
  );
};

export default TrackAdminGoogleMap;
 */

// import React, { useEffect, useState } from "react";
// import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";
// import { useMap } from "../../../Components/InVehicleClass/GoogleMap/mapHook";
// import { getCompletedLocation } from "../api";

// const TrackAdminGoogleMap = (props) => {
//   const { onLoadMap, locationKey, modal: { open, onClose }, videoTime } = props;
//   const [savedCurrentPosition, setSavedCurrentPosition] = useState();
//   const [savedCurrentRoute, setSavedCurrentRoute] = useState([]);
//   const [map, setMap] = useState(null);
//   const mapObj = useMap();
//   console.log("video time in TrackAdminGoogleMap is:",videoTime)


//   useEffect(() => {
//     getSavedLocation();
//   }, [locationKey]);

//   const getSavedLocation = async () => {
//     const res = await getCompletedLocation(locationKey);
//     const locations = res.data?.liveLocation || [];
//     setSavedCurrentRoute(locations);
//   };

//   useEffect(() => {
//     if (videoTime != null && savedCurrentRoute.length > 0) {
//       // Find the closest location based on the video time
//       const currentLocation = savedCurrentRoute.find((loc, index) => {
//         const nextLoc = savedCurrentRoute[index + 1];
//         return nextLoc && videoTime >= loc.timestamp && videoTime < nextLoc.timestamp;
//       });
//       if (currentLocation) {
//         setSavedCurrentPosition(currentLocation);
//         map?.panTo(currentLocation); // Center the map on the current location
//       }
//     }
//   }, [videoTime, savedCurrentRoute, map]);

//   return (
//     <div style={{ width: "100%", height: "100%" }}>
//       {mapObj.isLoaded ? (
//         <GoogleMap
//           mapContainerStyle={{ width: "100%", height: 650 }}
//           center={savedCurrentPosition}
//           onLoad={(map) => {
//             setMap(map);
//             onLoadMap?.(map);
//           }}
//           zoom={12}
//         >
//           {savedCurrentRoute.length > 0 && (
//             <PolylineF
//               path={savedCurrentRoute}
//               options={{ strokeColor: "#36454F" }}
//             />
//           )}
//           {savedCurrentPosition && <MarkerF position={savedCurrentPosition} />}
//         </GoogleMap>
//       ) : (
//         <p>Map Loading...</p>
//       )}
//     </div>
//   );
// };

// export default TrackAdminGoogleMap;

import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";
import { useMap } from "../../../Components/InVehicleClass/GoogleMap/mapHook";
import { getCompletedLocation } from "../api";

const TrackAdminGoogleMap = (props) => {
  const { onLoadMap, locationKey, modal: { open, onClose }, videoTime, onMapReady } = props;
  const [savedCurrentPosition, setSavedCurrentPosition] = useState();
  const [savedCurrentRoute, setSavedCurrentRoute] = useState([]);
  const [dynamicRoute, setDynamicRoute] = useState([]); // Subset of the polyline path
  const [map, setMap] = useState(null);
  const mapObj = useMap();

  useEffect(() => {
    getSavedLocation();
  }, [locationKey]);

  const getSavedLocation = async () => {
    const res = await getCompletedLocation(locationKey);
    const locations = res.data?.liveLocation || [];
    setSavedCurrentRoute(locations);
  };

  useEffect(() => {
    if (videoTime != null && savedCurrentRoute.length > 0) {
      // Find the closest location based on the video time
      const currentLocationIndex = savedCurrentRoute.findIndex((loc, index) => {
        const nextLoc = savedCurrentRoute[index + 1];
        return nextLoc && videoTime >= loc.timestamp && videoTime < nextLoc.timestamp;
      });

      if (currentLocationIndex !== -1) {
        const currentLocation = savedCurrentRoute[currentLocationIndex];
        setSavedCurrentPosition(currentLocation);
        setDynamicRoute(savedCurrentRoute.slice(0, currentLocationIndex + 1)); // Subset the path
        map?.panTo(currentLocation); // Center the map on the current location
      }
    }
  }, [videoTime, savedCurrentRoute, map]);

  // useEffect(() => {
  //   if(mapObj.isLoaded){
  //     onMapReady();
  //   }
  // }, [mapObj])

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {mapObj.isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: 650 }}
          center={savedCurrentPosition}
          onLoad={(map) => {
            setMap(map);
            onLoadMap?.(map);
          }}
          zoom={12}
        >
          {dynamicRoute.length > 0 && (
            <PolylineF
              path={dynamicRoute}
              options={{ strokeColor: "#36454F" }}
            />
          )}
          {savedCurrentPosition && <MarkerF position={savedCurrentPosition} />}
        </GoogleMap>
      ) : (
        <p>Map Loading...</p>
      )}
    </div>
  );
};

export default TrackAdminGoogleMap;

