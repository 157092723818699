import React, {useState, useEffect} from "react";
import SettingsPopup from "../Popups/Popups";
import OtpVerificationSettingsPopup from "../ResetPasswordPopup/OtpVerificationPopups";
import ResetPasswordSettingsPopup from "../ResetPasswordPopup/ResetPassword";
import CreateNewPasswordSettingsPopup from "../ResetPasswordPopup/CreateNewPasswordPopup";
import ResetPasswordSettingsSuccessPopup from "../ResetPasswordPopup/ResetpasswordSuccess";

const AccountSecurity= ({userInfo, UserId}) => {
    const Border = "border-[#E2E2E2] border-[1px] border-solid";

    const [userEmail, setUserEmail] = useState(userInfo?.Email || "Johndoe@gmail.com");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [bothPasswordError, setBothPasswordError] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [resetPasswordshowPopup, setResetPasswordShowPopup] = useState(false);
    const [otpVerificationShowPopup, setOtpVerificationShowPopup] = useState(false);
    const [createNewPasswordShowPopup, setCreateNewPasswordShowPopup] = useState(false);
    const [passwordResetSuccessShowPopup, setPasswordResetSuccessShowPopup] = useState(false);


    const [blocked, setBlocked] = useState(false);
    const [retryAfter, setRetryAfter] = useState(0);
    const [attempts, setAttempts] = useState(0);

    const MAX_ATTEMPTS = 5;
    const BLOCK_TIME = 300; // 5 minutes in seconds
  
   // Load block state from localStorage
    useEffect(() => {
        const storedBlocked = localStorage.getItem("blocked");
        const storedStartTime = localStorage.getItem("startTime");
        const currentTime = Math.floor(Date.now() / 1000);

        if (storedBlocked === "true" && storedStartTime) {
            const elapsedTime = currentTime - parseInt(storedStartTime, 10);
            const remainingTime = Math.max(BLOCK_TIME - elapsedTime, 0);

            if (remainingTime > 0) {
                setBlocked(true);
                setRetryAfter(remainingTime);
            } else {
                localStorage.removeItem("blocked");
                localStorage.removeItem("startTime");
            }
        }
    }, []);

    // Save block state to localStorage
    useEffect(() => {
        if (blocked) {
            localStorage.setItem("blocked", "true");
            localStorage.setItem("startTime", Math.floor(Date.now() / 1000));
        }
    }, [blocked]);

    const validateCurrentPassword = async () => {
        if (blocked) return;

        try {
            const response = await fetch("/api/settings/validate-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ currentPassword, UserId }),
            });

            const result = await response.json();

            if (response.ok) {
                setPasswordError("");
                setAttempts(0);
                setBlocked(false);
            } else {
                setPasswordError(result.message || "Current password is incorrect. ");
                setAttempts((prev) => {
                    const newAttempts = prev + 1;
                    if (newAttempts >= MAX_ATTEMPTS) {
                        setBlocked(true);
                        setRetryAfter(BLOCK_TIME);
                    }
                    return newAttempts;
                });
            }
        } catch (error) {
            setPasswordError("An error occurred. Please try again.");
        }
    };

    // Handle timer decrement
    useEffect(() => {
        if (blocked && retryAfter > 0) {
            const interval = setInterval(() => {
                setRetryAfter((prev) => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        setBlocked(false);
                        setAttempts(0);
                        localStorage.removeItem("blocked");
                        localStorage.removeItem("startTime");
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [blocked, retryAfter]);
    // const validateCurrentPassword = async () => {
    //     try {
    //       const response = await fetch("/api/settings/validate-password", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({ currentPassword, UserId }),
    //       });
    //       const result = await response.json();
    
    //       if (response.ok) {
    //         setPasswordError(""); // Clear the error if validation is successful
    //       } else {
    //         setPasswordError("Current password is incorrect.");
    //       }
    //     } catch (error) {
    //       setPasswordError("An error occurred. Please try again.");
    //     }
    //   };

    const matchBothPasswords = async () => {
        // Validate new password and confirm password match
        setBothPasswordError("")
        if (newPassword !== confirmPassword) {
            setBothPasswordError("New Password and Confirm Password do not match.");
            return;
        }
    };

    const handleChangePassword = async () => {
        // Reset messages
        setErrorMessage("");
        setSuccessMessage("");
    
        try {
          // Send data to backend
          const response = await fetch("/api/settings/change-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ currentPassword, newPassword, UserId, userEmail }),
          });
    
          const result = await response.json();
    
          if (response.ok) {
            setShowPopup(true)
          } else {
            setErrorMessage(result.message || "An error occurred.");
          }
        } catch (error) {
          setErrorMessage("An error occurred. Please try again.");
        }
      };
    


    return (
        <>
            {blocked ? (
                <p className="text-red-500">You are blocked. Try again in {retryAfter} seconds.</p>
                ) : (
                <div className="w-full flex flex-col gap-[10px]" >
                    <p className="text-[#404040] text-[20px] font-bold">Account Security</p>
                    <div className=" w-[90%] flex flex-col gap-[10px] bg-[#F8FAFA] rounded-[9px] px-[20px] py-[15px]">
                        {/* Email */}
                        <div className="flex flex-col gap-[5px]">
                            <label
                                htmlFor="Email"
                                className="text-[#404040] text-[16px]"
                            >
                                Email
                            </label>
                            <input
                                type="text"
                                id="Email"
                                name="FirstName"
                                readOnly
                                value={userEmail}
                                className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                            />
                        </div>

                        {/* Current Password */}
                        <div className="flex flex-col gap-[5px]">
                            <div className="flex justify-between">
                                <label
                                    htmlFor="Current Password"
                                    className="text-[#404040] text-[16px]"
                                >
                                    Current Password
                                </label>
                                {passwordError && <p className="text-[#ff0000] text-[16px]">{passwordError} 
                                {!blocked && (
                                    <span>
                                        ( {attempts}/{MAX_ATTEMPTS} attempts)
                                    </span>
                                )}
                                </p>}
                            </div>
                            <input
                                type="password"
                                id="Current Password"
                                placeholder="Enter you current password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                onBlur={validateCurrentPassword} // Validate on blur
                                className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                            />
                        </div>

                        {/* New Password */}
                        <div className="flex flex-col gap-[5px]">
                            <div className="flex justify-between">
                                <label
                                    htmlFor="New Password"
                                    className="text-[#404040] text-[16px]"
                                >
                                    New Password
                                </label>
                                {bothPasswordError && <p className="text-[#ff0000] text-[16px]">{bothPasswordError}</p>}
                            </div>
                            <input
                                type="password"
                                id="New Password"
                                placeholder="Enter you New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                            />
                        </div>

                        {/* Re Enter New Password */}
                        <div className="flex flex-col gap-[5px]">
                            <label
                                htmlFor="Re Enter New Password"
                                className="text-[#404040] text-[16px]"
                            >
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                id="Re Enter New Password"
                                placeholder="Enter you new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onBlur={matchBothPasswords} // Validate on blur
                                className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                            />
                        </div>

                    </div>

                    {showPopup && (
                        <SettingsPopup
                            setShowPopup={setShowPopup}
                            text="Password Changed Successfully."
                        />
                    )}

                    {resetPasswordshowPopup && (
                        <ResetPasswordSettingsPopup
                            setResetPasswordShowPopup={setResetPasswordShowPopup}
                            userEmail={userEmail}
                            setOtpVerificationShowPopup={setOtpVerificationShowPopup}
                        />
                    )}

                    {otpVerificationShowPopup && (
                        <OtpVerificationSettingsPopup
                            userEmail={userEmail}
                            setOtpVerificationShowPopup={setOtpVerificationShowPopup}
                            setCreateNewPasswordShowPopup={setCreateNewPasswordShowPopup}
                        />
                    )}

                    {createNewPasswordShowPopup && (
                        <CreateNewPasswordSettingsPopup
                        setCreateNewPasswordShowPopup={setCreateNewPasswordShowPopup}
                        setPasswordResetSuccessShowPopup={setPasswordResetSuccessShowPopup}
                        UserId={UserId}
                        userEmail={userEmail}
                        />
                    )}

                    {passwordResetSuccessShowPopup && (
                        <ResetPasswordSettingsSuccessPopup
                            setPasswordResetSuccessShowPopup={setPasswordResetSuccessShowPopup}
                        />
                    )}
                    <div className="w-[90%] flex justify-between mt-[10px]">
                        <p className="text-[18px] ml-[15px] text-[#FF5353] cursor-pointer"
                        onClick={()=>setResetPasswordShowPopup(true)}>Reset Password?</p>
                        <button
                        className="w-[180px] h-[44px] rounded-[8px] bg-[#A1A3EF] text-[#FFFFFF] text-[20px]"
                        onClick={handleChangePassword}>
                            Change Password
                        </button>
                    </div>
                </div>
            )}
    </>
    );
};

export default AccountSecurity;
