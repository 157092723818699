import React from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import InstructorInfo from "./Components/InstructorInfo/InstructorInfo";
import InstructorTop from "./Components/InstructorTop/InstructorTop";
import "./AddInstructor.css";
import { useState } from "react";
import {
    GetSInstructorA,
    UpdateInstructorA,
} from "../../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetLicenseTypes } from "../../../Actions/CategoryA";
import InstructorPopup from "./Components/Popup/InstructorPopup";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../../Actions/Base";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import {
    ScrollElement,
    ScrollSingleElement,
} from "../../../Helpers/ScrolltoElement";

const UpdateInstructorChild = () => {
    const { InstructorId } = useParams();

    const { error, loading, SInstructor } = useSelector(
        (Store) => Store.InstructorReducer
    );
    const [InstructorData, setInstructorData] = useState({
        FirstName: "",
        LastName: "",
        Address: "",
        PostalCode: "",
        Province: "",
        City: "",
        Email: "",
        PhoneNumber: "",
        EmergencyPhoneNumber: "",
        Gender: "",
        DOB: "",
        ProfileImage: "",
        TrainerPermitImage: "",
    });

    const [Success, setSuccess] = useState();
    const [Err, setErr] = useState();
    const [LicenseImages, setLicenseImages] = useState([]);
    const [SLicenseImages, setSLicenseImages] = useState([]);
    const [SpecialityName, setSpecialityName] = useState([]);
    console.log("line~51  SLicenseImages", SLicenseImages);
    console.log("line~52  LicenseImages", LicenseImages);

    const Dispatch = useDispatch();

    useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

    console.log("update instructor", error);
    useEffect(() => {
        if (InstructorId) Dispatch(GetSInstructorA(InstructorId));
    }, [Dispatch, InstructorId]);

    useEffect(() => {
        const sinstructor = JSON.parse(JSON.stringify(SInstructor));
        // console.log("line~64", SInstructor.Speciality);
        if (Object.entries(SInstructor).length > 0) {
            // console.log("line~64", SInstructor.Speciality);

            delete sinstructor.FromInstitute;
            setInstructorData({
                ...InstructorData,

                ...sinstructor,

                ProfileImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.ProfileImage}`,

                TrainerPermitImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.TrainerPermitImage}`,
                // Speciality: SInstructor?.Speciality,
            });
            // setSpecialityName(...SpecialityName, SInstructor?.Speciality);
            setSpecialityName(SInstructor?.Speciality);

            // setSpecialityName([...SpecialityName, SInstructor?.Speciality]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SInstructor]);

    useEffect(() => {
        Dispatch(GetLicenseTypes());
    }, [Dispatch]);

    // console.log("sinstructor PARESED", sinstructor);
    console.log("sinstructor", InstructorData);

    // const names = Specialities.map((value) => value.SpecialityName);

    const SubmitForm = (e) => {
        SubmitInstructorData(
            e,
            Dispatch,
            setSuccess,
            InstructorData,
            Err,
            setErr,
            LicenseImages,
            SLicenseImages,
            SpecialityName
        );
        // alert("updated successfully");
    };
    useEffect(() => {
        if (error?.response?.data) {
            setErr(error?.response?.data);
            if (error?.response?.data)
                for (const [key] of Object.entries(error?.response?.data)) {
                    if (key) {
                        ScrollSingleElement(key, 200);
                        break;
                    }
                }
        }
    }, [error]);

    // useEffect(() => {
    //     if (Success) {
    //         alert("Updated successfully!"); // Display browser alert when Success state is true
    //     }
    // }, [Success]);

    return !loading ? (
        <form
            // className="flex w-full flex-col items-center gap-10 bg-[#F7F7F7] py-10"
            className="mb-5 mt-5 flex w-full flex-col  items-center bg-[#FFFFFF] "
            onSubmit={SubmitForm}
        >
            {/* <div className="Intsructor-InputContainer">
                <InstructorTop /> */}
            <div className="flex w-full justify-center ">
                <InstructorInfo
                    InstructorData={InstructorData}
                    setInstructorData={setInstructorData}
                    Err={Err}
                    setErr={setErr}
                    updateOnly={true}
                    SpecialityName={SpecialityName}
                    setSpecialityName={setSpecialityName}
                    setLicenseImages={setLicenseImages}
                    LicenseImages={LicenseImages}
                    setSLicenseImages={setSLicenseImages}
                    SLicenseImages={SLicenseImages}
                    SInstructor={SInstructor}
                />
            </div>
            {/* </div> */}

            <button type="submit" className="Instructor-SubmitBtn">
                Save and Continue
            </button>
            {Success ? (
                <InstructorPopup
                    message={
                        "An Instructor Detail has been Updated Successfully"
                    }
                />
            ) : null}
        </form>
    ) : (
        <LoadingSpinner />
    );
};

const SubmitInstructorData = (
    e,
    Dispatch,
    setSuccess,
    InstructorData,
    Err,
    setErr,
    LicenseImages,
    SLicenseImages,
    SpecialityName
) => {
    e.preventDefault();
    console.log("InstructorData", InstructorData);
    let Errors = { ...Err };
    const InstructorFormData = new FormData();
    let InstructorDataNoImage = {};

    for (let [key, value] of Object.entries(InstructorData)) {
        if (!value || (typeof value === "string" && value?.trim() === ""))
            Errors[key] = true;
        else Errors[key] = undefined;
    }

    setErr({ ...Errors });
    ScrollElement(Errors);

    // console.log("InstructorDataNoImage", InstructorDataNoImage);

    if (!CheckEmptyObj(Errors)) return;

    for (let [key, value] of Object.entries(InstructorData)) {
        if (key.indexOf("Image") > -1) InstructorFormData.append(key, value);
        else InstructorDataNoImage = { ...InstructorDataNoImage, [key]: value };
    }

    for (let i = 0; i < LicenseImages.length; i++) {
        console.log("Single LicencenseImage", LicenseImages[i]);
        InstructorFormData.append("LicenseImages", LicenseImages[i]);
    }
    for (let i = 0; i < SLicenseImages.length; i++) {
        console.log("Single LicencenseImage", SLicenseImages[i]);
        InstructorFormData.append("SLicenseImages", SLicenseImages[i]);
    }
    InstructorFormData.append(
        "IntructorInfo",
        JSON.stringify(InstructorDataNoImage)
    );
    console.log("InstructorFormData", InstructorFormData);
    Dispatch(UpdateInstructorA(InstructorFormData, setSuccess));
};

const UpdateInstructor = () => (
    <InstituteTemplate Element={UpdateInstructorChild} />
);

function CheckEmptyObj(OBJ = {}) {
    let Check = false;
    for (const [, value] of Object.entries(OBJ)) {
        if (value || (typeof value === "string" && value?.trim() !== ""))
            Check = true;
    }
    return Check;
}

export default UpdateInstructor;
