// import React, {useEffect} from 'react'
// import { BaseUrl } from '../../../Actions/Base'

// const TopPart = ({InstituteCourseName, InstituteBannerImage}) => {
//     console.log("InstituteBannerImage is:",InstituteBannerImage)

//     const InstituteBannerMainImage = `${BaseUrl}/api/Images/InstituteBanner?banner=${InstituteBannerImage}`;
//     const defaultBannerImage = '../Assets/Enrollment bg.png';

//     useEffect(() => {
//         const fetchBannerImage = async () => {
//             try {
//                 if (!InstituteBannerImage) {
//                     console.log("No InstituteBannerImage provided");
//                     return;
//                 }

//                 console.log("Fetching banner image from API:", InstituteBannerMainImage);

//                 const response = await fetch(InstituteBannerMainImage);

//                 console.log("API Response:", response);

//                 if (!response.ok) {
//                     throw new Error(`HTTP error! Status: ${response.status}`);
//                 }

//                 console.log("Image URL:", response.url);
//             } catch (error) {
//                 console.error("Error fetching banner image:", error);
//             }
//         };

//         fetchBannerImage();
//     }, [InstituteBannerImage, InstituteBannerMainImage]);

//     const backgroundImageStyle = {
//         backgroundImage: `url(${InstituteBannerImage ? InstituteBannerMainImage : defaultBannerImage})`,
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//     };
    

//     return (
//         <div className='bg-[#C8C9F6] pb-5 sm:pb-10'>
//             <div className='relative pt-28 pb-36 z-10 sm:pt-56 sm:pb-72'>
//                 <h1 className='text-center text-[24px] sm:text-[45px] text-white SemiBoldItalic'>
//                     Enrollment for <br /> {InstituteCourseName}
//                 </h1>

//                 <div className='absolute top-0 w-full  h-full -z-10 blur-[5px]'
//                 style={backgroundImageStyle}>
//                     <div className='w-full h-full bg-[#a6a6a664]'></div>
//                 </div>
//             </div>

//         </div>
//     )
// }

// export default TopPart


import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../../../Actions/Base';
import backgroundCourseImage from "../Assets/Enrollmentbg.png"

const TopPart = ({ InstituteCourseName, InstituteBannerImage }) => {
    const [bannerError, setBannerError] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    // Log for debugging
    console.log("InstituteBannerImage is:", InstituteBannerImage);

    // Construct the URL for fetching the image
     const InstituteBannerMainImage = `${BaseUrl}/api/Images/InstituteBanner?url=${InstituteBannerImage}`;
    console.log("InstituteBannerMainImage:", InstituteBannerMainImage);
    const defaultBannerImage = '../Assets/Enrollmentbg.png';

    useEffect(() => {
        const fetchBannerImage = async () => {
            try {
                if (!InstituteBannerImage) {
                    console.log("No InstituteBannerImage provided");
                    setBannerError(true);
                    return;
                }

                console.log("Fetching banner image from API:", InstituteBannerMainImage);

                const response = await fetch(InstituteBannerMainImage);
                console.log("API Response:", response);

                if (!response.ok) {
                    setBannerError(true);
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                setImageUrl(response.url); // Use the correct URL if the image is fetched successfully
                setBannerError(false); // Reset error state on success
                console.log("Image URL:", response.url);
            } catch (error) {
                console.error("Error fetching banner image:", error);
                setBannerError(true);
            }
        };

        fetchBannerImage();
    }, [InstituteBannerImage, InstituteBannerMainImage]);
        console.log("imageUrl is:",imageUrl)

    const backgroundImageStyle = {
        backgroundImage: `url(${InstituteBannerMainImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <div className='bg-[#C8C9F6] pb-5 sm:pb-10'>
            <div className='relative pt-28 pb-36 z-10 sm:pt-56 sm:pb-72'>
                <h1 className='text-center text-[24px] sm:text-[45px] text-white SemiBoldItalic'>
                    Enrollment for <br /> {InstituteCourseName}
                </h1>
                {/* <img className="w-full rounded-full h-full" src={`${BaseUrl}/api/Images/InstituteBanner?url=${InstituteBannerImage}`} alt="InstituteLogo" /> */}


                <div className='absolute top-0 w-full h-full -z-10 blur-[5px]'
                     style={backgroundImageStyle}
                    // style=  {{
                    //     backgroundImage: `url(${backgroundCourseImage})`,
                    //     backgroundRepeat: "no-repeat",
                    //     backgroundSize: "cover",
                    //     backgroundPosition: "center",
                    // }}
                    >
                    <div className='w-full h-full bg-[#a6a6a664]'></div>
                </div>
            </div>
        </div>
    );
};

export default TopPart;
