import React,{useState} from "react";
import dayjs from "dayjs";
import "./InstructorTemplate.css";
export const InstructorRowTemplate = ({
    index,
    key,
    FirstName,
    LastName,
    Status,
    Role,
    createdAt,
    phone,
    Email,
    LastComp,
    LastCompp,
    StatusColor,
    PaymentOrUnderClients,
    PaymentOrClientsColor,
    OnClick,
    TrStyle,
    ImgUrl,
    Delete,
    type,
    StudentId,
    UnderTrainingStudents,
    TotalClasses,
    InstructorIdCard,
    StaffIdCardNo,
    IdCardNo,
    suspend,
    SchoolAdmin,
    MultipleUserType,
    createdDate,
    Ellipses,
    activeRow,
    DropdownMenu,
    isLastRow
}) => {
    console.log("MultipleUserType is:",MultipleUserType)
   
    // const handleClick = (e) => {
    //     e.stopPropagation(); // Prevent propagation to parent elements
    //     OnClick(e); // Call the provided onClick function
    // };
    return (
        <tr key={key}
         onClick={OnClick} 
         className="relativeTableData cursor-pointer">

         {/* Serial Number Column */}
         {type === "Instructor" || type === "Staff" ? (
      <td className="pl-[15px] text-center 2sm:text-start truncate">
        {index + 1}
      </td>
      ) : null
            }

            {/* for Big Screens hide for mobile */}
            {type === "Instructor" || type === "Staff" || type === "Admin" || type === "students" || type==="InstructorList" ? (
                <td className="flex justify-center items-center mt-[10px]">
                    <div className="ProfileImageContainer">
                        <img
                            className="absolute w-full h-full"
                            src={ImgUrl}
                            alt={FirstName + "img"}
                        />
                    </div>
                </td>
                ) : null
            }

            {type === "Admin" || type==="InstructorList" ? (
                <td className="px-3   text-center 2sm:text-start truncate">
                    {InstructorIdCard}
                </td>
            ) : null
            // (
            //     <td className="px-3  text-center 2sm:text-start truncate">{++index}</td>
            // )
            }
            {type === "students" ? (
                <td className="relative text-center  truncate"> {StudentId} </td>
            ) : null}

            <td className="truncate text-center">
                {FirstName} {LastName}
            </td>
            <td className="truncate hover:text-clip hover:text-wrap	 text-center">{Email}</td>

            <td className="text-center">
                <div className={`addCourse4Status text-[#FFFFFF]`}
                style={{
                    backgroundColor:
                    Status === "New Enrollment"
                        ? "#B05AC4" // Color for new enrollment
                        : Status === "InProgress"
                        ? "#7F82F9" // Color for in progress
                        : Status === "Completed"
                        ? "#B5AAFF" // Color for completed
                        : Status === "suspend"
                        ? "#F33A3A" // Color for suspend
                        : "#B05AC4", // Default color if none match
                }}>
                    {/* {Status
                        ? dayjs(Status).isValid()
                            ? `Next Class: ${dayjs(Status).format(
                                  "YYYY-MM-DD HH:mm"
                              )}`
                            : Status
                        : null} */}
                    {Status === "suspend" ? "Suspended" : Status}
                </div>
            </td>

            {createdDate && (
                <td className="relative text-center">
                    {createdDate}
                </td>
            )}
            

            {Role && (
                <td className="relative text-center">
                    {Role}
                    {SchoolAdmin === true ? "*" : ""}
                    {MultipleUserType ? `, ${MultipleUserType}` : ""}
                </td>
            )}


            {phone && (
                <td className="relative text-center truncate">{phone}</td>
            )}
            {/* {type === "students" && (
                <td className="relative text-center truncate">{phone}</td>
            )} */}

            {UnderTrainingStudents && (
                <td className="text-center  ">
                    <div className={`addCourse4Status bg-[#b5aaff] text-white`}>
                        {UnderTrainingStudents}
                    </div>
                </td>
            )}
            {TotalClasses && <td className="text-center">{TotalClasses}</td>}
            {LastCompp ? (
                <td className="text-center">
                    <LastCompp />
                </td>
            ) : null} 

            {type === "Instructor" || type === "Staff" ? (
            <td className="flex justify-center relativeTableData">
                 {/* Trigger Button (Three Dots)  */}
                <div className="relativeTableData">
                <Ellipses rowId={IdCardNo} />

                 {/* Dropdown Menu  */}
                {DropdownMenu && <DropdownMenu />}
                </div>

            </td>
            ) : null
            } 
           
            {/* <td className="text-center">
                {LastComp && !suspend ? <LastComp /> : null}
            </td> */}
            {Delete && !suspend ? (
                <td className=" text-center">
                    <Delete />
                </td>
            ) : null}
        </tr>
    );
};
