import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../Actions/Base";
import {
  DeleteInstructorA,
  GetInstituteInstructorsA,
} from "../../../Actions/InstructorA";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { InstructorRowTemplate } from "../../../Components/InstructorTemplate/InstructorTemplate";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../Components/NoDataResponse";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import TeacherFilter from "./Components/TeacherFilter";
import "./InstructorsList.css";

import dayjs from "dayjs";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
const InstructorsListChild = () => {
  const { Instructors, loading } = useSelector(
    (Store) => Store.InstructorReducer
  );
  console.log("InstructorsList", Instructors);
  const [FilterOpen, setFilterOpen] = useState();
  const Navigate = useNavigate();
  const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
  const Dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [sortByTime, setSortByTime] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [suspend, setSuspend] = useState(false);
  const handleApplyClick = (filters) => {
    // Get filter values from state variables
    console.log("in handleSortBy:", filters);
    setSearchQuery(filters?.searchQuery);
    setSuspend(filters.suspend);
    setSortBy(filters.sortBy);
    setSortByTime(filters.sortByTime);

    // Fetch data based on the applied filters
    Dispatch(
      GetInstituteInstructorsA({
        sortByTime: filters.sortByTime,
        sortBy: filters.sortBy,
        searchQuery: filters.searchQuery,
        suspend: filters.suspend,
      })
    );
  };

  useEffect(() => {
    Dispatch(
      GetInstituteInstructorsA({
        sortByTime,
        sortBy,
        searchQuery,
        suspend,
      })
    );
  }, [Dispatch, sortByTime, sortBy, searchQuery, suspend]);
  useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);
  const OnClick = (e) => {
    e.stopPropagation();
    if (!FilterOpen) {
      setFilterOpen(true);
      setMaxHeight("max-h-[1000px]");
    } else if (FilterOpen) {
      setFilterOpen(false);
      setMaxHeight("max-h-[0px]");
    }
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    setShowConfirmation(false); // Close the confirmation dialog

    // Dispatch the delete action with the InstructorId if needed
    Dispatch(DeleteInstructorA(popupDetail?.InstructorId))
      .then(() => {
        // Fetch the updated list of instructors after successful deletion
        Dispatch(
          GetInstituteInstructorsA({
            sortByTime,
            sortBy,
            searchQuery,
            suspend,
          })
        );
      })
      .catch((error) => {
        console.error("Error deleting instructor:", error);
      });
  };
  console.log("InstructorsInstructors", Instructors);
  return (
    <div className="instructorDiv shadow my-16  w-11/12  rounded-[35px] py-10">
      <div className="flex w-full flex-col items-center px-6 sm:flex-row sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
        <div className="Boldfont w-full text-center text-3xs  text-[#174A84] sm:w-1/5 sm:text-left md:w-1/4 lg:text-2xs xl:text-xs">
          Instructors
        </div>
        <div className="md::w-3/4 flex w-full justify-between gap-2 sm:w-4/5 sm:justify-end sm:gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12">
          <Link to="/Instructor/add" className="InstructorPageBtns px-4 ">
            Add a New Instructor
          </Link>
          <span
            className="InstructorPageBtns  relative"
            onClick={OnClick}
            onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
            onMouseLeave={() => setMaxHeight("max-h-[0px]")}
          >
            Filter Instructor
            <TeacherFilter
              handleApplyClick={handleApplyClick}
              setSortBy={setSortBy}
              setSortByTime={setSortByTime}
              setSearchQuery={setSearchQuery}
              setSuspend={setSuspend}
              sortBy={sortBy}
              sortByTime={sortByTime}
              searchQuery={searchQuery}
              suspend={suspend}
              MaxHeight={MaxHeight}
              type={"InstructorList"}
              title={"Filter Teachers"}
            />
          </span>
        </div>
      </div>

      {loading ? (
        <LoadingSpinner Bg="white" />
      ) : Instructors.length === 0 ? (
        <NoDataResponse
          topText="Sorry, there are no instructors to show!"
          bottomText="Admin/Institute can add instructors by click on 'Add a New Instructor' button."
        />
      ) : (
        <div className="overflow-x-auto px-5 pt-10">
          {showConfirmation && (
            <DeletePopup
              onCancel={() => setShowConfirmation(false)}
              onConfirm={handleDelete}
              popupDetail={popupDetail}
            />
          )}
          <table className="Instructor_table  addCourse4Table instructorDiv ">
            <thead>
              <tr>
                <th className="w-[1%]">Profile</th>
                <th className="w-[10%] px-3 text-center font-extrabold">ID</th>
                <th className="w-[10%] ">Name</th>
                <th className="w-[16.5%] text-center ">Email</th>
                <th className="w-[14%] text-center">Status</th>
                <th className="w-[16%] text-center">Clients</th>
                {/* <th className="w-[8%] text-center">
                                    Total Classes
                                </th> */}

                {!suspend && <th colSpan={2} className="w-[3.5%] text-center">Actions</th>}
               
              </tr>
            </thead>
            <tbody>
              {Instructors?.map((value, index) => {
                console.log("valueof instructormap", value);

                const { Status, InstructorId } = value?.user?.Instructor;
                console.log("Status=>>>", Status)
                {
                  /* const PaymentOrClientsColor =
                  !ClientsTraining || ClientsTraining === 0
                    ? "text-[green] bg-[#aaffbe]"
                    : "text-white bg-[#b5aaff]"; */
                }
                const StatusColor =
                  Status === "Free"
                    ? "text-white bg-[#b5aaff]"
                    : Status === "InClass"
                    ? " bg-[#B05AC4]"
                    : "text-white bg-[#7F82F9]";

                // const Edit = () => (
                //   <Link onClick={e => e.stopPropagation()}
                //     to={`/instructor/update/${value.Instructor.InstructorId}`}
                //   >
                //     <img src={require("./Assets/Edit.svg").default} alt="Edit" />
                //   </Link>
                // );

                const Edit = () => (
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/instructor/update/${value?.user?.Instructor.InstructorId}`}
                  >
                    <button
                      type="button"
                      // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2"
                      className="absolute  left-0 w-[80px] 2sm:w-auto cursor-pointer  rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2 "
                    >
                      Edit
                    </button>
                  </Link>
                );

                const Delete = () => {
                  const handleDeleteClick = (e) => {
                    e.stopPropagation();
                    setPopupDetail({
                      InstructorId: value?.user?.Instructor?.InstructorId,
                      InstructorName: `${value?.user?.FirstName} ${value?.user?.LastName}`,
                      InstructorProfile: ImageUrl,
                      Profile: value?.user?.Instructor?.ProfileImage,
                    });
                    setShowConfirmation(true);
                  };

                  return (
                    <>
                      <button
                        onClick={handleDeleteClick}
                        type="button"
                        // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2 lg:py-2"
                        className="absolute  left-0 w-[80px] 2sm:w-auto  cursor-pointer rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-6 lg:py-2 "
                      >
                        Delete
                      </button>
                    </>
                  );
                };

                const ImageUrl = `${BaseUrl}/api/images/Instructors?url=${value?.user?.Instructor?.ProfileImage}`;
                const OnRowClick = (e) => {
                  Navigate(
                    `/about/instructor/${value?.user?.Instructor.InstructorId}`
                  );
                  e.stopPropagation();
                };
      
                return (
                  <InstructorRowTemplate
                    key={value?.user?.Instructor?.InstructorId}
                    InstructorIdCard={
                      value?.user?.Instructor?.InstructorIdCardNo
                    }
                    value={value}
                    FirstName={value?.user?.FirstName}
                    Email={value?.user?.Email}
                    LastName={value?.user?.LastName}
                    // Status={!Status ? "free" : Status}
                    suspend={suspend}
                    Status={
                      Status
                        ? dayjs(Status).isValid()
                          ? `Next Class at ${dayjs(Status).format("HH:mm")}`
                          : Status
                        : null
                    }
                    // underTrainingStatusColor={
                    //        value?.user?.Instructor
                    //         ?.UnderTrainingStudents === "0"
                    //         ? "text-white bg-[#b5aaff]"
                    //         : "text-white bg-[#b5aaff]"
                    // }
                    // ClientsTraining={
                    //     value.Instructor.ClientsTraining
                    // }
                    // PaymentOrUnderClients={
                    //     !StudentsTraining
                    //         ? "free"
                    //         : StudentsTraining
                    // }
                    ImgUrl={ImageUrl}
                    // PaymentOrClientsColor={
                    //     PaymentOrClientsColor
                    // }
                    StatusColor={StatusColor}
                    // underTrainingStatusColor={
                    //     underTrainingStatusColor
                    // }
                    LastCompp={Edit}
                    OnClick={OnRowClick}
                    Delete={Delete}
                    TrStyle={"cursor-pointer"}
                    type={"InstructorList"}
                    UnderTrainingStudents={
                      value?.user?.Instructor?.UnderTrainingStudents === "0"
                        ? "Free"
                        : value?.user?.Instructor?.UnderTrainingStudents
                    }
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const InstructorsList = () => (
  <InstituteTemplate
    Element={InstructorsListChild}
    bg="bg-[#F7F7F7] min-h-screen items-center"
  />
);

export default InstructorsList;
