import React, { useRef } from "react";
import { useEffect } from "react";

const SideFilters = ({
    Title,
    FiltersArr,
    TitleStyle,
    WidthStyle,
    PositionAndIndex,
    OnClick,
    Style,
    ShowOnSmall,
    children,
    setSortBy,
    setSortByTime,
    setSearchQuery,
    setSuspend,
    suspend,
    sortByTime,
    sortBy,
    searchQuery,
    handleApplyClick,
    vehicleType,
    setVehicleType,
    vehicleLicenseType,
    setVehicleLicenseType,
    types,
    gearType,
    setTypes,
    setGearType,
    setSelectedRole,
    selectedRole,
    setSelectedMultipleUserType
}) => {
    const FilterRef = useRef();
    // console.log("FIltersArr", FiltersArr);
    const HideFilter = (e) => {
        if (e.currentTarget === e.target) {
            let CheckClass = e.target.classList.contains("max-h-[0px]");
            if (!CheckClass) e.target.classList.add("max-h-[0px]");
        }
    };
    window.addEventListener("resize", () => {
        const VehicleFilter = document.getElementById("VehicleFilter");

        const Condition = !ShowOnSmall
            ? window.innerWidth >= 640 //to hide on small screen
            : window.innerWidth <= 640 || window.innerWidth >= 640; //to show on small screen
        if (VehicleFilter)
            if (Condition) {
                VehicleFilter.style.minHeight = "auto";
                VehicleFilter.style.height = "auto";
                VehicleFilter.style.display = "flex";
            } else {
                VehicleFilter.style.minHeight = "0px";
                VehicleFilter.style.height = "0px";
            }
    });
    useEffect(() => {
        if (window.innerWidth < 640) {
            FilterRef.current.style.minHeight = "0px";
            FilterRef.current.style.height = "0px";
        }
    }, []);

    if (!TitleStyle) TitleStyle = "text-2xs font-normal Boldfont";
    if (!WidthStyle) WidthStyle = "w-full  md:w-11/12";
    if (!PositionAndIndex)
        PositionAndIndex = "fixed   sm:relative     z-30  sm:z-0";
    if (!Style)
        Style =
            Style +
            `top-0 flex  justify-center items-center duration-200 overflow-hidden
        ${WidthStyle} ${PositionAndIndex} cursor-auto duration-100
        rounded-b-[70px]        sm:rounded-none 
        SideBarBG`;
    
    

    return (
        <div
            className={Style}
            id="VehicleFilter"
            ref={FilterRef}
            onClick={(e) => e.stopPropagation()}
            style={{
                background: "transparent linear-gradient(180deg, #B05AC4 0%, #A1A3EF 100%) 0% 0% no-repeat padding-box",
            }}
        >
            <span
                className="absolute right-12 top-5 flex cursor-pointer items-center justify-center rounded-[17px] bg-[#A1A3EF] sm:hidden"
                onClick={HideFilter}
            >
                <img
                    className="pointer-events-none p-3"
                    src={
                        require("../Header/Components/StudentHeader/Components/RightSide/Assets/CrossIcon.svg")
                            .default
                    }
                    alt="cross"
                />
            </span>

            <div
                className={`flex w-11/12 max-w-[400px] flex-col gap-16  bg-white px-10 py-10 sm:w-full`}
                style={{
                background: "transparent linear-gradient(180deg, #B05AC4 0%, #A1A3EF 100%) 0% 0% no-repeat padding-box",
            }}
            >
                <h4 className={TitleStyle}
                style={{
                    color: "#FFFFFF", // Correct CSS property for text color
                }}> {Title}</h4>
                <div className="flex flex-col gap-9">
                    {FiltersArr ? (
                        <FiltersArr
                            types={types}
                            setTypes={setTypes}
                            gearType={gearType}
                            setGearType={setGearType}
                            setSortBy={setSortBy}
                            setSortByTime={setSortByTime}
                            setSearchQuery={setSearchQuery}
                            setSuspend={setSuspend}
                            sortBy={sortBy}
                            sortByTime={sortByTime}
                            searchQuery={searchQuery}
                            suspend={suspend}
                            handleApplyClick={handleApplyClick}
                            vehicleLicenseType={vehicleLicenseType}
                            setVehicleLicenseType={setVehicleLicenseType}
                            vehicleType={vehicleType}
                            setVehicleType={setVehicleType}
                            setSelectedRole={setSelectedRole}
                            selectedRole={selectedRole}
                            setSelectedMultipleUserType={setSelectedMultipleUserType}
                        />
                    ) : null}

                    {children}
                </div>

                <button
                    type="button"
                    className="relative flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md  border-none bg-[#FFFFFF] p-4 text-4xs text-[#A1A3EF] outline-none"
                    onClick={OnClick}
                >
                    <img
                        className="absolute left-3"
                        src={require("./SearchWhite.svg").default}
                        alt=""
                    />
                    Apply Filters
                </button>
            </div>
        </div>
    );
};

export default SideFilters;
