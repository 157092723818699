import React, { useEffect, useState } from 'react';
import CourseTiles from '../../CourseCategory/Components/CourseTiles/CourseTiles';
import CourseSliderForSearchCourses from '../../../Components/CourseSlider/CourseSliderForSearchCourses';
import { BaseUrl } from '../../../Actions/Base';
import axios from 'axios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner'
import NoDataResponse from '../../../Components/NoDataResponse';


let ClassNames = `relative   duration-300 flex
w-[95%]                             lg:w-11/12                  xl:w-[97%]        
flex-wrap  
justify-center
                    gap-7                               lg:gap-x-8 lg:gap-y-16
                    sm:px-8     
                               
`
const Courses = ({searchCourse}) => {
    const [courseData, setCourseData]= useState()
    const [Loading, setLoading] = useState(false)
    const [FilteredCount, setFilteredCount] = useState(0);

    // const Courses = LicenseType?.Courses

    // useEffect(() => {
    //     setPopularCourses(Courses)
    // }, [Courses])
    
    const url =  `${BaseUrl}` + `/api/searchh?search=${searchCourse}`
    const getData = async () => {
        setLoading(true);
        try {
          const res = await axios.get(url);
          console.log(res)
          setCourseData(res?.data);
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false);
        }
      };
    
    useEffect(()=> {
        let timeOut = setTimeout(()=> {
          const data=  getData()
          console.log("data,,,", data)
        }, 1000)

        return () => clearTimeout(timeOut)
    }, [searchCourse])


    return (
        <div className='relative flex-col md:items-center overflow-hidden bg-backgroundWhiteColor'>
            <div className='flex items-center flex-col gap-7 w-full'>
                {/* <h2 className='sm:text-white text-center text-base sm:text-[38px]  PrintBoldfont'>
                    {LicenseType?.LicenseTypeName}
                </h2> */}

               

               <div className='w-full'>
                    {
                       courseData?.length < 1 ? 
                       <NoDataResponse topText={"Course Not Found!"} course={true} /> : 
                       <CourseSliderForSearchCourses ClassNames={ClassNames} 
                       CourseTiles={CourseTiles} CoursesInfo={courseData && courseData}
                       setFilteredCount={setFilteredCount}  />
                    }

                </div>
                {/* {NextSubLicenseType ? <LicenseType3Detail LicenseType={NextSubLicenseType} /> : null} */}
            </div>
            {/* <BG /> */}

        </div>
    )
}



export default Courses
