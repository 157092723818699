import React, { useRef, useState, useEffect } from "react";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { Button } from "@mui/material";
import Webcam from "react-webcam";
import PickUpModal from "./PickUpModal";
import { useParams, useNavigate, useLocation  } from "react-router-dom";
import CustomeGoogleMap from "./GoogleMap";
import { useMap } from "./GoogleMap/mapHook";
import InfoSummer from "./GoogleMap/InfoSummer.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentLocation,
  convertDistanceToMeters,
} from "./GoogleMap/utils.js";
import OTPModal from "./OTPModal.jsx";
import ClassSummaryDialog from "./ClassSummaryDialog.jsx";
import { wayPointByRoad } from "../../Helpers/utils/googleMap.js";
import { getSocket } from "./socket.js";
import { GetInstructorAndStudentAddressA, UpdatePickedStudentUserFieldA,
   UpdateStartRecordingUserFieldA, UpdateStartRecordingAndPickedStudentUserFieldA } from "../../Actions/InstructorA";
import { usePageVisibility } from "./PageVisibility";
import { FloatingWindow } from "./FloatingWindow";
import BypassOTPModal from "./BypassOtpModal";
import TimeInfo from "./GoogleMap/TimeInfo";
import MobileTopBtns from "./GoogleMap/MobileTopBtns";
import MobileLowerInfotab from "./GoogleMap/MobileLowerInfoTab";
import BottomCarIcon from "./GoogleMap/bottomLeftCarIcon";
import ClassSummaryModal from "./ClassSummaryModal";
import { updateSchedule } from "../../Actions/ScheduleA";
import CancelClassModal from "./CancelClassModal";
import InVehicleClassSidebar from "./InVehicleClassSidebar";
import usePageUnloadWarning from './UseBlocker'
import NetworkStatus from "./NetworkStatus";
import SoSFunctionality from "../SOSFeatures/SoS";
import SOSPopup from "../SOSFeatures/SOSPopUp";
import EmergencyTimerPopup from "../SOSFeatures/EmergencyTimerPopup";
import SOSAlertPopup from "../SOSFeatures/SOSAlertPopup";
import RecordRTC from "recordrtc";
import LocationUpdater from "./liveLocationUpdator";
import { BaseUrl } from "../../Actions/Base";
import { UploadScreenRecordingToS3 } from "../../Actions/InstructorA";
import SOSMobileIcon from "../SOSFeatures/SOSMobileIcon";
import SOSMobileFeaturePopup from "../SOSFeatures/SosMobileFeaturePopup";
import SOSMobileEmergencyTimerPopup from "../SOSFeatures/SOSMobileEmergencyTimerAlert";
import SOSMobileBeaconClick from "../SOSFeatures/SOSMobileBeaconClick";
import SOSMobileCreateLocationLink from "../SOSFeatures/SOSCreateMobileLink";
import { UpdateLiveLocationInstructorCoordinates } from "../../Actions/InstructorA";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";

const google = window.google;
const defaultCenter = { lat: 43.65323, lng: -79.38318 };
const defaultLocations = {
  origin: { address: "", latlng: null },
  destination: { address: "", latlng: null },
};
// const defaultLocations = {
//   origin: { 
//     address: "Toronto, ON, Canada", 
//     latlng: { lat: 43.6532, lng: -79.3832 } 
//   },
//   destination: { 
//     address: "Ottawa, ON, Canada", 
//     latlng: { lat: 45.4215, lng: -75.6972 } 
//   },
// };

const defaultZoom = 12;

const StartClass = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openSOSPopup, setOpenSOSPopup] = useState(false);
  const [openSOSMobilePopup, setOpenSOSMobilePopup] = useState(false);
  const [openSOSEmergencyTimerPopup, setOpenSOSEmergencyTimerPopup] = useState(false);
  const [openSOSEmergencyMobileTimerPopup, setOpenSOSEmergencyMobileTimerPopup] = useState(false);
  const [openSOSMobileBeaconClickPopup, setOpenSOSMobileBeaconClickPopup] = useState(false);
  const [openSOSMobileCreateLinkPopup, setOpenSOSMobileCreateLinkPopup] = useState(false);
  const [openSOSAlertPopup, setOpenSOSAlertPopup] = useState(false);
  const [openCancelClassPopup, setOpenCancelClassPopup] = useState(false);
  const [selectedSOSProvidedFeatures, setSelectedSOSProvidedFeatures] = useState("");

  const [streams, setStreams] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [openBypassOTPModal, setOpenBypassOTPModal] = useState(false);
  const [classSummaryDialog, setClassSummaryDialog] = useState(false);
  const [classTimingDialog, setClassTimingDialog] = useState(false);
  const [bottomCarMinimizingIcon, setBottomCarMinimizingIcon] = useState(false);
  const [recording, setRecording] = useState(false);
  const [randomText, setRandomText] = useState("Recording will start soon...");
  const [recorder, setRecorder] = useState(null);
  const [recordingStream, setRecordingStream] = useState(null);
  const [recordingStartTime, setRecordingStartTime] = useState(null); // Record the start time
  const [locationData, setLocationData] = useState([]); // Store location and timestamps
  const [intervalId, setIntervalId] = useState(null); // Store the interval ID for cleanup

  const InstructorUserId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;
  console.log("InstructorUserId is:",InstructorUserId)
  // const getCurrentTimestamp = () => {
  //   console.log("recordingStartTime is:",recordingStartTime)
  //   if (recordingStartTime) {
  //     return (performance.now() - recordingStartTime) / 1000; // Time in seconds
  //   }
  //   return 0;
  // };
  // const [leftLocationCurrentTime, setLeftLocationCurrentTime] = useState("");
  // const [reachedTPpickupZoneCurrentTime, setReachedTPpickupZoneCurrentTime] = useState("");
  // const [pickupStudentCurrentTime, setPickupStudentCurrentTime] = useState("");
  // const [completedInvehicleCurrentTime, setCompletedInvehicleCurrentTime] = useState("");

    // Initialize state from local storage or set to an empty string if it doesn't exist
    const [leftLocationCurrentTime, setLeftLocationCurrentTime] = useState(() => {
      return localStorage.getItem("leftLocationCurrentTime") || "";
    });
    const [reachedTPpickupZoneCurrentTime, setReachedTPpickupZoneCurrentTime] = useState(() => {
      return localStorage.getItem("reachedTPpickupZoneCurrentTime") || "";
    });
    const [pickupStudentCurrentTime, setPickupStudentCurrentTime] = useState(() => {
      return localStorage.getItem("pickupStudentCurrentTime") || "";
    });
    const [completedInvehicleCurrentTime, setCompletedInvehicleCurrentTime] = useState(() => {
      return localStorage.getItem("completedInvehicleCurrentTime") || "";
    });

  // const [showTopButtonNumber, setShowTopButtonNumber] = useState(0);

  // Initialize state from local storage or set to 0 if it doesn't exist
  // const [showTopButtonNumber, setShowTopButtonNumber] = useState(() => {
  //   const savedValue = localStorage.getItem("showTopButtonNumber");
  //   return savedValue ? JSON.parse(savedValue) : 0;
  // });

 

  const [webcamReady, setWebcamReady] = useState(false);
  // map starts
  const [directionsRes, setDirectionsRes] = useState(null);
  const [map, setMap] = useState(/** @type google.maps.map */ (null));
  const [mapZoom, setMapZoom] = useState(defaultZoom);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [otpAPIResponse, setOtpAPIResponse] = useState("");
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [locations, setLocations] = useState(defaultLocations);
  const [isTrainingStart, setIsTrainingStart] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [previousPosition, setPreviousPosition] = useState(null);
  const [currentRoute, setCurrentRoute] = useState([]);
  const [currentDummyRoute, setCurrentDummyRoute] = useState([
    { lat: 33.7408062, lng: 73.1977244, timestamp: 0 }, // Start at 0 seconds
    { lat: 33.7418062, lng: 73.1987244, timestamp: 3 }, // 3 seconds later
    { lat: 33.7428062, lng: 73.1997244, timestamp: 6 }, // 6 seconds later
    { lat: 33.7438062, lng: 73.2007244, timestamp: 9 }, // 9 seconds later
    { lat: 33.7448062, lng: 73.2017244, timestamp: 12 }, // 12 seconds later
    { lat: 33.7458062, lng: 73.2027244, timestamp: 15 }, // 15 seconds later
    { lat: 33.7468062, lng: 73.2037244, timestamp: 18 }, // 18 seconds later
  ]);
  
  const [instructorActualAddress, setInstructorActualAddress] = useState(null);
  const [marker, setMarker] = useState(null);  // Marker for the instructor
  const [paused, setPaused] = useState(false);


  const [isFloatingVisible, setIsFloatingVisible] = useState(false);
  const [minimizeStudentImage, setMinimizeStudentImage] = useState(false);
  const recordingStartRef = useRef(null); // Use ref for recording start time

  const setRecordingTime = () => {
    const now = performance.now();
    console.log("now is:",now)
    recordingStartRef.current = now; // Set the ref value
  };

  const getCurrentTimestamp = () => {
    if (recordingStartRef.current) {
      return (performance.now() - recordingStartRef.current) / 1000; // Time in seconds
    }
    return 0;
  };


  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (!isPageVisible) {
      // If page becomes hidden (user gets distracted), show floating window
      setIsFloatingVisible(true);
    } else {
      setIsFloatingVisible(false); // Page is active again
    }
  }, [isPageVisible]);
  
  const Dispatch = useDispatch();
  const mapObj = useMap();
  const videoRef = useRef(null);
  const params = useParams();
  const EventId = params.eventId;
  console.log("EventId is in map component:",EventId)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Start sending live location updates");
  
    // Clear any existing interval to avoid duplicate intervals
    if (intervalId) {
      clearInterval(intervalId);
    }
  
    // Create an interval to fetch the user's current location and send updates
    const newIntervalId = setInterval(() => {
      // Use the Geolocation API to get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
  
          console.log("Current Position:", { lat, lng });
  
          // Dispatch the updated coordinates
          Dispatch(UpdateLiveLocationInstructorCoordinates(EventId, InstructorUserId, lat, lng));
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: true, // Use GPS for better accuracy
          maximumAge: 10000,        // Cache position for 10 seconds
          timeout: 5000,            // Timeout after 5 seconds
        }
      );
    }, 10000); // 10 seconds interval
  
    setIntervalId(newIntervalId);
  
    // Cleanup interval on component unmount
    return () => {
      clearInterval(newIntervalId);
      setIntervalId(null);
    };
  }, []); // Empty dependency array ensures this effect runs only on mount


  const socket = getSocket();

  const { InstructorAddress } = useSelector(
    (Store) => Store.InstructorReducer
  );

  console.log("InstructorAddress in the component is:",InstructorAddress)
  const instructorAddressfromDB = InstructorAddress?.instructor?.Address || "Toronto, ON, Canada"
  console.log("instructorAddress:",instructorAddressfromDB)

  const studentAddressfromDB = InstructorAddress?.studentInfo?.PickupAddress || "Toronto, ON, Canada"
  console.log("studentAddress:",studentAddressfromDB)

  const ClassStartTime = InstructorAddress?.timeTableRecord?.startDate || ""
  console.log("ClassStartTime:",ClassStartTime)

  const ClassEndTime = InstructorAddress?.timeTableRecord?.endDate || ""
  console.log("ClassEndTime:",ClassEndTime)

  const StudentFirstName = InstructorAddress?.userInfo?.FirstName || ""
  console.log("StudentFirstName:",StudentFirstName)

  const StudentLastName = InstructorAddress?.userInfo?.LastName || ""
  console.log("StudentLastName:",StudentLastName)

  const StudentImage = InstructorAddress?.userInfo?.StudentImage || ""
  console.log("StudentImage:",StudentImage)

  const StudentEmail = InstructorAddress?.userInfo?.Email || ""
  console.log("StudentEmail:",StudentEmail)

  const PickupService = InstructorAddress?.CoursePackageData?.PickupService || ""
  console.log("PickupService:",PickupService)

  const [showTopButtonNumber, setShowTopButtonNumber] = useState(() => {
    // Attempt to retrieve the saved value from localStorage
    const savedValue = localStorage.getItem("showTopButtonNumber");
    
    // If a value exists in localStorage, parse and return it; otherwise, return 0
    return savedValue ? JSON.parse(savedValue) : 0;
  });

  useEffect(() => {
    console.log("Updating student picked field status");
    Dispatch(UpdatePickedStudentUserFieldA(EventId));
  }, []); // Empty dependency array ensures this effect runs only on mount
  
  useEffect(() => {
    // Only proceed if PickupService has a valid value ("Yes" or "No")
    if (PickupService && !localStorage.getItem("showTopButtonNumber")) {
      const initialValue = PickupService === "Yes" ? 0 : 2;
      setShowTopButtonNumber(initialValue);
      localStorage.setItem("showTopButtonNumber", JSON.stringify(initialValue));
    }
  }, [PickupService]);


  // Update local storage whenever showTopButtonNumber changes
   useEffect(() => {
    if (PickupService){
    localStorage.setItem("showTopButtonNumber", JSON.stringify(showTopButtonNumber));
    }
  }, [showTopButtonNumber]);

  useEffect(() => {
    // Get showTopButtonNumber from local storage
    const storedShowTopButtonNumber = Number(localStorage.getItem("showTopButtonNumber") || 0);

    // If storedShowTopButtonNumber is greater than 1, set classTimingDialog to true
    if (storedShowTopButtonNumber >= 1) {
      setClassTimingDialog(true);
    }
  }, []);

   useEffect(() => {
      if (!isTrainingStart) {
        const fetchLocation = async () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const { latitude, longitude } = position.coords;
                setMapCenter({ lat: latitude, lng: longitude });

                // Create the address object
                const addressObject = {
                  address: instructorAddressfromDB, // Address from the database
                  latlng: { lat: latitude, lng: longitude }, // Current latitude and longitude
                };
                console.log("addressObject in useEffect is:", addressObject);

                // Update the state with the address object
                setInstructorActualAddress(addressObject);
              },
              (error) => {
                console.error('Error getting location:', error);
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
          }
        };

        // Fetch location initially
        fetchLocation();

        const interval = setInterval(() => {
          fetchLocation(); // Fetch the current location at intervals
        }, 10000); // Update every 10 seconds

        return () => clearInterval(interval); // Cleanup on unmount
      }
    }, [instructorAddressfromDB, isTrainingStart]);  // Dependency on `isTrainingStart`
    

  useEffect(() => {
    if (EventId) Dispatch(GetInstructorAndStudentAddressA(EventId));
  }, [Dispatch, EventId]);

  useEffect(() => {
    if (
      !isTrainingStart &&
      locations.origin.address &&
      locations.destination.address
    ) {
      handleMapDirections();
    }
  }, [map, locations]);

  useEffect(() => {
    if (!isTrainingStart && distance) {
      let distanceInMeter = convertDistanceToMeters(distance);
      if (distanceInMeter <= 200) {
        setShowTopButtonNumber(2)
        // handleCamera();
      }
    }
  }, [distance]);

  useEffect(() => {
    const getLocations = async () => {
      if (instructorActualAddress && !isTrainingStart){
      // const instrutorAddress = await getCurrentLocation("instructor",instructorAddressfromDB);
      // console.log("instrutorAddress in useeffect is:",instrutorAddress)
      const studentAddress = await getCurrentLocation("student",studentAddressfromDB);
      console.log("instructorActualAddress in useeffect is:",instructorActualAddress)
      console.log("studentAddress in useeffect is:",studentAddress)
      setLocations((oldLat) => ({
        ...oldLat,
        origin: instructorActualAddress,
        destination: studentAddress,
      }));
      //origin: instructorActualAddress,

      // setTimeout(() => {
      //   setLocations((oldLat) => ({
      //     ...oldLat,
      //     origin: studentAddress,
      //     destination: studentAddress,
      //   }));
      // }, 10000);
    };
  }
    getLocations();
  }, [instructorActualAddress]);

   // Save current position to local storage whenever it changes
   useEffect(() => {
    if (currentPosition) {
      localStorage.setItem('currentPosition', JSON.stringify(currentPosition));
    }
  }, [currentPosition]);

  // Save current route to local storage whenever it changes
  useEffect(() => {
    if (currentRoute.length > 0) {
      console.log("currentRoute in currentRoute.length > 0 is:",currentRoute)
      localStorage.setItem('currentRoute', JSON.stringify(currentRoute));
    }
  }, [currentRoute]);

  // Start tracking instructor's movement once the lesson starts
useEffect(() => {
  let lastUpdateTime = 0; // Store the timestamp of the last update
  if (isTrainingStart) {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newPosition = { lat: latitude, lng: longitude };

        const timestamp = getCurrentTimestamp();
        const currenttimestamp = Date.now(); // Get the current timestamp
        const newLocation = { lat: latitude, lng: longitude, timestamp };

         // Only update if enough time has passed since the last update
      if (currenttimestamp - lastUpdateTime >= 3000) { // Throttle updates to every 3 seconds
        // Check if there's a previous position before calculating distance
        if (previousPosition) {
          // Calculate the distance between current and previous position
            const distanceBetweenPoints = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(newPosition.lat, newPosition.lng),
            new google.maps.LatLng(previousPosition.lat, previousPosition.lng)
          );

          // Only update if the distance is greater than 5 meters
          if (distanceBetweenPoints > 5) {
            setLocations(newPosition);
            setPreviousPosition(newPosition);
            setCurrentPosition(newPosition);

            // Append the new position to the currentRoute
            setCurrentRoute((prevRoute) => [...prevRoute, newLocation]);
            console.log("CurrentRoute is:", currentRoute);
          }
        } else {
          setLocations(newPosition)
          // If this is the first position, initialize previousPosition
          setPreviousPosition(newPosition);
          setCurrentPosition(newPosition);

          // Add the first position to the route
          setCurrentRoute([newLocation]);
          console.log("CurrentRoute initialized with first position:", newPosition);
        }
        // Update the last update time
        lastUpdateTime = timestamp;
      }
      },
      (error) => {
        console.error("Error watching position:", error);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId); // Cleanup when component unmounts
    };
  }
}, [isTrainingStart, previousPosition]);


  useEffect(() => {
    if (isTrainingStart) {
      console.log("in marker blue dot useeffect")
      const initialPosition = currentRoute[0];
      const savedRoute = localStorage.getItem('currentRoute');
      if (savedRoute) {
        const route = JSON.parse(savedRoute);
        if (route.length > 0 && map) {
          const startPosition = route[0]; // Get the first point

            // Check if a marker already exists and remove it
          if (marker) {
            marker.setMap(null);  // Remove the existing marker
          }

          const newMarker = new google.maps.Marker({
            position: startPosition,
            map: map,
            title: "Start Position",
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",  // Blue marker
            },
          });
          setMarker(newMarker); // Save the marker in state
        }
    }
  }
  }, [isTrainingStart, currentRoute]);


  const drawPolyline = () => {
    // Retrieve the currentRoute from localStorage
    const storedRoute = localStorage.getItem('currentRoute');
  
    if (storedRoute) {
      // Parse the storedRoute from localStorage
      const currentRoute = JSON.parse(storedRoute);
  
      if (map && currentRoute.length > 1) {
        // Clear previous polyline (if any)
        if (window.currentPolyline) {
          window.currentPolyline.setMap(null); // Remove the previous polyline
        }
  
        // Convert the points into LatLng objects for Polyline
        const routePath = currentRoute.map(point => new google.maps.LatLng(point.lat, point.lng));
  
        // Create a new Polyline using the routePath
        const polyline = new google.maps.Polyline({
          path: routePath,
          geodesic: true, // Make it a geodesic polyline for curvature (optional)
          strokeColor: '#FF0000', // Color of the line
          strokeOpacity: 1.0,
          strokeWeight: 7, // Increased stroke weight
        });
  
        // Store the polyline globally so you can clear it later
        window.currentPolyline = polyline;
  
        // Set the polyline on the map
        polyline.setMap(map);
      }
    } else {
      console.error("No route found in localStorage");
    }
  };
  
  useEffect(() => {
    if (isTrainingStart) {
      drawPolyline(); // Draw the polyline every time the route updates
    }
  }, [currentRoute, map]);
  
  useEffect(() => {
    if (currentPosition) {
      socket.emit("live-location", {
        eventId: params.eventId,
        liveLocation: currentPosition,
      });
    }
  }, [currentPosition]);

  const handleMapDirections = async () => {
    const directionsService = new google.maps.DirectionsService();
    const resulte = await directionsService.route({
      origin: locations.origin.latlng,
      destination: locations.destination.latlng,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsRes(resulte);
    setDistance(resulte.routes[0].legs[0].distance.text);
    setDuration(resulte.routes[0].legs[0].duration.text);
  };

  const handleCamera = () => {
    // setOpenCamera(true);
    if(openOTPModal === false && openBypassOTPModal === false){
    setOpenPopup(true);
    }
  };

  const handleModalYes = async () => {
    try {
      setOpenPopup(false);
      setOpenOTPModal(true);
    } catch (error) {
      alert(error.message);
    }
  };
  const handleModalNo = () => {
    // navigate("/lessons");
    // setStreams();
    // setDistance("");
    // setDuration("");
    // setOpenPopup(false);
    // setOpenCamera(false);
    // setDirectionsRes(null);
    // setMapCenter(defaultCenter);
    // setMapZoom(defaultZoom);
    // setOpenOTPModal(false);
    setOpenPopup(false);
    setOpenOTPModal(false)
  };

  // const startRecording = async () => {
  //   try {
  //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
  //       // video: true,
  //       video: { displaySurface: "monitor" }, // Prefers the entire screen
  //       audio: true,
  //     });

  //     const audioStream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: false,
  //     });

  //     // Combine screen and audio streams
  //     const combinedStream = new MediaStream([
  //       ...screenStream.getVideoTracks(),
  //       ...audioStream.getAudioTracks(),
  //     ]);

  //     // Initialize RecordRTC
  //     const recordRTC = new RecordRTC(combinedStream, {
  //       type: "video",
  //     });

  //     recordRTC.startRecording();

  //     setRecorder(recordRTC);
  //     setRecordingStream(combinedStream);
  //     setRecording(true);

  //   } catch (error) {
  //     console.error("Error starting recording:", error);
  //   }
  // };

  let savedScreenStream; // Global or external variable to store the screen stream

const startRecording = async () => {
  try {
    // Check if the user has an existing screen stream
    if (savedScreenStream && savedScreenStream.active) {
      console.log("Reusing saved screen stream.");
    } else {
      console.log("Prompting user to select a screen to share.");

      // Get the screen stream (this will prompt the user if not already granted)
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { displaySurface: "monitor" }, // Prefer entire screen
        audio: true,
      });

      savedScreenStream = screenStream; // Save the stream for future use
    }

    // Get the audio stream
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    // Combine screen and audio streams
    const combinedStream = new MediaStream([
      ...savedScreenStream.getVideoTracks(),
      ...audioStream.getAudioTracks(),
    ]);

    // Initialize RecordRTC
    const recordRTC = new RecordRTC(combinedStream, {
      type: "video",
    });

    // Start recording
    recordRTC.startRecording();

    // Update states
    setRecorder(recordRTC);
    setRecordingStream(combinedStream);
    setRecording(true);

    console.log("Recording started successfully.");
  } catch (error) {
    console.error("Error starting recording:", error);
  }
};

  const stopRecording = async () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();

        // Save recording to server
        const formData = new FormData();
        formData.append("file", blob, "recording.webm");
        formData.append("eventId", params.eventId); // Append eventId to the form data

        console.log("Event ID in stop recording is:", params.eventId);

        Dispatch(UploadScreenRecordingToS3(EventId,formData))

      //   fetch(`${BaseUrl}/api/upload/recording/rtc?eventId=${EventId}`, {
      //     method: "POST",
      //     body: formData,
      //   })
      //     .then((res) => res.json())
      //     .then((data) => console.log("File saved successfully:", data))
      //     .catch((error) => console.error("Error saving file:", error));
       });

      // Stop all tracks
      recordingStream.getTracks().forEach((track) => track.stop());
      setRecording(false);
    }
  };

  const handleStop = async () => {
    // Stop recording using the `stopRecording` function
    await stopRecording();
    Dispatch(UpdateStartRecordingAndPickedStudentUserFieldA(EventId))
    if (streams) {
      streams.getTracks().forEach((track) => track.stop());
      videoRef.current = null;
      setStreams(null);
      socket.emit("recording-stop", params.eventId);
    }
    if (currentRoute) {
      socket.emit("location-stop", {
        eventId: params.eventId,
        liveLocation: currentRoute,
      });
    }
    setPaused(true); // Set paused to true to stop the timers
    setOpenPopup(false);
    setStreams(null);
    setOpenCamera(false);
    // setClassSummaryDialog(true);
    // Delay setting class summary dialog to true by 5 seconds
    setTimeout(() => {
      setClassSummaryDialog(true);
  }, 10000);
    // Clear local storage
    localStorage.removeItem('currentPosition');
    localStorage.removeItem('currentRoute');
    localStorage.removeItem('remainingTime');
    localStorage.removeItem('elapsedTime');
    // localStorage.removeItem("leftLocationCurrentTime");
    // localStorage.removeItem("reachedTPpickupZoneCurrentTime");
    // localStorage.removeItem("pickupStudentCurrentTime");
    // localStorage.removeItem("completedInvehicleCurrentTime");
    // localStorage.removeItem("showTopButtonNumber");


    // Disconnect the socket to allow a fresh connection for the next session
  // socket.disconnect();

  // Optionally reconnect the socket for the next session, if needed
 // Optionally reconnect the socket after a delay to ensure a fresh connection
//  setTimeout(() => {
//   socket.connect();

//   // Remove listeners that were set up during the recording session
//   socket.off("upload-error");
//   socket.off("recording-start");
//   socket.off("recording-stop");
// }, 5000); // Delay allows disconnect to complete before reconnecting
  };

  const handleStramming = async (stream) => {
    // Start recording using the `startRecording` function
    await startRecording();
    setRecordingTime();
    Dispatch(UpdateStartRecordingUserFieldA(EventId))

    const sender = new MediaRecorder(stream);
    sender.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        if (!socket.connected) {
          socket.connect();
        }
        socket.emit("recording-start", {
          stream: event.data,
          eventId: params.eventId,
          liveLocation: currentPosition,
        });
      }
    };
    // Start sending chunks at 1-second intervals (1000 ms)
    sender.start(1000);
  };

  const handleStudentVerifing = async () => {
    if(otpAPIResponse === "verified"){
      setShowTopButtonNumber((prev) => prev + 1);
      setBottomCarMinimizingIcon(false)
      setClassTimingDialog(true)
      try {
        await setStreams(videoRef.current.stream);
        handleStramming(videoRef.current.stream);
      } catch (error) {
        alert(error.message);
      }
      setOpenOTPModal(false);
      // moveAlongPath();
      setIsTrainingStart(true);
      setDirectionsRes(null);
      // const address_one = await getCurrentLocation("student");
      // const address_two = await getCurrentLocation("Ontario L3Y 4W1.Canada");
      // setLocations({ origin: address_one, destination: address_two });
    
  }
  };

  const handleDeliverClass = (newStatus, feedback) => {

    setTimeout(() => {
    Dispatch(
      updateSchedule({
          EventId, // Make sure EventId is defined
          newStatus,
          ClassFeedback: feedback,
      })
    );

    // Only increment showTopButtonNumber if the newStatus is "Delivered"
    if (newStatus === "Delivered") {
      setShowTopButtonNumber((prev) => prev + 1);
    }

    setBottomCarMinimizingIcon(false);
    setClassTimingDialog(true);
    setOpenCancelClassPopup(false);
    setClassSummaryDialog(false);
    socket.disconnect();
    // Delay navigation to /lessons by 10 seconds
    setTimeout(() => {
      navigate("/lessons");
  }, 7000); // 10,000 ms = 10 seconds
    // navigate("/lessons");
  }, 5000); // 5000 ms = 5 seconds
  };

  const handleLoadMap = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (webcamReady) {
      handleStudentVerifing();  // Call handleStudentVerifing only when webcam is ready
    }
  }, [webcamReady, otpAPIResponse]);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // Check if the webcam needs to be reinitialized
      if (videoRef.current && !videoRef.current.srcObject) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          videoRef.current.srcObject = stream; // Set the new stream to the video element
        } catch (error) {
          console.error("Failed to access webcam on visibility change:", error);
          alert("Please check camera permissions or reopen the camera.");
        }
      }
    }
  };
  
  // Add visibility and focus event listeners based on isTrainingStart
  useEffect(() => {
    if (!isTrainingStart) return; // Run effect only when isTrainingStart is true
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleVisibilityChange);
  
    // Check webcam status at intervals
    const checkWebcam = setInterval(() => {
      if (videoRef.current && videoRef.current.srcObject === null) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
          .then((stream) => {
            videoRef.current.srcObject = stream;
          })
          .catch((error) => console.error("Error accessing webcam:", error));
      }
    }, 10000); // check every 10 seconds
  
    // Cleanup event listeners and interval on unmount or if isTrainingStart changes
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleVisibilityChange);
      clearInterval(checkWebcam);
    };
  }, [isTrainingStart]); // Add isTrainingStart as a dependency
  
  
  const checkAudioPermissions = async () => {
    try {
      // Check permission status for microphone
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'denied') {
        alert('Microphone access is denied. Please enable microphone permissions in your browser settings.');
      } else if (permissionStatus.state === 'prompt') {
        // This will prompt the user if they haven’t already granted or denied permission
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(() => console.log('Microphone access granted'))
          .catch(() => alert('Microphone access is required to record audio.'));
      }
    } catch (error) {
      console.error('Error checking microphone permission:', error);
    }
  };
  
  // Call this function in useEffect or when the component mounts
  useEffect(() => {
    checkAudioPermissions();
  }, []);


  useEffect(() => {
    socket.on("upload-error", (error) => {
      alert(error.message); // Display the actual error message in an alert box
    });
  
    return () => {
      socket.off("upload-error");
    };
  }, []);

  // const [isFormDirty, setIsFormDirty] = useState(true);

  // const blocker = () => {
  //   if (isFormDirty) {
  //     return window.confirm('Are you sure you want to leave?');
  //   }
  //   return true;
  // };

  // useBlocker(blocker, isFormDirty);

  usePageUnloadWarning("Are you sure you want to leave?");  

  const selectedSOSFeature = localStorage.getItem('selectedSOSFeature');
  
  return (
    <div className="relative h-[750px] w-full bg-violet-100">
      <NetworkStatus
      />

    {(selectedSOSProvidedFeatures === "Harassment" || 
      selectedSOSFeature === "Harassment") && 
      <LocationUpdater EventId={EventId} />
    }

      {/* <SoSFunctionality
      setOpenSOSPopup={setOpenSOSPopup} 
      />

      <SOSMobileIcon
      setOpenSOSMobilePopup={setOpenSOSMobilePopup}
      />   */}

      {isTrainingStart && (
      <SOSMobileIcon
      setOpenSOSMobilePopup={setOpenSOSMobilePopup}
      />
    ) }

      {openSOSMobilePopup && (
        <SOSMobileFeaturePopup
         setOpenSOSMobilePopup={setOpenSOSMobilePopup}
         setOpenSOSEmergencyMobileTimerPopup={setOpenSOSEmergencyMobileTimerPopup}
         setSelectedSOSProvidedFeatures={setSelectedSOSProvidedFeatures}  
         setOpenSOSMobileBeaconClickPopup={setOpenSOSMobileBeaconClickPopup}
        />
      ) }

      {openSOSEmergencyMobileTimerPopup && (
        <SOSMobileEmergencyTimerPopup 
        selectedSOSProvidedFeatures={selectedSOSProvidedFeatures}
        setOpenSOSEmergencyMobileTimerPopup={setOpenSOSEmergencyMobileTimerPopup}
        EventId={EventId}
        />
      ) }

      {openSOSMobileBeaconClickPopup && (
        <SOSMobileBeaconClick 
         setOpenSOSMobileBeaconClickPopup={setOpenSOSMobileBeaconClickPopup}
         setOpenSOSEmergencyMobileTimerPopup={setOpenSOSEmergencyMobileTimerPopup}
         setSelectedSOSProvidedFeatures={setSelectedSOSProvidedFeatures}  
         setOpenSOSMobileCreateLinkPopup={setOpenSOSMobileCreateLinkPopup}
        />
      ) }

      {openSOSMobileCreateLinkPopup && (
        <SOSMobileCreateLocationLink
         EventId={EventId}
         setOpenSOSMobileCreateLinkPopup={setOpenSOSMobileCreateLinkPopup}
        />
      ) }


     {isTrainingStart && (
      <SoSFunctionality
      setOpenSOSPopup={setOpenSOSPopup} 
      />
    )} 

      {openSOSPopup && 
       <SOSPopup
        setOpenSOSPopup={setOpenSOSPopup}
        setOpenSOSEmergencyTimerPopup={setOpenSOSEmergencyTimerPopup}
        setSelectedSOSProvidedFeatures={setSelectedSOSProvidedFeatures}  />
       }

       {openSOSEmergencyTimerPopup && 
       <EmergencyTimerPopup
       setOpenSOSEmergencyTimerPopup={setOpenSOSEmergencyTimerPopup} 
       setOpenSOSAlertPopup={setOpenSOSAlertPopup}
       selectedSOSProvidedFeatures={selectedSOSProvidedFeatures}
       EventId={EventId}
       />
       }

       {openSOSAlertPopup &&
       <SOSAlertPopup 
       setOpenSOSAlertPopup={setOpenSOSAlertPopup}
       />
       }
      
      {!mapObj.isLoaded ? (
        <p>Map is loading...</p>
      ) : (
        <CustomeGoogleMap
          center={mapCenter}
          directionsRes={directionsRes}
          onLoadMap={handleLoadMap}
          currentPosition={currentPosition}
          currentRoute={currentRoute}
          isTrainingStart={isTrainingStart}
          map={map}
        />
      )}

      <InVehicleClassSidebar
         StudentFirstName={StudentFirstName}
        StudentLastName={StudentLastName}
        StudentImage={StudentImage}
        showTopButtonNumber={showTopButtonNumber}
        setShowTopButtonNumber={setShowTopButtonNumber}
        StudentEmail={StudentEmail}
        setLeftLocationCurrentTime={setLeftLocationCurrentTime}
        leftLocationCurrentTime={leftLocationCurrentTime}
        setReachedTPpickupZoneCurrentTime={setReachedTPpickupZoneCurrentTime}
        reachedTPpickupZoneCurrentTime={reachedTPpickupZoneCurrentTime}
        setPickupStudentCurrentTime={setPickupStudentCurrentTime}
        pickupStudentCurrentTime={pickupStudentCurrentTime}
        setCompletedInvehicleCurrentTime={setCompletedInvehicleCurrentTime}
        completedInvehicleCurrentTime={completedInvehicleCurrentTime}
        setOpenCancelClassPopup={setOpenCancelClassPopup}
        setOpenPopup={setOpenPopup}
        ClassStartTime={ClassStartTime}
        ClassEndTime={ClassEndTime}
        onStop={handleStop}
        paused={paused}
        PickupService={PickupService}
        EventId={EventId}
       />

      {
        <MobileTopBtns
        setClassTimingDialog={setClassTimingDialog}
        setShowTopButtonNumber={setShowTopButtonNumber}
        showTopButtonNumber={showTopButtonNumber}
        setBottomCarMinimizingIcon={setBottomCarMinimizingIcon}
        setOpenPopup={setOpenPopup}
        ClassStartTime={ClassStartTime}
        ClassEndTime={ClassEndTime}
        onStop={handleStop}
        StudentFirstName={StudentFirstName}
        StudentLastName={StudentLastName}
        StudentImage={StudentImage}
        StudentEmail={StudentEmail}
        setOpenCancelClassPopup={setOpenCancelClassPopup}
        setMinimizeStudentImage={setMinimizeStudentImage}
        minimizeStudentImage={minimizeStudentImage}
        paused={paused}
        EventId={EventId}
         />
      }
      {minimizeStudentImage && showTopButtonNumber === 3 && (
        <div className="fixed top-[135px] right-[10px]">
        <TimeInfo 
            ClassStartTime={ClassStartTime}
            ClassEndTime={ClassEndTime}
            paused={paused} // Pass paused prop to TimeInfo
        />
        </div>
      )}

      { classTimingDialog &&
        <MobileLowerInfotab
        setBottomCarMinimizingIcon={setBottomCarMinimizingIcon}
        setClassTimingDialog={setClassTimingDialog}
        setLeftLocationCurrentTime={setLeftLocationCurrentTime}
        leftLocationCurrentTime={leftLocationCurrentTime}
        showTopButtonNumber={showTopButtonNumber}
        setShowTopButtonNumber={setShowTopButtonNumber}
        setReachedTPpickupZoneCurrentTime={setReachedTPpickupZoneCurrentTime}
        reachedTPpickupZoneCurrentTime={reachedTPpickupZoneCurrentTime}
        setPickupStudentCurrentTime={setPickupStudentCurrentTime}
        pickupStudentCurrentTime={pickupStudentCurrentTime}
        setCompletedInvehicleCurrentTime={setCompletedInvehicleCurrentTime}
        completedInvehicleCurrentTime={completedInvehicleCurrentTime}
        PickupService={PickupService}
        EventId={EventId}
         />
      }

      {openCancelClassPopup &&
      <CancelClassModal 
      open={openCancelClassPopup}
      setOpenCancelClassPopup={setOpenCancelClassPopup}
      setClassSummaryDialog={setClassSummaryDialog}
      />
      }

      {bottomCarMinimizingIcon &&
      <BottomCarIcon
      setClassTimingDialog={setClassTimingDialog}
      setBottomCarMinimizingIcon={setBottomCarMinimizingIcon}  />
      }

      {/* <FloatingWindow isVisible={isFloatingVisible}>
            <CustomeGoogleMap
              center={mapCenter}
              directionsRes={directionsRes}
              currentPosition={currentPosition}
              isTrainingStart={isTrainingStart}
            />
      </FloatingWindow> */}
      {/* <div className="absolute top-0 flex w-full flex-col gap-1"> */}
      {isTrainingStart && 
      <LiveStatus
        streams={streams}
        onClick={handleCamera}
        onStop={handleStop}
      />
      }
       {/* {directionsRes && <InfoSummer distance={distance} duration={duration} />}  */}

      {/* {isTrainingStart &&
        <TimeInfo
        ClassStartTime={ClassStartTime}
        ClassEndTime={ClassEndTime}
         />
      } */}

      {/* </div> */}
      {openCamera && (
        <div className="absolute bottom-[0px] right-5 z-50 h-52 w-52 rounded-md">
          <Webcam 
          ref={videoRef} 
          className="h-full w-full rounded-md"
          audio={true} // Enable audio recording
          onUserMedia={() => setWebcamReady(true)}  // Set webcam ready when stream is initialized
           />
        </div>
      )}
      {openPopup && (
        <PickUpModal
          open={openPopup}
          onModalYes={handleModalYes}
          onModalNo={handleModalNo}
          streams={streams}
        />
      )}
      {openOTPModal && (
        <OTPModal
          open={openOTPModal}
          handleClose={() => handleModalNo()}
          onStdudentVerifing={handleStudentVerifing}
          EventId={EventId}
          setOpenCamera={setOpenCamera}
          openCamera={openCamera}
          setOtpAPIResponse={setOtpAPIResponse}
          otpAPIResponse={otpAPIResponse}
          setOpenBypassOTPModal={setOpenBypassOTPModal}
          setOpenOTPModal={setOpenOTPModal}
        />
      )}

      {openBypassOTPModal && (
        <BypassOTPModal
         open={openBypassOTPModal}
         setOpenBypassOTPModal={setOpenBypassOTPModal}
         setOpenCamera={setOpenCamera}
         setOpenOTPModal={setOpenOTPModal}
         setOtpAPIResponse={setOtpAPIResponse}
         EventId={EventId}
        />
      )}

      {/* {classSummaryDialog && (
        <ClassSummaryDialog
          open={classSummaryDialog}
          handleClose={() => setClassSummaryDialog(false)}
          onDelivered={handleDeliverClass}
        />
      )} */}
      {classSummaryDialog && (
        <ClassSummaryModal
          open={classSummaryDialog}
          handleClose={() => setClassSummaryDialog(false)}
          // onDelivered={handleDeliverClass}
          onDelivered={(feedback) => handleDeliverClass("Delivered", feedback)}
          onMissed={(feedback) => handleDeliverClass("Missed", feedback)}
        />
      )}
    </div>
  );
};

export default StartClass;

const LiveStatus = ({ streams, onClick, onStop }) => {
  console.log("streams in live status is :",streams)
  return (
    <div className="absolute top-[-30px] flex w-full items-center justify-center z-50">
      {streams ? (
        <div className="inline-flex items-center justify-center gap-[10px]">
          <LiveTvIcon className="text-error" fontSize="medium" />
          <p className="hidden lg:block text-3xs text-error">This class is being recorded!</p>
        </div>
      ) : null}
    </div>
  );
};

// const LiveStatus = ({ streams, onClick, onStop }) => {
//   console.log("streams in live status is :",streams)
//   return (
//     <div className="hidden lg:absolute top-0 flex h-5 w-full items-center justify-center gap-4 bg-[#e5e5e7] px-20 py-5">
//       {streams ? (
//         <div className="inline-flex items-center justify-center">
//           <LiveTvIcon className="text-error" fontSize="medium" />
//           <p className="text-3xs text-error">This class is being recorded!</p>
//           <Button className="self-end text-error" onClick={onStop}>
//             Stop
//           </Button>
//         </div>
//       ) : null}
//     </div>
//   );
// };

// useEffect(() => {
//   if (isTrainingStart) {
//     setTimeout(() => {
//       const lastLocation = locations.destination;
//       setLocations({ origin: lastLocation, destination: lastLocation });
//       setTimeout(() => {
//         setClassSummaryDialog(true);
//       }, 3000);
//     }, 10000);
//   }
// }, [isTrainingStart]);

// useEffect(() => {
//   const successCallback = async (position) => {
//     const latlng = {
//       lat: position.coords.latitude,
//       lng: position.coords.longitude,
//     };
//     const addressIntructor = await fetchLocationName(latlng);
//     const addressStduent = await fetchLocationName(studLatLng);
//     setIntructorLocation({ address: addressIntructor, latlng });
//     setLocations({
//       origin: addressIntructor,
//       destination: addressStduent,
//     });
//   };

//   const errorCallback = (error) => {
//     alert(error.message);
//   };

//   navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
// }, []);
