import React from "react";
import CallIcon from '@mui/icons-material/Call';
import PhoneIcon from "./Assets/Iconawesome-phone-alt.svg"
import HarrasmentIcon from "./Assets/harassicon-01.png"
import HomeIcon from "./Assets/Iconawesome-home.svg"
import SchoolBuildingIcon from "./Assets/Iconawesome-building.svg"


function SOSPopup({setOpenSOSPopup, setOpenSOSEmergencyTimerPopup, setSelectedSOSProvidedFeatures}) {
    const handleDivsClick = value =>{
        setOpenSOSEmergencyTimerPopup(true)
        setSelectedSOSProvidedFeatures(value)
        setOpenSOSPopup(false)
    }
    return(
        <div className="fixed inset-0 z-10 flex items-center justify-center ">
            <div className="relative rounded-[23px] p-[20px] shadow-lg w-[350px]"
            style={{
                background: 'linear-gradient(90deg, #FFFFFF 0%, #D6D8FF 100%) no-repeat padding-box',
            }}>
                <div className="absolute top-2 right-2">
                    <img
                        className="absolute right-3 top-[6px] cursor-pointer"
                        src={require("./Assets/Cross.svg").default}
                        alt=""
                        onClick={() => setOpenSOSPopup(false)}
                    />
                </div>
                <p className="ml-[30px] text-center text-[14px] lg:text-[18px] text-[#000000] font-bold w-[250px]">Use SOS In Case Of Emergency Only</p>
                
                <div className="flex w-full justify-center items-center mt-[15px]">
                    <div className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] rounded-full bg-[#FF0000] flex justify-center items-center">
                        <p className="font-bold text-[18px] lg:text-[27px] text-[#FFFFFF]">SOS</p>
                    </div>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px] gap-[10px]">
                    <div className="bg-[#FFFFFF] rounded-[9px] border-[1px] border-[#A1A3EF] w-[120px] h-[120px] lg:w-[120px] lg:h-[120px] flex flex-col justify-center items-center gap-[5px] cursor-pointer"
                    style={{
                        border : "1px solid #A1A3EF"
                    }}
                    onClick={() => handleDivsClick('911')} // Pass "911" as the value
                    >
                        <img src={PhoneIcon} alt="siren" className="w-[40px] h-[40px] lg:w-auto lg:h-auto" />
                        {/* <CallIcon  sx={{ color: "#A1A3EF",  fontSize: 35  }} /> */}
                        <p className="text-[14px] lg:text-[18px]">911</p>
                    </div>
                    <div className="bg-[#FFFFFF] rounded-[9px] border-[1px] border-[#A1A3EF] w-[120px] h-[120px] lg:w-[120px] lg:h-[120px] p-[10px] flex flex-col justify-center items-center gap-[5px] cursor-pointer"
                    style={{
                        border : "1px solid #A1A3EF"
                    }}
                    onClick={() => handleDivsClick('Harassment')} // Pass "Harassment" as the value
                    >
                        {/* <CallIcon  sx={{ color: "#A1A3EF",  fontSize: 35  }} /> */}
                        <img src={HarrasmentIcon} alt="siren" className="w-[40px] h-[40px] lg:w-auto lg:h-[50px]" />
                        <p className="text-[14px] lg:text-[18px] text-center">Report Harassment</p>
                    </div>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px] gap-[10px]">
                    {/* <div className="bg-[#FFFFFF] rounded-[9px] border-[1px] border-[#A1A3EF] w-[80px] h-[70px] lg:w-[120px] lg:h-[120px] flex flex-col justify-center items-center gap-[5px] cursor-pointer"
                    style={{
                        border : "1px solid #A1A3EF"
                    }}
                    onClick={handleDivsClick}>
                        <img src={HomeIcon} alt="siren" />
                        <p className="text-[14px] lg:text-[18px]">Call Home</p>
                    </div> */}
                    <div className="bg-[#FFFFFF] rounded-[9px] border-[1px] border-[#A1A3EF] w-[130px] h-[120px] lg:w-[120px] lg:h-[120px] flex flex-col justify-center items-center gap-[5px] cursor-pointer"
                    style={{
                        border : "1px solid #A1A3EF"
                    }}
                    onClick={() => handleDivsClick('Call Driving School')} // Pass "Call School" as the value
                    >
                        <img src={SchoolBuildingIcon} alt="siren" className="w-[40px] h-[40px] lg:w-auto lg:h-[50px]" />
                        {/* <CallIcon  sx={{ color: "#A1A3EF",  fontSize: 35  }} /> */}
                        <p className="text-[14px] lg:text-[18px]">Call Institute</p>
                    </div>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <button className="rounded-[7px] w-[100px] lg:w-[150px] h-[31px] lg:h-[41px] text-[14px] lg:text-[18px] text-[#4C34AD]"
                    style={{
                        border : "1px solid #4C34AD"
                    }}
                    onClick={() => setOpenSOSPopup(false)}>
                        Cancel
                    </button>
                </div>

            </div>
        </div>
    )
}

export default SOSPopup;