import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../Actions/Base";
import { DeleteStaffA } from "../../../Actions/StaffA";

import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { InstructorRowTemplate } from "../../../Components/InstructorTemplate/InstructorTemplate";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../Components/NoDataResponse";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import TeacherFilter from "../Instructors/Components/TeacherFilter";
import axios from "axios";
import "./StaffList.css";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import { GetInstituteStaffA, SuspendTheSelectedUser, UnSuspendTheSelectedUser } from "../../../Actions/InstructorA";
import { GetInstituteInstructorsA } from "../../../Actions/InstructorA";
import dayjs from "dayjs";
import MemberSelection from "./MembersSelectionPopup"
import UpdateMemberSelectionPopup from "./UpdateMemberSelectionPopup";
import SuspendAndResumePopup from "./SuspendAndResumePopup";
import SuspendImage from "./Assets/SuspendUserImg.svg"
import ResumeImage from "./Assets/ResumeUserImg.svg"


const StaffListing = () => {
    const { Staffs, Instructors, loading } = useSelector(Store => Store.InstructorReducer);
    const [staffData, setStaffData] = useState([]);
    const [FilterOpen, setFilterOpen] = useState();
    const Navigate = useNavigate();
    const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
    const [sortByTime, setSortByTime] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [suspend, setSuspend] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [popupDetail, setPopupDetail] = useState({});
    const Dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpenMembersPopup, setIsOpenMembersPopup] = useState(false);
    const [isOpenUpdateMembersPopup, setIsOpenUpdateMembersPopup] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null); // For role filtering
    const [selectedMultipleUserType, setSelectedMultipleUserType] = useState(null); // For MultipleUserType filtering
    const [loadingSuspendFunction, setLoadingSuspendFunction] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isOpenSuspendAndResumePopup, setIsOpenSuspendAndResumePopup] = useState(false);
    const [popupData, setPopupData] = useState(null); // To hold the details

    const filterRef = useRef(null);
    const buttonRef = useRef(null); // Ref for the button

    const handleRedirect = (path) => {
        setIsDropdownOpen(false); // Close dropdown after selection
        window.location.href = path; // Redirect to the desired path
    };

    console.log("Staffs", Staffs)
    console.log("Instructors", Instructors)


    useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);
    // const OnClick = (e) => {
    //     e.stopPropagation();
    //     if (!FilterOpen) {
    //         setFilterOpen(true);
    //         setMaxHeight("max-h-[1200px]");
    //     } else if (FilterOpen) {
    //         setFilterOpen(false);
    //         setMaxHeight("max-h-[0px]");
    //     }
    // };

    // const fetchData = async () => {
    //     const params = new URLSearchParams({
    //         sortByTime,
    //         sortBy,
    //         searchQuery,
    //         suspend,
    //     });

    //     const url = `${BaseUrl}/api/Institute/Staffs?${params}`;

    //     try {
    //         setLoading(true)
    //         const res = await axios.get(url);
    //         setStaffData(res.data);
    //         setLoading(false)
    //     } catch (err) {
    //         console.log(err);
    //         setLoading(false)

    //     }
    // };

    // useEffect(() => {
    //     const filters = ({
    //         sortByTime,
    //         sortBy,
    //         searchQuery,
    //         suspend,
    //     });
    //     fetchData();
    // }, [sortByTime, sortBy, searchQuery, suspend]);

    // useEffect(() => {
    //     let isMounted = true;
    //     fetchData();
    //     return () => {
    //         isMounted = false;
    //     };
    // }, [sortByTime, sortBy, searchQuery, suspend]);

    const OnClick = (e) => {
        e.stopPropagation();
        if (FilterOpen) {
            setFilterOpen(false);
            setMaxHeight("max-h-[0px]");
        } else {
            setFilterOpen(true);
            setMaxHeight("max-h-[1200px]");
        }
    };

    useEffect(() => {
        Dispatch(
            GetInstituteStaffA({
            sortByTime,
            sortBy,
            searchQuery,
            suspend,
          })
        );

        // Dispatch(
        //     GetInstituteInstructorsA({
        //       sortByTime,
        //       sortBy,
        //       searchQuery,
        //       suspend,
        //     })
        //   );
      }, [Dispatch, sortByTime, sortBy, searchQuery, suspend]);

    useEffect(() => {
        Dispatch(
            GetInstituteInstructorsA({
                sortByTime,
                sortBy,
                searchQuery,
                suspend,
            })
        );
    }, [Dispatch, sortByTime, sortBy, searchQuery, suspend]);

    const handleApplyClick = (filters) => {
        // Get filter values from state variables
        console.log("in handleSortBy:", filters);
        setSearchQuery(filters?.searchQuery);
        setSuspend(filters.suspend);
        setSortBy(filters.sortBy);
        setSortByTime(filters.sortByTime);

        // Fetch data based on the applied filters
        // Dispatch(
        //     GetInstituteInstructorsA({
        //         sortByTime: filters.sortByTime,
        //         sortBy: filters.sortBy,
        //         searchQuery: filters.searchQuery,
        //         suspend: filters.suspend,
        //     })
        // );
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        setShowConfirmation(false); // Close the confirmation dialog

        // Dispatch the delete action with the InstructorId if needed
        Dispatch(DeleteStaffA(popupDetail?.StaffId))
            .then(() => {
                // Fetch the updated list of instructors after successful deletion
                // Dispatch(
                //     GetInstituteInstructorsA({
                //         sortByTime,
                //         sortBy,
                //         searchQuery,
                //         suspend,
                //     })
                // );
                GetInstituteStaffA();
            })
            .catch((error) => {
                console.error("Error deleting instructor:", error);
            });
    };

    const unifiedData = [
        ...(Staffs?.length > 0
            ? Staffs.map((staff) => ({
                  type: "Staff",
                  IdCardNo: staff?.Staff?.StaffIdCardNo,
                  name: `${staff.FirstName} ${staff.LastName}`,
                  email: staff.Email,
                  status: staff?.Staff?.Status,
                  Role: staff?.Staff?.Role,
                  phone: staff?.Staff?.PhoneNumber,
                  profileImage: staff?.Staff?.ProfileImage,
                  ImageUrl: `${BaseUrl}/api/images/Staff?url=${staff?.Staff?.ProfileImage}`,
                  EditLinkID: staff?.Staff?.StaffId,
                  SchoolAdmin: staff?.Staff?.SchoolAdmin,
                  MultipleUserType: staff?.InstituteUser?.MultipleUserType,
                  createdDate: staff?.createdAt ? new Date(staff.createdAt).toLocaleDateString() : null,
                  UserId: staff?.UserId,
              }))
            : []),
        ...(Instructors?.length > 0
            ? Instructors.map((instructor) => ({
                  type: "Instructor",
                  IdCardNo: instructor?.user?.Instructor?.InstructorIdCardNo,
                  name: `${instructor?.user?.FirstName} ${instructor?.user?.LastName}`,
                  email: instructor?.user?.Email,
                  status: instructor?.user?.Instructor?.Status,
                  Role: "Instructor",
                  phone: instructor?.user?.PhoneNumber,
                  profileImage: instructor?.user?.Instructor?.ProfileImage,
                  ImageUrl: `${BaseUrl}/api/images/Instructors?url=${instructor?.user?.Instructor?.ProfileImage}`,
                  EditLinkID: instructor?.user?.Instructor?.InstructorId,
                  SchoolAdmin: instructor?.user?.SchoolAdmin,
                  MultipleUserType: instructor?.user?.InstituteUser?.MultipleUserType,
                  createdDate: instructor?.user?.createdAt
                      ? new Date(instructor.user.createdAt).toLocaleDateString()
                      : null,
                  UserId: instructor?.user?.UserId,
              }))
            : []),
    ];

    console.log("unifiedData is:",unifiedData)

    // const unifiedData = [
    //     ...Staffs.map((staff) => ({
    //         type: "Staff",
    //         IdCardNo: staff?.Staff?.StaffIdCardNo,
    //         name: `${staff.FirstName} ${staff.LastName}`,
    //         email: staff.Email,
    //         status: !staff?.Staff?.Status ? "Free" : staff?.Staff?.Status,
    //         Role: staff?.Staff?.Role,
    //         phone: staff?.Staff?.PhoneNumber,
    //         profileImage: staff?.Staff?.ProfileImage,
    //         ImageUrl : `${BaseUrl}/api/images/Staff?url=${staff?.Staff?.ProfileImage}`,
    //         EditLinkID : staff?.Staff?.StaffId,
    //         SchoolAdmin: staff?.Staff?.SchoolAdmin,
    //         MultipleUserType: staff?.InstituteUser?.MultipleUserType,
    //         createdDate: staff?.createdAt ? new Date(staff.createdAt).toLocaleDateString() : null, // Format createdAt
    //         UserId: staff?.UserId,
    //     })),
    //     ...Instructors.map((instructor) => ({
    //         type: "Instructor",
    //         IdCardNo: instructor?.user?.Instructor?.InstructorIdCardNo,
    //         name: `${instructor?.user?.FirstName} ${instructor?.user?.LastName}`,
    //         email: instructor?.user?.Email,
    //         status: instructor?.user?.Instructor?.Status || "Free",
    //         Role: "Instructor",
    //         phone: instructor?.user?.PhoneNumber,
    //         profileImage: instructor?.user?.Instructor?.ProfileImage,
    //         ImageUrl : `${BaseUrl}/api/images/Instructors?url=${instructor?.user?.Instructor?.ProfileImage}`,
    //         EditLinkID: instructor?.user?.Instructor?.InstructorId,
    //         SchoolAdmin: instructor?.user?.SchoolAdmin,
    //         MultipleUserType: instructor?.user?.InstituteUser?.MultipleUserType,
    //         createdDate: instructor?.user?.createdAt ? new Date(instructor.user.createdAt).toLocaleDateString() : null, // Format createdAt
    //         UserId: instructor?.user?.UserId,
    //     })),
    // ];

    // Deduplicate and Sort by SchoolAdmin
    const processedData = [...new Map(
        unifiedData.map((item) => [item.IdCardNo, item]) // Deduplicate based on IdCardNo
    ).values()].sort((a, b) => {
        // Sort by SchoolAdmin (true first)
        if (a.SchoolAdmin && !b.SchoolAdmin) return -1;
        if (!a.SchoolAdmin && b.SchoolAdmin) return 1;
        return 0; // Keep original order for other records
    });

    console.log("processedData is:",processedData)

    // Filter by Role and MultipleUserType
    const filteredData = processedData.filter((value) => {
        const matchesRole = selectedRole ? value.Role === selectedRole : true;
        const matchesMultipleUserType = selectedRole
            ? value.MultipleUserType === selectedRole
            : true;

        const isSuspended = value.status === "suspend";  // Assuming status field indicates suspension

        return (matchesRole || matchesMultipleUserType) || isSuspended;

        // return matchesRole || matchesMultipleUserType; // Use OR logic to include either condition
    });

    console.log("filteredData is:",filteredData)


    // const filteredData = unifiedData.filter((value) => {
    //     const matchesRole = selectedRole ? value.Role === selectedRole : true;
    //     const matchesMultipleUserType = selectedRole
    //         ? value.MultipleUserType === selectedRole
    //         : true;
    //     return matchesRole || matchesMultipleUserType; // Use OR logic to include either condition
    // });

    const [activeRow, setActiveRow] = useState(null); // Track active row

    const toggleDropdown = (rowId) => {
        setActiveRow((prev) => (prev === rowId ? null : rowId)); // Toggle dropdown for this specific row
    };


    // const handleSuspend = (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) =>{
    //     Dispatch(SuspendTheSelectedUser(Role,UserUniqueId,MultipleUserRole, UserIdCardNo))
    // }

    const handleSuspend = (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) => {
        // Track the dispatch status (e.g., using a loading state)
        setLoadingSuspendFunction(true); // Setting loading to true when dispatch starts
        Dispatch(SuspendTheSelectedUser(Role, UserUniqueId, MultipleUserRole, UserIdCardNo))
            .then(() => {
                // After dispatch completes, refresh the page
                setLoadingSuspendFunction(false); // Set loading to false once done
                window.location.reload(); // Refresh the page
            })
            .catch(error => {
                console.error('Error during suspend:', error);
                setLoadingSuspendFunction(false); // Set loading to false even if there's an error
            });
    };

    const handleResume = (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) => {
        // Track the dispatch status (e.g., using a loading state)
        setLoadingSuspendFunction(true); // Setting loading to true when dispatch starts
        Dispatch(UnSuspendTheSelectedUser(Role, UserUniqueId, MultipleUserRole, UserIdCardNo))
            .then(() => {
                // After dispatch completes, refresh the page
                setLoadingSuspendFunction(false); // Set loading to false once done
                window.location.reload(); // Refresh the page
            })
            .catch(error => {
                console.error('Error during suspend:', error);
                setLoadingSuspendFunction(false); // Set loading to false even if there's an error
            });
    };


    // const handleResume = (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) =>{
    //     Dispatch(UnSuspendTheSelectedUser(Role,UserUniqueId,MultipleUserRole, UserIdCardNo))
    // }

    const handleManageRoles = (role, userUniqueIdNo, UserFK, MultipleUserType) => {
        setSelectedRowData({ role, userUniqueIdNo, UserFK, MultipleUserType }); // Store role and ID in the state
        setIsOpenUpdateMembersPopup(true); // Open the popup
        setActiveRow(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current &&
            !filterRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
            ) {
                setFilterOpen(false);
                setMaxHeight("max-h-[0px]");
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSuspendResumeClick = (userData) => {
        setPopupData(userData);
        setIsOpenSuspendAndResumePopup(true);
        setActiveRow(false);
    };

    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveRow(null); // Close the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="Staff_table shadow instructorDiv my-16 w-11/12  rounded-[35px] py-10">
            <div className="flex w-full flex-col items-center px-6 sm:flex-row sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
                <div className="Boldfont w-full text-center text-3xs  text-[#174A84] sm:w-1/5 sm:text-left md:w-1/4 lg:text-2xs xl:text-xs">
                    Team Members
                </div>
                <div className="md:w-3/4 flex w-full justify-between gap-2 sm:w-4/5 sm:justify-end sm:gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12">
                    {/* <Link
                        to="/Admin/staff/add"
                        className="InstructorPageBtns px-4 "
                    >
                        Add a Member
                    </Link> */}
                    <div className="relative">
                        <button
                                onClick={() => setIsOpenMembersPopup(!isOpenMembersPopup)}
                                className="InstructorAddMemberPageBtns relative px-[80px] duration-300 text-[#FFFFFF]"
                                style={{
                                        background: "transparent linear-gradient(89deg, #B05AC4 0%, #AFABFC 100%) 0% 0% no-repeat padding-box",
                                        color:"#FFFFFF"
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.background =
                                            "transparent linear-gradient(89deg, #AFABFC 0%, #B05AC4 100%) 0% 0% no-repeat padding-box";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.background =
                                            "transparent linear-gradient(89deg, #B05AC4 0%, #AFABFC 100%) 0% 0% no-repeat padding-box";
                                    }}
                            >
                                Add a Member
                        </button>
                        {isOpenMembersPopup && (
                            <MemberSelection
                                setIsOpenMembersPopup={setIsOpenMembersPopup}
                             />
                        )}
                        {isDropdownOpen && (
                                <div className="absolute right-0 mt-1 w-[240px] rounded-md bg-white shadow-lg z-10">
                                    <ul className="py-1">
                                        <div
                                            className="px-4 py-2 cursor-pointer hover:bg-[#A1A3EF]"
                                            onClick={() => handleRedirect("/Admin/staff/add")}
                                        >
                                            Staff
                                        </div>
                                        <div
                                            className="px-4 py-2 cursor-pointer hover:bg-[#A1A3EF]"
                                            onClick={() => handleRedirect("/Admin/staff/add")}
                                        >
                                            Supervisor
                                        </div>
                                        <div
                                            className="px-4 py-2 cursor-pointer hover:bg-[#A1A3EF]"
                                            onClick={() => handleRedirect("/Admin/staff/add")}
                                        >
                                            Administrator
                                        </div>
                                        <div
                                            className="px-4 py-2 cursor-pointer hover:bg-[#A1A3EF]"
                                            onClick={() => handleRedirect("/Instructor/add")}
                                        >
                                            Instructor
                                        </div>
                                    </ul>
                                </div>
                            )}
                    </div>
                    {isOpenUpdateMembersPopup && (
                                    <UpdateMemberSelectionPopup
                                    setIsOpenUpdateMembersPopup={setIsOpenUpdateMembersPopup}
                                    role={selectedRowData?.role}
                                    userUniqueIdNo={selectedRowData?.userUniqueIdNo}
                                    UserFK= {selectedRowData?.UserFK}
                                    MultipleUserType={selectedRowData?.MultipleUserType}
                                    />
                                )}
                    <span
                        className="InstructorPageBtns border-none outline-none shadow-none relative duration-300 text-[#FFFFFF]"
                        onClick={OnClick}
                        ref={buttonRef}
                        style={{
                            background: "transparent linear-gradient(89deg, #B05AC4 0%, #AFABFC 100%) 0% 0% no-repeat padding-box",
                            color:"#FFFFFF"
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.background =
                                "transparent linear-gradient(89deg, #AFABFC 0%, #B05AC4 100%) 0% 0% no-repeat padding-box";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.background =
                                "transparent linear-gradient(89deg, #B05AC4 0%, #AFABFC 100%) 0% 0% no-repeat padding-box";
                        }}
                        // onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
                        // onMouseLeave={() => setMaxHeight("max-h-[0px]")}
                    >
                        Filter Member
                        <div ref={filterRef}>
                        <TeacherFilter
                            handleApplyClick={handleApplyClick}
                            setSortBy={setSortBy}
                            setSortByTime={setSortByTime}
                            setSearchQuery={setSearchQuery}
                            setSuspend={setSuspend}
                            sortBy={sortBy}
                            sortByTime={sortByTime}
                            searchQuery={searchQuery}
                            suspend={suspend}
                            title={"Filter Team"}
                            MaxHeight={MaxHeight}
                            type="StaffList"
                            setSelectedRole={setSelectedRole}
                            selectedRole={selectedRole}
                            setSelectedMultipleUserType={setSelectedMultipleUserType}
                        />
                        </div>
                    </span>
                </div>
            </div>

            {isOpenSuspendAndResumePopup && (
                <SuspendAndResumePopup
                    setIsOpenSuspendAndResumePopup={setIsOpenSuspendAndResumePopup}
                    ResumeAndSuspendData={popupData}
                />
            )}

            {loading ? (
                <LoadingSpinner />
            ) : Staffs?.length === 0 && Instructors?.length === 0 ? (
                <NoDataResponse
                    topText="Sorry, there are no Staff to show!"
                    bottomText="Admin/Institute can add Staff by click on 'Add a New Staff' button."
                />
            ) : (
                <div className=" px-5 py-10 relativeTableData">
                    {showConfirmation && (
                        <DeletePopup
                            type={"Staff"}
                            onCancel={() => setShowConfirmation(false)}
                            onConfirm={handleDelete}
                            popupDetail={popupDetail}
                        />
                    )}
                    <table className="staff_table addCourse4Table instructorDiv ">
                        <thead>
                            <tr>
                                <th className="w-[2%] font-bold text-center">S.no</th>
                                <th className="w-[6%]  ">Profile</th>
                                {/* <th className="w-[10%] font-bold text-center">ID</th>  */}
                                <th className="w-[3%] font-bold ">Name</th>
                                <th className="w-[10%] font-bold text-center">Email</th>
                                <th className="w-[10%] font-bold text-center">Status</th>
                                <th className="w-[7%] font-bold text-center">Joining Date</th>
                                <th className="w-[10%] font-bold text-center">Role</th>
                                <th className="w-[7%] font-bold text-center">Phone</th>
                                <th className="w-[7%] font-bold text-center">
                                    Actions
                                </th>

                            </tr>
                        </thead>
                        <tbody className="relativeTableData">
                            {filteredData.map((value, index) => {
                                const StatusColor = value.status === "Free"
                                    ? "text-white bg-[#b5aaff]" :
                                    value.status === "suspend"
                                    ? "text-white bg-[#F33A3A]"
                                    : "text-white bg-[#7F82F9]";

                                    const isLastRow = (index, totalRows) => {
                                        return totalRows - index <= 2;
                                        };


                                // Define the Edit button with conditional navigation
                                const Edit = () => {
                                    let link = "#"; // Default link if no match
                                    if (value.type === "Staff") {
                                        link = `/Admin/staff/update/${value.EditLinkID}`;
                                    } else if (value.type === "Instructor" ) {
                                        link = `/instructor/update/${value.EditLinkID}`;
                                    }

                                    return (
                                        <Link onClick={(e) => e.stopPropagation()} to={link} >
                                            <button
                                                type="button"
                                                disabled={!!suspend} // Convert `suspend` to boolean
                                                className="absolute left-0 w-[80px] 2sm:w-auto cursor-pointer rounded md:rounded-[10px] border-0 bg-[#4C34AD] px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2"
                                            >
                                                Edit
                                            </button>
                                        </Link>
                                    );
                                };

                                const EditDropdown = () => {
                                    let link = "#"; // Default link if no match
                                    if (value.type === "Staff") {
                                        link = `/Admin/staff/update/${value.EditLinkID}`;
                                    } else if (value.type === "Instructor" ) {
                                        link = `/instructor/update/${value.EditLinkID}`;
                                    }

                                    return (
                                        <Link onClick={(e) => e.stopPropagation()} to={link} >
                                            <button
                                                type="button"
                                                className="text-[20px] text-[#174A84] hover:text-white"
                                            >
                                                Edit
                                            </button>
                                        </Link>
                                    );
                                };

                                const Ellipses = ({ rowId }) => {
                                    return (
                                        <button
                                            className="relative text-lg font-bold px-2 rounded cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent the row click event
                                                toggleDropdown(rowId); // Open/close dropdown for this specific row
                                            }}
                                        >
                                            &#x2026; {/* Horizontal Ellipsis */}
                                        </button>
                                    );
                                };

                                const DropdownMenu = () =>
                                    activeRow === value.IdCardNo && (
                                        <div
                                         ref={dropdownRef} // Attach ref to dropdown
                                        // className="absoluteTableData right-0 mt-2 w-40 bg-white shadow-lg rounded-[8px] z-999999"
                                        className={`absoluteTableData ${
                                            isLastRow(index, filteredData.length) ? "bottom-full" : "top-full"
                                            } right-0 mt-2 w-40 bg-white shadow-lg rounded-[8px] z-[9999]`}
                                            style={{
                                            transform: isLastRow(index, filteredData.length)
                                                ? "translateY(-10px)"
                                                : "translateY(10px)",
                                            }}>
                                            <div className="flex flex-col justify-start items-start py-[10px]">
                                                {/* Conditional rendering based on status */}
                                                {value.status === "suspend" ? (
                                                    <div
                                                        className="w-full py-2 pl-[20px] text-[20px] text-[#174A84] cursor-pointer hover:bg-gradient-to-r hover:from-[#B05AC4] hover:to-[#AFABFC] hover:text-white"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent the row click event
                                                            handleSuspendResumeClick({
                                                                firstName: value.name,
                                                                role: value.Role,
                                                                editLinkID: value.EditLinkID,
                                                                multipleUserType: value.MultipleUserType,
                                                                idCardNo: value.IdCardNo,
                                                                PopupType:"Resume",
                                                                Image: ResumeImage,
                                                                text: "."
                                                            });
                                                            // handleResume(value.Role, value.EditLinkID, value.MultipleUserType, value.IdCardNo); // Call resume function
                                                        }}
                                                    >
                                                        Resume
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="w-full py-2 pl-[20px] text-[20px] text-[#174A84] cursor-pointer hover:bg-gradient-to-r hover:from-[#B05AC4] hover:to-[#AFABFC] hover:text-white"
                                                            onClick={(e) => {
                                                            e.stopPropagation(); // Prevent the row click event
                                                            handleManageRoles(value.Role, value.EditLinkID, value.UserId, value.MultipleUserType); // Call resume function
                                                        }}
                                                        >
                                                            Manage Roles
                                                        </div>
                                                        <div
                                                            className="w-full py-2 pl-[20px] text-[20px] text-[#174A84] cursor-pointer hover:bg-gradient-to-r hover:from-[#B05AC4] hover:to-[#AFABFC] hover:text-white"
                                                        >
                                                            <EditDropdown />
                                                        </div>
                                                        <div
                                                            className="w-full py-2 pl-[20px] text-[20px] text-[#F33A3A] cursor-pointer hover:bg-gradient-to-r hover:from-[#B05AC4] hover:to-[#AFABFC] hover:text-white"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent the row click event
                                                                handleSuspendResumeClick({
                                                                    firstName: value.name,
                                                                    role: value.Role,
                                                                    editLinkID: value.EditLinkID,
                                                                    multipleUserType: value.MultipleUserType,
                                                                    idCardNo: value.IdCardNo,
                                                                    PopupType:"Suspend",
                                                                    Image: SuspendImage,
                                                                    text:"All the activities for this user will be stopped."
                                                                });
                                                                // handleSuspend(value.Role, value.EditLinkID, value.MultipleUserType, value.IdCardNo); // Call suspend function
                                                            }}
                                                        >
                                                            Suspend
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );


                                {/* const DropdownMenu = () =>
                                    activeRow === value.IdCardNo && (
                                        <div className="absoluteTableData right-0 mt-2 w-40 bg-white shadow-lg rounded-[8px] z-[999]">
                                            <div className="flex flex-col justify-start items-start py-[10px]">
                                                <div
                                                    className="py-2 pl-[20px] text-[20px] text-[#174A84] cursor-pointer"
                                                    // onClick={handleManageRoles}
                                                >
                                                    Manage Roles
                                                </div>
                                                <div
                                                    className="py-2 pl-[20px] text-[20px] text-[#174A84] cursor-pointer"
                                                    // onClick={handleEditUser}
                                                >
                                                    <EditDropdown />
                                                </div>
                                                <div
                                                    className="py-2 pl-[20px] text-[20px] text-[#F33A3A] cursor-pointer"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent the row click event
                                                        handleSuspend(value.Role, value.EditLinkID) // Open/close dropdown for this specific row
                                                    }}
                                                >
                                                    Suspend
                                                </div>
                                            </div>
                                        </div>
                                    ); */}

                             const OnRowClick = (e) => {
                                e.stopPropagation(); // Prevent event bubbling

                                let url = "#"; // Default to "#" if no match
                                if (value.type === "Staff") {
                                    url = `/Admin/staff/detail/${value.EditLinkID}?suspend=${suspend}`;
                                } else if (value.type === "Instructor") {
                                    url = `/about/instructor/${value.EditLinkID}`;
                                }

                                Navigate(url);
                            };

                                return (
                                    <InstructorRowTemplate
                                        key={value.IdCardNo}
                                        IdCardNo={value.IdCardNo}
                                        value={value}
                                        index={index}
                                        type={value.type}
                                        FirstName={value.name}
                                        Email={value.email}
                                        Status={value.status}
                                        Role={value.Role}
                                        phone={value.phone}
                                        ImgUrl={value.ImageUrl}
                                        suspend={suspend}
                                        StatusColor={StatusColor}
                                        TrStyle={"cursor-pointer"}
                                        OnClick={OnRowClick}
                                        // Delete={Delete}
                                        // LastComp={Edit}
                                        SchoolAdmin={value.SchoolAdmin}
                                        MultipleUserType={value.MultipleUserType}
                                        createdDate={value.createdDate}
                                        Ellipses={Ellipses}
                                        activeRow={activeRow}
                                        DropdownMenu={DropdownMenu}
                                        isLastRow={isLastRow}
                                    />
                                );
                            })}
                        </tbody>

                        {/* <tbody>
                            {Staffs?.map((value, index) => {
                                console.log("Staffs", Staffs)
                                {
                                    // console.log("value", value);
                                }
                                const { Status } = value?.Staff;
                                // const PaymentOrClientsColor =
                                //   !ClientsTraining || ClientsTraining === 0
                                //     ? "text-[green] bg-[#aaffbe]"
                                //     : "text-white bg-[#b5aaff]";
                                const StatusColor = !Status
                                    ? "text-[green] bg-[#aaffbe]"
                                    : "text-white bg-[#b5aaff]";

                                const Edit = () => (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`/Admin/staff/update/${value?.Staff?.StaffId}`}
                                    >
                                        <button
                                            type="button"
                                            disabled={suspend && true}
                                            // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2"
                                            className="absolute  left-0 w-[80px] 2sm:w-auto cursor-pointer  rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2 "
                                            >
                                            Edit
                                        </button>
                                    </Link>
                                );

                                const ImageUrl = `${BaseUrl}/api/images/Staff?url=${value?.Staff?.ProfileImage}`;
                                // const ImageUrl =null;

                                const OnRowClick = (e) => {
                                    Navigate(
                                        `/Admin/staff/detail/${value.Staff.StaffId}?suspend=${suspend}`
                                    );

                                    e.stopPropagation();
                                };

                                const Delete = () => {
                                    const handleDeleteClick = (e) => {
                                        e.stopPropagation();
                                        setPopupDetail({
                                            StaffId: value?.Staff?.StaffId,
                                            StaffName: `${value?.FirstName} ${value?.LastName}`,
                                            Profile: value?.Staff?.ProfileImage,
                                            Heading: "Delete Staff !!",
                                        });
                                        setShowConfirmation(true);
                                    };

                                    return (
                                        <>
                                            <button
                                                onClick={handleDeleteClick}
                                                type="button"
                                                disabled={suspend && true}
                                                // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2 lg:py-2"
                                                className="absolute  left-0 w-[80px] 2sm:w-auto  cursor-pointer rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-6 lg:py-2 "
                                                >
                                                Delete
                                            </button>
                                        </>
                                    );
                                };

                                return (
                                    <InstructorRowTemplate
                                        key={value?.Staff?.StaffId}
                                        value={value}
                                        index={index}
                                        FirstName={value.FirstName}
                                        Email={value.Email}
                                        LastName={value.LastName}
                                        Status={!Status ? "free" : Status}
                                        createdAt={value?.Staff?.createdAt}
                                        // ClientsTraining={value.Instructor.ClientsTraining}
                                        // PaymentOrUnderClients={
                                        //   !StudentsTraining ? "free" : StudentsTraining
                                        // }
                                        suspend={suspend}
                                        ImgUrl={ImageUrl}
                                        // PaymentOrClientsColor={PaymentOrClientsColor}
                                        TrStyle={"cursor-pointer"}
                                        StatusColor={StatusColor}
                                        LastComp={Edit}
                                        OnClick={OnRowClick}
                                        Role={value?.Staff?.Role}
                                        type={"Staff"}
                                        phone={value?.Staff?.PhoneNumber}
                                        Delete={Delete}
                                        const
                                        StaffIdCardNo={
                                            value?.Staff?.StaffIdCardNo
                                        }
                                    />
                                );
                            })}

                        </tbody> */}
                    </table>
                </div>
            )}
        </div>
    );
};

const StaffList = () => (
    <InstituteTemplate
        Element={StaffListing}
        bg="bg-[#F7F7F7] min-h-screen items-center"
    />
);

export default StaffList;
