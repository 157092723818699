import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerF,
  Polyline,
  PolylineF,
} from "@react-google-maps/api";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useMap } from "../../../Components/InVehicleClass/GoogleMap/mapHook";
import { getCompletedLocation } from "../api";

const AdminGoogleMap = (props) => {
  const {
    onLoadMap,
    currentPosition,
    currentRoute,
    locationKey,
    modal: { open, onClose },
  } = props;
  const mapObj = useMap();
  const [savedCurrentPosition, setSavedCurrentPosition] = useState();
  const [savedCurrentRoute, setSavedCurrentRoute] = useState([]);

  useEffect(() => {
    if (currentPosition && currentRoute) {
      setSavedCurrentPosition(currentPosition);
      setSavedCurrentRoute(currentRoute);
    } else {
      getSavedLocation();
    }
  }, [currentPosition]);

  const getSavedLocation = async () => {
    getCompletedLocation(locationKey)
      .then((res) => {
        if (res.data?.liveLocation.length > 0) {
          const updateRoute = (index) => {
            if (index < res.data?.liveLocation.length) {
              setSavedCurrentRoute((oldRoute) => [
                ...oldRoute,
                res.data?.liveLocation[index],
              ]);
              setSavedCurrentPosition(res.data?.liveLocation[index]);
              setTimeout(() => {
                updateRoute(index + 1);
              }, 10000);
            }
          };

          updateRoute(0);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Dialog maxWidth="lg" open={open} id="admin-google-map">
      <DialogTitle className="relative">
        <Typography sx={{ fontWeight: 700 }} className="w-full">
          Admin Map
        </Typography>
        <Box className="absolute top-0 right-0">
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className="w-[900px]"></DialogContentText>
        {mapObj.isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: 650 }}
            center={savedCurrentPosition}
            onLoad={onLoadMap}
            zoom={12}
          >
            {savedCurrentRoute.length > 0 && (
              <PolylineF
                path={savedCurrentRoute}
                options={{ strokeColor: "#36454F" }}
              />
            )}

            {savedCurrentPosition && (
              <MarkerF position={savedCurrentPosition} />
            )}
          </GoogleMap>
        ) : (
          <p>Map Loading...</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdminGoogleMap;
