import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const AddInstructorA =
    (InstructorData, setSuccess) => async (dispatch) => {
        try {
            dispatch({
                type: "CreateInstructorRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/Instructor/add`,
                InstructorData
            );

            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            // if (data) {
            setSuccess(true);
            // }
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            console.log("error?.response?.data is:",error?.response?.data)
            const errorMessage = error?.response?.data || 'Something went wrong';

            dispatch({
                type: "CreateInstructorFailure",
                payload: errorMessage,
            });

            // Pass the error message to the caller
            return Promise.reject(errorMessage);
        }
    };

export const AddInstructorWithMultipleRoleA =
    (InstructorData, setSuccess, roles) => async (dispatch) => {
        console.log("roles at frontend is in action:",roles)
        try {
            dispatch({
                type: "CreateInstructorRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/Instructor/mulipleroleadd/${roles}`,
                InstructorData
            );

            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            // if (data) {
            setSuccess(true);
            // }
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "CreateInstructorFailure",
                payload: error,
            });
        }
    };

// export const UpdateRoleToInstructor =
//     (InstructorData,setSuccess,StaffIdCardNumber,UserFK,userStaffIdNo) => async (dispatch) => {
//         console.log("userStaffIdNo at frontend is in action:",StaffIdCardNumber)
//         console.log("InstructorData at frontend is in action:",InstructorData)

//         try {
//             dispatch({
//                 type: "CreateInstructorRequest",
//             });
//             const { data } = await axiosInstance.post(
//                 `/api/Instructor/updateroletoinstructor`,
//                 InstructorData, // Send FormData directly
//                 {
//                     InstructorData,
//                     StaffIdCardNumber,
//                     UserFK,
//                     userStaffIdNo
//                 }
//             );

//             dispatch({
//                 type: "CreateInstructorSuccess",
//                 payload: data,
//             });
//             // if (data) {
//             setSuccess(true);
//             // }
//         } catch (error) {
//             CheckLoginServer(error?.response?.data, dispatch);
//             dispatch({
//                 type: "CreateInstructorFailure",
//                 payload: error,
//             });
//         }
//     };

// export const UpdateRoleToInstructor = 
//     (InstructorData, setSuccess, StaffIdCardNumber, UserFK, userStaffIdNo) => async (dispatch) => {
//         try {
//             console.log("StaffIdCardNumber at frontend:", StaffIdCardNumber);
//             console.log("InstructorData at frontend:", InstructorData);

//             for (let pair of InstructorData.entries()) {
//                 console.log(pair[0] + ": " + pair[1]);
//             }

//             // Dispatch initial request action
//             dispatch({
//                 type: "UpdateInstructorRequest",
//             });

//             // Append additional fields to FormData
//             InstructorData.append("StaffIdCardNumber", StaffIdCardNumber);
//             InstructorData.append("UserFK", UserFK);
//             InstructorData.append("userStaffIdNo", userStaffIdNo);

//             // Make the API call
//             const { data } = await axiosInstance.post(
//                 `/api/Instructor/updateroletoinstructor`, // API endpoint
//                 InstructorData, // FormData as body
//                 {
//                     headers: {
//                         "Content-Type": "multipart/form-data", // Ensure correct headers
//                     },
//                 }
//             );

//             // Dispatch success action with response payload
//             dispatch({
//                 type: "UpdateInstructorSuccess",
//                 payload: data,
//             });

//             // Update success state
//             setSuccess(true);
//         } catch (error) {
//             // Handle errors
//             CheckLoginServer(error?.response?.data, dispatch);
//             dispatch({
//                 type: "UpdateInstructorFailure",
//                 payload: error,
//             });
//         }
//     };

export const UpdateRoleToInstructor =
    (InstructorData, setSuccess, StaffIdCardNumber, UserFK, userStaffIdNo) => async (dispatch) => {
        console.log("StaffIdCardNumber at frontend is in action:",StaffIdCardNumber)
        console.log("UserFK at frontend is in action:",UserFK)
        console.log("userStaffIdNo at frontend is in action:",userStaffIdNo)

        try {
            dispatch({
                type: "CreateInstructorRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/Instructor/updateroletoinstructor/${StaffIdCardNumber}/${UserFK}/${userStaffIdNo}`,
                InstructorData
            );

            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            // if (data) {
            setSuccess(true);
            // }
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "CreateInstructorFailure",
                payload: error,
            });
        }
    };

export const UpdateUserRoletoInstituteStaff =
    (userUniqueIdNo,UserFK,filteredRoles) => async (dispatch) => {
        console.log("userUniqueIdNo at frontend is in action:",userUniqueIdNo)
        console.log("UserFK at frontend is in action:",UserFK)
        console.log("filteredRoles at frontend is in action:",filteredRoles)

        try {
            dispatch({
                type: "UpdateInstituteUserRoleToStaffMembersRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/Instructor/updateroletoinstituteStaff`,
                {
                    userUniqueIdNo,
                    UserFK,
                    filteredRoles
                }
            );

            dispatch({
                type: "UpdateInstituteUserRoleToStaffMembersSuccess",
                payload: data,
            });
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "UpdateInstituteUserRoleToStaffMembersFailure",
                payload: error,
            });
        }
    };


export const GetStaffDataforInstructor =
    (userStaffIdNo, UserFK) => async (dispatch) => {
        console.log("userStaffIdNo at frontend is in action:",userStaffIdNo)
        console.log("UserFK at frontend is in action:",UserFK)

        try {
            dispatch({
                type: "UpdateToInstructorRequest",
            });
            // Pass data as query parameters in the GET request
            const { data } = await axiosInstance.get(
                `/api/Instructor/getstaffdataforinstructor`, 
                {
                    params: {
                        userStaffIdNo,
                        UserFK,
                    },
                }
            );

            dispatch({
                type: "UpdateToInstructorSuccess",
                payload: data,
            });
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "UpdateToInstructorFailure",
                payload: error,
            });
        }
    };

export const UpdateInstructorA =
    (InstructorData, setSuccess) => async (dispatch) => {
        console.log("InstructorData is in action:",InstructorData)

         // Iterate through FormData to log its contents
         if (InstructorData instanceof FormData) {
            for (const [key, value] of InstructorData.entries()) {
                console.log(`form data in the acton is ${key}:`, value);
            }
        } else {
            console.log("InstructorData is not FormData:", InstructorData);
        }

        try {
            dispatch({
                type: "CreateInstructorRequest",
            });

            const { data } = await axiosInstance.put(
                `/api/Instructor/update`,
                InstructorData
            );
            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            setSuccess(true);
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "CreateInstructorFailure",
                payload: error,
            });
        }
    };

export const GetInstructorsA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorsRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Instructors/${EnrollmentId}`
        );

        dispatch({
            type: "GetInstructorsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorsFailure",
            payload: error,
        });
    }
};

export const DeleteInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "DeleteInstructorRequest",
        });

        const { data } = await axiosInstance.delete(
            `/api/Instructor/delete/${InstructorId}`
        );

        dispatch({
            type: "DeleteInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "DeleteInstructorFailure",
            payload: error,
        });
    }
};
export const GetInstituteInstructorsA = (filters) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorsRequest",
        });
        console.log("filters in instructor action are", filters);
        const { sortByTime, sortBy, searchQuery, suspend } = filters;
        const queryParams = new URLSearchParams({
            sortByTime: sortByTime,
            sortBy: sortBy,
            searchQuery: searchQuery,
            suspend: suspend,
        }).toString();
        // console.log("queryparams", queryParams);
        const { data } = await axiosInstance.get(
            `/api/Institute/Instructors?${queryParams}`
        );
        // console.log("instructorFilteredData", data);
        // const { data } = await axiosInstance.get(`/api/Institute/Instructors`);

        dispatch({
            type: "GetInstructorsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorsFailure",
            payload: error,
        });
    }
};

export const GetInstituteStaffA = (filters) => async (dispatch) => {
    try {
        dispatch({
            type: "GetStaffRequest",
        });
        // console.log("filters", filters);
        const { sortByTime, sortBy, searchQuery, suspend } = filters;
        const queryParams = new URLSearchParams({
            sortByTime: sortByTime,
            sortBy: sortBy,
            searchQuery: searchQuery,
            suspend: suspend,
        }).toString();
        // console.log("queryparams", queryParams);
        const { data } = await axiosInstance.get(
            `/api/Institute/Staffs?${queryParams}`
        );
        // console.log("instructorFilteredData", data);
        // const { data } = await axiosInstance.get(`/api/Institute/Instructors`);

        dispatch({
            type: "GetStaffSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStaffFailure",
            payload: error,
        });
    }
};

export const GetInstructorStudents = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorStudentsRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/teacher/${InstructorId}`
        );
        console.log("data", data);

        dispatch({
            type: "GetInstructorStudentsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorStudentsFailure",
            payload: error,
        });
    }
};

export const GetSInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetSInstructorRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/SInstructor/${InstructorId}`
        );

        dispatch({
            type: "GetSInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetSInstructorFailure",
            payload: error,
        });
    }
};

//Get Related to course Instructors
export const GetCourseInstructorA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetCourseInstructorRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Course/Instructors/${EnrollmentId}`
        );

        dispatch({
            type: "GetCourseInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetCourseInstructorFailure",
            payload: error,
        });
    }
};

// courses of instructors
export const GetCoursesOfSInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetCoursesOfSInstructorRequest",
        });

        console.log("instructor id in action is:",InstructorId)

        const { data } = await axiosInstance.get(
            `/api/instructor/courses/${InstructorId}`
        );

        console.log("coursesdata", data);
        dispatch({
            type: "GetCoursesOfSInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetCoursesOfSInstructorFailure",
            payload: error,
        });
    }
};

// courses instructor and student address
export const GetInstructorAndStudentAddressA = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorAndStudentAddressRequest",
        });

        console.log("EventId in action is:",EventId)

        const { data } = await axiosInstance.get(
            `/api/instructor/address/${EventId}`
        );

        console.log("address student and instructor", data);
        dispatch({
            type: "GetInstructorAndStudentAddressSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorAndStudentAddressFailure",
            payload: error,
        });
    }
};

// UpdatePickedStudentField
export const UpdatePickedStudentUserFieldA = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdatePickedStudentUserFieldRequest",
        });

        console.log("EventId in action is:",EventId)

        const { data } = await axiosInstance.put(
            `/api/instructor/updatepickedstudentfield/${EventId}`
        );

        dispatch({
            type: "UpdatePickedStudentUserFieldSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdatePickedStudentUserFieldFailure",
            payload: error,
        });
    }
};

// Update start recording backend field
export const UpdateStartRecordingUserFieldA = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdatePickedStudentUserFieldRequest",
        });

        console.log("EventId in UpdateStartRecordingUserFieldA is:",EventId)

        const { data } = await axiosInstance.put(
            `/api/instructor/updatestartrecordingfield/${EventId}`
        );

        dispatch({
            type: "UpdatePickedStudentUserFieldSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdatePickedStudentUserFieldFailure",
            payload: error,
        });
    }
};

// Update start recording and picked student to false backend field
export const UpdateStartRecordingAndPickedStudentUserFieldA = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdatePickedStudentUserFieldRequest",
        });

        console.log("EventId in UpdateStartRecordingUserFieldA is:",EventId)

        const { data } = await axiosInstance.put(
            `/api/instructor/updatestartrecordingandpickedtudentfield/${EventId}`
        );

        dispatch({
            type: "UpdatePickedStudentUserFieldSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdatePickedStudentUserFieldFailure",
            payload: error,
        });
    }
};


// Update Instructor Live Location Latitude and Longitude
export const UpdateLiveLocationInstructorCoordinates = (EventId, InstructorUserId, latitude, longitude) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdateInstructorLiveLocationCoordinatesRequest",
        });

        console.log("EventId in UpdateInstructorLiveLocationCoordinatesRequest action is:",EventId)

        const { data } = await axiosInstance.post(
            `/api/update-livelocation`,
            {
                EventId,
                InstructorUserId,
                latitude,
                longitude
            }
        );

        dispatch({
            type: "UpdateInstructorLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateInstructorLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// Get Instructor Live Location Latitude and Longitude
export const GetLiveLocationInstructorCoordinates = (UserId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorLiveLocationCoordinatesRequest",
        });

        console.log("UserId in GetLiveLocationInstructorCoordinates action is:",UserId)

        const { data } = await axiosInstance.post(
            `/api/get-livelocation`,
            {
                UserId,
            }
        );

        dispatch({
            type: "GetInstructorLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// Get Instructor Name, profile and Speciality Details
export const GetInstructorNameAndOtherDetails = (UserId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorNameAndOtherDetailsRequest",
        });

        console.log("UserId in GetInstructorNameAndOtherDetails action is:",UserId)

        const { data } = await axiosInstance.post(
            `/api/getinstructornameandotherdetails`,
            {
                UserId,
            }
        );

        dispatch({
            type: "GetInstructorNameAndOtherDetailsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorNameAndOtherDetailsFailure",
            payload: error,
        });
    }
};

// Get Class Locations timings
export const GetClassLocationsTimings = (UserId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetClassLocationsDetailsRequest",
        });

        console.log("UserId in GetClassLocationsTimings action is:",UserId)

        const { data } = await axiosInstance.post(
            `/api/getclasslocationstimings`,
            {
                UserId,
            }
        );

        dispatch({
            type: "GetClassLocationsDetailsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetClassLocationsDetailsFailure",
            payload: error,
        });
    }
};

// Get Student Live Location Latitude and Longitude
export const GetLiveLocationStudentCoordinates = (UserId) => async (dispatch) => {
    try {
        console.log("UserId in GetLiveLocationStudentCoordinates is:",UserId)
        dispatch({
            type: "GetInstructorLiveLocationCoordinatesRequest",
        });

        console.log("UserId in GetLiveLocationStudentCoordinates action is:",UserId)

        const { data } = await axiosInstance.post(
            `/api/get-student-livelocation`,
            {
                UserId,
            }
        );

        dispatch({
            type: "GetInstructorLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// Save Student Live Location Coordinates
export const saveStudentLiveLocationCoordinates = ( UserId, latitude, longitude) => async (dispatch) => {
    try {
        dispatch({
            type: "SaveStudentLiveLocationCoordinatesRequest",
        });

        console.log("UserId in saveStudentLiveLocationCoordinates action is:",UserId)

        const { data } = await axiosInstance.post(
            `/api/save-student-location`,
            {
                UserId,
                latitude,
                longitude,
            }
        );

        dispatch({
            type: "SaveStudentLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SaveStudentLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// live  location link to user mobile number, email, whatsapp
export const SendLiveLocationLink = (link,EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "SendLiveLocationLinkRequest",
        });

        const { data } = await axiosInstance.post(`/api/sendlivelocationlink/${EventId}`, {
            link,
        });

        dispatch({
            type: "SendLiveLocationLinkSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SendLiveLocationLinkFailure",
            payload: error,
        });
    }
};



// bypass otp feedback update
export const GetBypassOtpFeedBackUpdate = (EventId, feedbackData) => async (dispatch) => {
    try {
        dispatch({
            type: "GetBypassOtpFeedBackUpdateRequest",
        });

        console.log("EventId in GetBypassOtpFeedBackUpdate action is:",EventId)
        console.log("Feedback Data:", feedbackData);

        const { data } = await axiosInstance.post(
            `/api/bypassotpfeedback/${EventId}`,
            feedbackData
        );

        dispatch({
            type: "GetBypassOtpFeedBackUpdateSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "GetBypassOtpFeedBackUpdateFailure",
            payload: error,
        });
    }
};

// update student that instructor has left the location
export const UpdateStudentAboutInstructorLocation = (StudentEmail, StudentFirstName, StudentLastName) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorLeftLocationUpdateRequest",
        });

        console.log("StudentEmail in UpdateStudentAboutInstructorLocation action is:",StudentEmail)
        console.log("StudentFirstName in UpdateStudentAboutInstructorLocation action is:",StudentFirstName)
        console.log("StudentLastName in UpdateStudentAboutInstructorLocation action is:",StudentLastName)

        const { data } = await axiosInstance.get(
            `/api/updateinstructorleftlocation/${StudentEmail}`,
            {
                params: {
                    StudentFirstName: StudentFirstName,
                    StudentLastName: StudentLastName,
                },
            }
        );

        dispatch({
            type: "GetInstructorLeftLocationUpdateSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "GetInstructorLeftLocationUpdateFailure",
            payload: error,
        });
    }
};

// update student that instructor has left the location
export const UpdateLocationButtonsTime = (EventId,ActionName, currentTime) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdateLocationsTimeRequest",
        });

        console.log("EventId in UpdateLocationButtonsTime action is:",EventId)
        console.log("ActionName in UpdateLocationButtonsTime action is:",ActionName)
        console.log("currentTime in UpdateLocationButtonsTime action is:",currentTime)

        const { data } = await axiosInstance.post(
            `/api/updateinstructorlocationstime/${EventId}`,
            {
                ActionName,
                currentTime
            }
        );

        dispatch({
            type: "UpdateLocationsTimeSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "UpdateLocationsTimeFailure",
            payload: error,
        });
    }
};

// screen recording upload
export const UploadScreenRecordingToS3 = (EventId, formData) => async (dispatch) => {
    try {
        dispatch({
            type: "UploadScreenRecordingToS3Request",
        });

        const { data } = await axiosInstance.post(`/api/upload/recording/rtc/${EventId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data", // Ensure correct content type
            },
        });

        dispatch({
            type: "UploadScreenRecordingToS3Success",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UploadScreenRecordingToS3Failure",
            payload: error,
        });
    }
};

// user live location updation
export const UpdateLiveLocationCoordinates = ( EventId, latitude, longitude ) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdateLiveLocationCoordinatesRequest",
        });

        const { data } = await axiosInstance.post(`/api/update-livelocation`, { 
            EventId, 
            latitude, 
            longitude });

        dispatch({
            type: "UpdateLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// get user live location 
export const GetLiveLocationCoordinates = ( EventId ) => async (dispatch) => {
    try {
        dispatch({
            type: "GetLiveLocationCoordinatesRequest",
        });

        const { data } = await axiosInstance.post(`/api/getUserLivelocation/${EventId}`);

        dispatch({
            type: "GetLiveLocationCoordinatesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetLiveLocationCoordinatesFailure",
            payload: error,
        });
    }
};

// live  location link to user mobile number, email, whatsapp
export const ContactSchoolEmergencyContact = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "MakeACallToSchoolContactRequest",
        });

        const { data } = await axiosInstance.get(`/api/makecalltoschoolnumber/${EventId}`);

        dispatch({
            type: "MakeACallToSchoolContactSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "MakeACallToSchoolContactFailure",
            payload: error,
        });
    }
};

// SOS Call Driving School from Student
export const GetDrivingInstituteNumberForStudent = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "MakeACallToSchoolContactRequest",
        });

        const { data } = await axiosInstance.get(`/api/getinstitutenumberforstudent/${EnrollmentId}`);

        dispatch({
            type: "MakeACallToSchoolContactSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "MakeACallToSchoolContactFailure",
            payload: error,
        });
    }
};

// SOS Call Driving School from Student
export const SendHarassmentEmailAndSMSForStudent = (liveLocationLink, EnrollmentId, UserId) => async (dispatch) => {
    try {
        console.log("liveLocationLink in SendHarassmentEmailAndSMSForStudent is:",liveLocationLink)
        console.log("EnrollmentId in SendHarassmentEmailAndSMSForStudent is:",EnrollmentId)
        console.log("UserId in SendHarassmentEmailAndSMSForStudent is:",UserId)

        dispatch({
            type: "SendHarassmentStudentEmergencyAlertRequest",
        });

        const { data } = await axiosInstance.post(`/api/sendharassmentstudentreport/${EnrollmentId}`,
        {
            liveLocationLink,
            UserId
        });

        dispatch({
            type: "SendHarassmentStudentEmergencyAlertSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SendHarassmentStudentEmergencyAlertFailure",
            payload: error,
        });
    }
};

// SOS Call Driving School from Student
export const GetBeaconAndInstituteEmergencyContacts = (EnrollmentId,UserId) => async (dispatch) => {
    try {
        console.log("EnrollmentId in SendHarassmentEmailAndSMSForStudent is:",EnrollmentId)

        dispatch({
            type: "GetBeaconAndInstituteEmergencyContactRequest",
        });

        const { data } = await axiosInstance.post(`/api/getbeaconandinstitutecontacts/${EnrollmentId}`,
        {
            UserId
        }
        );

        dispatch({
            type: "GetBeaconAndInstituteEmergencyContactSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetBeaconAndInstituteEmergencyContactFailure",
            payload: error,
        });
    }
};

// SOS send location link to beacon
export const SendLocationToBeacon = (liveLocationLink, UserId) => async (dispatch) => {
    try {
        console.log("liveLocationLink in SendHarassmentEmailAndSMSForStudent is:",liveLocationLink)
        console.log("UserId in SendHarassmentEmailAndSMSForStudent is:",UserId)

        dispatch({
            type: "SendHarassmentStudentEmergencyAlertRequest",
        });

        const { data } = await axiosInstance.post(`/api/sendlocationlinktobeacon`,
        {
            liveLocationLink,
            UserId
        });

        dispatch({
            type: "SendHarassmentStudentEmergencyAlertSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SendHarassmentStudentEmergencyAlertFailure",
            payload: error,
        });
    }
};


// get institute user and multiple user type
export const GetInstituteUserTypeData =
    (UserId) => async (dispatch) => {
        console.log("UserId at frontend is in action:",UserId)
        try {
            dispatch({
                type: "GetInstituteUserTypeRequest",
            });
            const { data } = await axiosInstance.get(
                `/api/Institute/getusertypeandrole/${UserId}`,
            );

            dispatch({
                type: "GetInstituteUserTypeSuccess",
                payload: data,
            });
            
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "GetInstituteUserTypeFailure",
                payload: error,
            });
        }
    };

// get institute user and multiple user type
export const ChangeInstituteUserTypeData =
    (UserId, multipleUserType, instituteUserRole) => async (dispatch) => {
        console.log("UserId at frontend is in action:",UserId)
        console.log("multipleUserType at frontend is in action:",multipleUserType)
        console.log("instituteUserRole at frontend is in action:",instituteUserRole)
        try {
            dispatch({
                type: "ChangeInstituteUserTypeRequest",
            });
            const { data } = await axiosInstance.put(
                `/api/Institute/changeusertypeandrole/${UserId}`,
                {
                    multipleUserType,
                    instituteUserRole,
                  }
            );

            dispatch({
                type: "ChangeInstituteUserTypeSuccess",
                payload: data,
            });
            
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "ChangeInstituteUserTypeFailure",
                payload: error,
            });
        }
    };

// suspend the institute user
export const SuspendTheSelectedUser =
    (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) => async (dispatch) => {
        console.log("Role at frontend is in action:",Role)
        console.log("UserUniqueId at frontend is in action:",UserUniqueId)
        try {
            dispatch({
                type: "SuspendInstituteUserRequest",
            });
            const { data } = await axiosInstance.put(
                `/api/Institute/suspendtheuser`,
                {
                    Role,
                    UserUniqueId,
                    MultipleUserRole,
                    UserIdCardNo
                  }
            );

            dispatch({
                type: "SuspendInstituteUserSuccess",
                payload: data,
            });
            
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "SuspendInstituteUserFailure",
                payload: error,
            });
        }
    };

// suspend the institute user
export const UnSuspendTheSelectedUser =
    (Role, UserUniqueId, MultipleUserRole, UserIdCardNo) => async (dispatch) => {
        console.log("Role at frontend is in action:",Role)
        console.log("UserUniqueId at frontend is in action:",UserUniqueId)
        console.log("MultipleUserRole at frontend is in action:",MultipleUserRole)
        console.log("UserIdCardNo at frontend is in action:",UserIdCardNo)
        try {
            dispatch({
                type: "SuspendInstituteUserRequest",
            });
            const { data } = await axiosInstance.put(
                `/api/Institute/unsuspendtheuser`,
                {
                    Role,
                    UserUniqueId,
                    MultipleUserRole,
                    UserIdCardNo
                  }
            );

            dispatch({
                type: "SuspendInstituteUserSuccess",
                payload: data,
            });
            
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "SuspendInstituteUserFailure",
                payload: error,
            });
        }
    };
