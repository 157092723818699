import React, {useState, useEffect} from "react";

const SettingsPopup = ({setShowPopup, text }) => {
   
  const closePopup = () =>{
      console.log("Close icon clicked");
      setShowPopup(false);
  }
    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative rounded-[23px] bg-white p-[20px] shadow-lg">
          <div className="absolute top-2 right-2">
            <img
              className="absolute right-3 top-[6px] cursor-pointer"
              src={require("../Assets/Cross.svg").default}
              alt=""
              onClick={closePopup}
            />
          </div>
          <h2
            // style={{ WebkitTextStroke: "1px #404040" }}
            className=" mb-4 text-center text-[16px]"
          >
            Congratulations !!
          </h2>
          <p className="text-center text-[14px] w-[250px]">{text}</p>
          <div className="w-full flex justify-center items-center mt-[15px]">
            <button
                className="w-[60px] h-[30px] rounded-full bg-[#A1A3EF] text-[#FFFFFF] text-[14px] cursor-pointer"
                onClick={closePopup}>
                Done
            </button>
          </div>
        </div>
      </div>
    );
  };

export default SettingsPopup;
