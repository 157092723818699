import React, { useState, useEffect } from "react";
import Graphs from "./dashboard 0.1/Graphs";
import RecentCourses from "./Components/RecentCourses/RecentCourses";
import TopCards from "./Components/TopCards/TopCards";
import Popups from "./Components/Popups/Popups";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import DashBoardAdminCourses from "../Admin/AdminCourses";
import "./InsStaffAdminDashboard.css";
import { Link } from "react-router-dom";
import RecentCoursesMainPage from "./Components/RecentCourses/RecentCoursesMainPage";
import ManageLogin from "./dashboard 0.2/GeographyGraph";
import { useDispatch, useSelector } from "react-redux";
import { getInstituteStudentSchedule } from "../../../Actions/ScheduleA";

function InsStaffAdminDashboard() {
  const [Edit, setEdit] = useState();
  const [InstituteCourseId, setInstituteCourseId] = useState();
  const Dispatch = useDispatch();
  const { schedule } = useSelector(
    (Store) => Store?.ScheduleReducer
  );

  console.log("schedule is:",schedule)

  //local storage values
  const staff = GetLocalStorage("UserInfo").InstituteUserType === "Staff";
  const InstituteAdmin =
    GetLocalStorage("UserInfo").InstituteUserType === "Admin";
  let AddCourse = `/addcourse`;
  let AdminCourse = `/admin/course/add`;
  let CourseRoute =
    GetLocalStorage("UserInfo").User === "Admin"
      ? AdminCourse
      : GetLocalStorage("UserInfo").User === "Institute"
      ? AddCourse
      : null;

  const InstituteId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;
  console.log("InstituteId",InstituteId)

  useEffect(() => {
     Dispatch(getInstituteStudentSchedule(InstituteId)); 
  }, []);

  return (
    <div className="InsDashboardContainer">
      {GetLocalStorage("UserInfo").InstituteUserType === "Admin" && Edit ? (
        <Popups
          setEdit={setEdit}
          Edit={Edit}
          InstituteCourseId={InstituteCourseId}
        />
      ) : null}

      <TopCards  data={schedule} />
      <div className="my-3 w-full ">
        {/* <Graphs /> */}

        {InstituteAdmin || staff ? (
          <div className="CoursesGraphContainer">
            <div className="w-full max-w-[1300px] 3xl:w-4/5">
              {/* <RecentCourses
                                setEdit={setEdit}
                                setInstituteCourseId={setInstituteCourseId}
                            /> */}
              <RecentCoursesMainPage
                setEdit={setEdit}
                setInstituteCourseId={setInstituteCourseId}
              />
            </div>
            <div className="bilal flex flex-col items-center">
              <div className="create_course_card mt-[3.7rem] w-[332px] min-w-[320px]  rounded-[15px] border-[1px]   border-solid border-[#D4D4D4] bg-white px-[26px] py-[20px] shadow drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center md:mt-[3.6rem] md:w-[380px] md:max-w-[400px]">
                <h3
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                  className="heading_2 font-bold text-[#4C34AD]"
                >
                  Create Course
                </h3>

                <p className="py-4 font-segoe-ui text-7xs 2xs:text-6xs  2xl:text-5xs 2.5xl:text-6xs">
                  Want to publish a vehicle learning course? Click on Create
                  Course to publish
                </p>
                <div className=" flex w-full justify-center ">
                  <div className="img w-[60%] py-6">
                    <img
                      src={require("./dashboard 0.2/images/createCourse.png")}
                      alt=""
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="text-center">
                  <Link to={CourseRoute}>
                    <button
                      type="button"
                      class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 text-4xs font-medium text-white outline-none transition-all hover:scale-95 hover:bg-[#656afd] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px] "
                    >
                      Create Course
                    </button>
                  </Link>
                </div>
              </div>
              {staff ? (
                <ManageLogin
                  heading={"Home Pickups"}
                  paragraph={"Enable Home Pickup service for the students"}
                  link={""}
                  btnText={"Pickup"}
                  staff={
                    GetLocalStorage("UserInfo").InstituteUserType === "Staff"
                  }
                />
              ) : (
                <ManageLogin
                  heading={"Manage Logins"}
                  paragraph={"Manage login details for your staff here "}
                  link={"/staff/manage-login"}
                  btnText={"Create Login"}
                  staff={
                    GetLocalStorage("UserInfo").InstituteUserType === "Staff"
                  }
                />
              )}

              <div></div>
            </div>
          </div>
        ) : GetLocalStorage("UserInfo").User === "Admin" ? (
          <DashBoardAdminCourses />
        ) : null}
      </div>
    </div>
  );
}

export default InsStaffAdminDashboard;
