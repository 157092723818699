import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CoursesTiles.css";
const CourseSliderForSearchCourses = ({
  CoursesInfo,
  CourseTiles,
  ClassNames,
  ExtraMove = 0,
  setFilteredCount
}) => {
  const [ref, setref] = useState({});
  const [Size, setSize] = useState(0);

  console.log("SearchCourses ref", ref)
 
  return (
    <div
      className={`w-full h-fit relative flex justify-center items-center flex-col  `}
    >

      <div
        className={`w-[95%] mx-2 flex items-center justify-center py-5 overflow-hidden`}
      >
        <div
          style={{
            left: -(Size * ref?.offsetWidth + (Size * ExtraMove) / 2) + "px",
          }}
          className={ClassNames}
        >
          <CourseTiles
            CoursesInfo={CoursesInfo}
            VehicleTypesSate={CoursesInfo}
            setref={setref}
            setFilteredCount={setFilteredCount}
          />
        </div>
      </div>
      
    </div>
  );
};


export default CourseSliderForSearchCourses;
