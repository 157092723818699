import React, {useState, useEffect, useContext} from "react";
import dateCalendar from "../Assets/dateCalendar.png"
import CityIcon from "../Assets/Cityicon.png"
import LocationIcon from "../Assets/Locationicon.png"
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSettingsField } from "../../../Actions/StudentA";
import { LogOut } from "../../../Actions/UserA";
import SettingsPopup from "../Popups/Popups";
import { signOut } from "firebase/auth";
import {  auth ,provider } from "../../LoginAndRegister/Components/UserSigning/Components/LeftSide/ContinueWIth/config";
import { SocketContext } from "../../../App";
import { City, Country, State } from "country-state-city";
import { CountryOptions } from "../../Institute/AddInstructor/Components/OptionsArr/OptionsArr";
import SettingSearchLocationByInputStudent from "../../Institute/AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SettingSearchLocationByInputStudent";
import { SelectList, SettingSelectList } from "../../../Components/InputComps/InputComps";

const ProfileContent= ({userInfo, UserId}) => {
    const Dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [SelectedCoordinates, setSelectedCoordinates] = useState(null);
    const socket = useContext(SocketContext);


    const Border = "border-[#E2E2E2] border-[1px] border-solid";
    console.log("UserInforamtion in ProfileContent page is:", userInfo)
    const [cities, setCities] = useState([]);

    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        DOB: "",
        Gender: "",
        Country: "",
        City: "",
        Province: "",
        PostalCode: "",
        Address: "",
        PhoneNumber: "",
        EmergencyPhoneNumber1: "",
        EmergencyPhoneNumber2: "",
        EmergencyEmailAddress1: "",
        EmergencyEmailAddress2: "",

    });

    useEffect(() => {
        if (userInfo) {
            setFormData((prevFormData) => {
                const dob = userInfo?.StudentInfo?.DOB
                    ? new Date(userInfo.StudentInfo.DOB).toLocaleDateString("en-GB") // Format as DD/MM/YYYY
                    : prevFormData.DOB; // Retain the previous value if DOB is not available
    
                return {
                    ...prevFormData,
                    FirstName: userInfo.FirstName || prevFormData.FirstName,
                    LastName: userInfo.LastName || prevFormData.LastName,
                    DOB: dob,
                    Gender: userInfo.StudentInfo?.Gender || prevFormData.Gender,
                    Country: userInfo.StudentInfo?.Country || prevFormData.Country,
                    City: userInfo.StudentInfo?.City || prevFormData.City,
                    Province: userInfo.StudentInfo?.Province || prevFormData.Province,
                    PostalCode: userInfo.StudentInfo?.PostalCode || prevFormData.PostalCode,
                    Address: userInfo.StudentInfo?.Address || prevFormData.Address,
                    PhoneNumber: userInfo?.PhoneNumber || prevFormData.PhoneNumber,
                    EmergencyPhoneNumber1:
                        userInfo.StudentInfo?.EmergencyPhoneNumber1 || prevFormData.EmergencyPhoneNumber1,
                    EmergencyPhoneNumber2:
                        userInfo.StudentInfo?.EmergencyPhoneNumber2 || prevFormData.EmergencyPhoneNumber2,
                    EmergencyEmailAddress1:
                        userInfo.StudentInfo?.EmergencyEmailAddress1 || prevFormData.EmergencyEmailAddress1,
                    EmergencyEmailAddress2:
                        userInfo.StudentInfo?.EmergencyEmailAddress2 || prevFormData.EmergencyEmailAddress2,
                };
            });
        }
    }, [userInfo]);

    useEffect(() => {
        const selectedCountry = formData?.Country;
        console.log("selected country set in new location is:",selectedCountry)
        const selectedProvince = formData?.Province;
        console.log("selected selectedProvince set in new location is:",selectedProvince)
        if (
          typeof selectedCountry === "string" &&
          typeof selectedProvince === "string"
        ) {
          const country = Country?.getAllCountries()?.find(
            (country) =>
              country?.name.toLowerCase() === selectedCountry?.toLowerCase()
          );
          if (country) {
            const states = State?.getStatesOfCountry(country?.isoCode);
            const selectedProvinceData = states?.find(
              (province) =>
                province.name.toLowerCase() === selectedProvince?.toLowerCase()
            );
            if (selectedProvinceData ) {
              console.log("Found Province: ", selectedProvinceData);
              const citiesData = City.getCitiesOfState(
                country?.isoCode,
                selectedProvinceData?.isoCode
              );
              setCities(citiesData);
            }
          }
        }
      }, [formData]);

    const ProvinceOptions = () => {
        let states = [];
    
        // Determine the country to use
      const countryName = formData?.Country;
    
        if (typeof countryName  === "string") {
          let countries = Country?.getAllCountries();
    
          const country = countries?.find(
            (country) =>
              country?.name.toLowerCase() ===
              countryName.toLowerCase()
          );
    
          if (country) {
            states = State?.getStatesOfCountry(country?.isoCode);
            // console.log("country", states);
          }
        }
    
        console.log("States", states);
        return states?.map((country) => (
          <option value={country.name} key={country.isoCode}>
            {country?.name}
          </option>
        ));
      };

    const CityOptions = () => {
    return (
        cities &&
        cities?.map((city, index) => (
        <option value={city?.name} key={index}>
            {city?.name}
        </option>
        ))
    );
    };

    const handleChange = (e, field) => {
        let value;
        let inputField;
    
        if (typeof e === "string") {
            // For inputs like PhoneInput that provide value directly
            value = e;
            inputField = field;
        } else if (e && e.target) {
            // For standard inputs with event object
            value = e.target.value;
            inputField = field || e.target.name || e.target.id; // Prioritize `name`, fallback to `id`
        }
    
        if (!inputField) return; // Prevent errors if no field is identified
    
        setFormData((prev) => {
            const updatedFormData = { ...prev, [inputField]: value };
    
            // Reset dependent fields for hierarchical relationships
            if (inputField === "Country") {
                updatedFormData.Province = "";
                updatedFormData.City = "";
            } else if (inputField === "Province") {
                updatedFormData.City = "";
            }
    
            return updatedFormData;
        });
    };
     
    // const handleChange = (e, field) => {
    //     if (typeof e === "string" && field) {
    //       // Handles PhoneInput change
    //       setFormData((prev) => ({ ...prev, [field]: e }));
    //     } else {
    //       // Handles standard input change
    //       const { name, value } = e.target;
    //       setFormData((prev) => ({ ...prev, [name]: value }));
    //     }
    //   };

      const LogOutUser = () => {
        signOut(auth, provider)
            .then(() => {
                console.log("Sign-out successful.2");
                socket?.emit("offline", { UserId: UserId});
            })
            .catch((error) => {
                // An error happened.
            });
        Dispatch(LogOut());
    };
      
    const handleSubmit = () => {
        console.log("Form Data:", formData);
      
        Dispatch(UpdateSettingsField(UserId, formData))
          .then(() => {
            setShowPopup(true); // Show popup on successful update
      
            // Add a delay of 5 seconds before calling LogOutUser
            setTimeout(() => {
              LogOutUser();
            }, 5000); // 5000 milliseconds = 5 seconds
          })
          .catch((error) => {
            console.error("Error updating settings:", error);
            // Handle error if needed
          });
      };
    
    return (
        <div className="w-full flex flex-col gap-[10px]" >
            <p className="text-[#404040] text-[20px] font-bold">Personal Details</p>
            <div className=" w-[90%] flex flex-col gap-[10px] bg-[#F8FAFA] rounded-[9px] px-[20px] py-[15px]">
                 {/* First Name */}
                 <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="firstName"
                        className="text-[#404040] text-[16px]"
                    >
                        First Name
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        name="FirstName"
                        value={formData.FirstName}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    />
                </div>

                {/* Last Name */}
                <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="lastName"
                        className="text-[#404040] text-[16px]"
                    >
                        Last Name
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="LastName"
                        value={formData.LastName}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    />
                </div>

                {/* Date of Birth */}
                <div className="flex flex-col gap-[5px]">
                    <label htmlFor="dob" className="text-[#404040] text-[16px]">
                        Date of Birth (DD/MM/YYYY)
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            id="dob"
                            name="DOB"
                            value={formData.DOB}
                            onChange={handleChange}
                            className={`w-full rounded-md py-2 px-[10px] ${Border} shadow-[4px_5px_6px_#00000029]`}
                        />
                        {/* Icon */}
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#A9A9A9]">
                            <img src={dateCalendar} alt="calendar" className="w-[20px] h-[20px]" />
                        </span>
                    </div>
                </div>

                {/* Gender */}
                <div className="flex flex-col gap-[5px]">
                    <label htmlFor="gender" className="text-[#404040] text-[16px]">
                        Gender
                    </label>
                    <select
                        id="gender"
                        name="Gender"
                        value={formData.Gender}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2 px-[10px] ${Border} shadow-[4px_5px_6px_#00000029]`}
                    >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>

            </div>

            <p className="text-[#404040] text-[20px] font-bold mt-[10px]">Address</p>
            <div className=" w-[90%] flex flex-col gap-[10px] bg-[#F8FAFA] rounded-[9px] px-[20px] py-[15px]">
                 {/* Country */}
                 <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="Country"
                        className="text-[#404040] text-[16px]"
                    >
                        Country
                    </label>
                    
                    <div className="relative">
                        <SettingSelectList
                            Id="Country"
                            defaultValue=""
                            Text="Country"
                            OptionsArr={CountryOptions}
                            State={formData.Country}
                            onChange={(e) => handleChange(e, "Country")}
                        />

                        {/* <input
                            type="text"
                            id="Country"
                            name="Country"
                            value={formData.Country}
                            onChange={handleChange}
                            className={`w-full rounded-md py-2 px-[10px] ${Border} shadow-[4px_5px_6px_#00000029]`}
                        />
                        //Icon 
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#A9A9A9]">
                            <img src={LocationIcon} alt="LocationIcon" className="w-[15px] h-[20px]" />
                        </span> */}
                    </div>
                </div>

                {/* Province */}
                <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="Province"
                        className="text-[#404040] text-[16px]"
                    >
                        Province
                    </label>
                    <SettingSelectList
                        Id="Province"
                        defaultValue=""
                        Text="Province / Territory"
                        OptionsArr={ProvinceOptions}
                        State={formData.Province}
                        onChange={(e) => handleChange(e, "Province")}
                    />
                    {/* <input
                        type="text"
                        id="Province"
                        name="Province"
                        value={formData.Province}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    /> */}
                </div>

                 {/* City */}
                 <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="City"
                        className="text-[#404040] text-[16px]"
                    >
                        City
                    </label>
                    <SettingSelectList
                        Id="City"
                        defaultValue=""
                        Text="City"
                        OptionsArr={CityOptions}
                        State={formData.City}
                        onChange={(e) => handleChange(e, "City")} 
                    />
                    {/* <div className="relative">
                   
                        <input
                            type="text"
                            id="City"
                            name="City"
                            value={formData.City}
                            onChange={handleChange}
                            className={`w-full rounded-md py-2 px-[10px] ${Border} shadow-[4px_5px_6px_#00000029]`}
                        />
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#A9A9A9]">
                            <img src={CityIcon} alt="calendar" className="w-[20px] h-[20px]" />
                        </span>
                    </div> */}
                </div>

                {/* Postal Code */}
                <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="PostalCode"
                        className="text-[#404040] text-[16px]"
                    >
                        Postal Code
                    </label>
                    <input
                        type="text"
                        id="PostalCode"
                        name="PostalCode"
                        value={formData.PostalCode}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    />
                </div>

                {/* Address */}
                <div className="flex flex-col gap-[5px]">
                    <label htmlFor="Address" className="text-[#404040] text-[16px]">
                         Address
                    </label>
                    <SettingSearchLocationByInputStudent
                            InstructorData={{ Address: formData.Address }} // Pass Address data
                            // setInstructorData={(update) =>
                            //     setFormData((prev) => ({
                            //         ...prev,
                            //         ...update, // Update only the modified field
                            //     }))
                            // }
                            setInstructorData={setFormData }
                            EnrollmentData={{
                                StudentData: {
                                    Country: formData.Country, // Use selected country for restrictions
                                },
                            }}
                            setSelectedCoordinates={setSelectedCoordinates} // Update lat/lng
                            newCountryPostalCode={formData.Country}
                            Id="Address"
                            addressType="Address"
                        />

                    {/* <div className="relative">
                        <input
                            type="text"
                            id="Address"
                            name="Address"
                            value={formData.Address}
                            onChange={handleChange}
                            className={`w-full rounded-md py-2 px-[10px] ${Border} shadow-[4px_5px_6px_#00000029]`}
                        />
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#A9A9A9]">
                            <img src={LocationIcon} alt="LocationIcon" className="w-[15px] h-[20px]" />
                        </span>
                    </div> */}
                </div>

            </div>

            <p className="text-[#404040] text-[20px] font-bold mt-[10px]">Contact Details</p>
            <div className=" w-[90%] flex flex-col gap-[10px] bg-[#F8FAFA] rounded-[9px] px-[20px] py-[15px]">
                 {/* Phone Number */}
                 <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="Phone Number"
                        className="text-[#404040] text-[16px]"
                    >
                        Your Phone Number
                    </label>
                    <PhoneInput  // library to automatically show the country phone code  
                        country="ca" // Automatically sets the country code
                        value={formData.PhoneNumber}
                        onChange={(value) => handleChange(value, "PhoneNumber")}
                        inputProps={{
                        name: "PhoneNumber",
                        }}
                        containerClass={"PhoneNumberInput"}
                        inputClass={"CustomPhoneNumberInput"}
                        specialLabel={"Phone Number *"}
                        inputStyle={{
                        width: "100%",
                        height: "45px",
                        }}
                    />
                </div>
                 
                  {/* Emergency Phone Number 1 */}
                  <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="Emergency Contact Number (1)"
                        className="text-[#404040] text-[16px]"
                    >
                        Your Emergency Contact Number (1)
                    </label>
                    <PhoneInput  // library to automatically show the country phone code  
                        country="ca" // Automatically sets the country code
                        value={formData.EmergencyPhoneNumber1}
                        onChange={(value) => handleChange(value, "EmergencyPhoneNumber1")}
                        inputProps={{
                        name: "PhoneNumber",
                        }}
                        containerClass={"PhoneNumberInput"}
                        inputClass={"CustomPhoneNumberInput"}
                        specialLabel={"Phone Number"}
                        inputStyle={{
                        width: "100%",
                        height: "45px",
                        }}
                    />
                </div>

                {/*  Your Emergency Contact Number (1) */}
                <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="Your Emergency Contact Number (1)"
                        className="text-[#404040] text-[16px]"
                    >
                     Your Emergency Contact Number (2)
                    </label>
                    <PhoneInput  // library to automatically show the country phone code  
                        country="ca" // Automatically sets the country code
                        value={formData.EmergencyPhoneNumber2}
                        onChange={(value) => handleChange(value, "EmergencyPhoneNumber2")}
                        inputProps={{
                        name: "PhoneNumber",
                        }}
                        containerClass={"PhoneNumberInput"}
                        inputClass={"CustomPhoneNumberInput"}
                        specialLabel={"Phone Number *"}
                        inputStyle={{
                        width: "100%",
                        height: "45px",
                        }}
                    />
                </div>

                 {/* Emergency Email Address 1 */}
                 <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="EmergencyEmailAddress1"
                        className="text-[#404040] text-[16px]"
                    >
                        Emergency Email Address
                    </label>
                    <input
                        type="text"
                        id="EmergencyEmailAddress1"
                        name="EmergencyEmailAddress1"
                        value={formData.EmergencyEmailAddress1}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    />
                </div>

                {/* Emergency Email Address 2 */}
                <div className="flex flex-col gap-[5px]">
                    <label
                        htmlFor="EmergencyEmailAddress2"
                        className="text-[#404040] text-[16px]"
                    >
                        Emergency Email Address 2
                    </label>
                    <input
                        type="text"
                        id="EmergencyEmailAddress2"
                        name="EmergencyEmailAddress2"
                        value={formData.EmergencyEmailAddress2}
                        onChange={handleChange}
                        className={`w-full rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                    />
                </div>

            </div>

            <div className="w-[90%] flex justify-end mt-[10px]">
                <button
                  className="w-[160px] h-[44px] rounded-[8px] bg-[#A1A3EF] text-[#FFFFFF] text-[20px] cursor-pointer"
                  onClick={handleSubmit}>
                    Save Changes
                </button>
            </div>

            {/* <SettingsPopup
                    setShowPopup={setShowPopup}
                /> */}

            {showPopup && (
                <SettingsPopup
                    setShowPopup={setShowPopup}
                    text="Your changes have been saved successfully! Please log in again to view the updated data."
                />
            )}
        </div>
    );
};

export default ProfileContent;
