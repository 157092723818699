import React, {useState, useEffect} from "react";
import AccountSecurity from "./AccountSecurity/AccountSecurity";
// import ImgUrl from './Assets/ProfileImage.png';
import ProfileContent from "./ProfileContent/ProfileContent";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../Actions/Base";
import PersonalNotifications from "./Notifications/Notifications";

const StudentSettings= () => {
    const Dispatch = useDispatch();
    const { UserInfo: UserInformation } = useSelector((Store) => Store.LoginSignupReducer);

    console.log("UserId in settings page is:", UserInformation)
    const UserId = UserInformation?.UserId;
    console.log("UserId in settings page is:", UserId)
     // State to manage the active tab
     const [activeTab, setActiveTab] = useState("profile");
     const [imgUrl, setImgUrl] = useState("");

     useEffect(() => {
        if (UserInformation) {
            const ImgUrl = `${BaseUrl}/api/images/Student?url=${UserInformation?.StudentImage}`;
            setImgUrl(ImgUrl)
        }
    }, [UserInformation]);

     const [SettingsData, setSettingsData] = useState({
        StudentData: {
            FirstName: "",
            LastName: "",
            Address: "",
            PostalCode: "",
            Province: "",
            City: "",
            DOB: "",
            Email: "",
            Gender: "",
        },
    });

     // Function to render the right-side content based on the active tab
     const renderContent = () => {
         switch (activeTab) {
             case "profile":
                 return <ProfileContent userInfo={UserInformation} UserId={UserId} />;
            case "Account Security":
                    return <AccountSecurity userInfo={UserInformation} UserId={UserId} /> ;
            case "Notifications":
                return <PersonalNotifications UserId={UserId} /> ;
            
            default:
            return <ProfileContent />;
         }
     };
    
    return (
        <div className="flex w-full justify-center items-center mt-[150px] pb-[100px]">
           <div className="w-[70%] flex gap-[20px]">
                <div className="w-[30%] flex flex-col gap-[20px]">
                    <div className=" w-[90%] flex flex-col justify-center items-center gap-[10px] bg-[#F8FAFA] rounded-[9px] p-[15px]">
                        <p className="text-[#404040] text-[20px]">Profile</p>
                        <div  className="relative flex items-center justify-center overflow-hidden rounded-full text-center h-[80px] w-[80px]"
                            style={{border:'4px solid #2D2366'}}>
                                <img
                                    className="absolute w-full"
                                    src={imgUrl}
                                    alt="img"
                                /> 
                        </div>
                        <p className="text-[#404040] text-[22px] font-bold">{UserInformation?.FirstName || "John"} {UserInformation?.LastName || "Doe"}</p>
                    </div>
                    
                    <div className=" w-[90%] flex flex-col gap-[5px] bg-[#F8FAFA] rounded-[9px] p-[15px]">
                        <p className="text-[#404040] text-[20px] font-bold text-center">Quick Link</p>
                        <p
                            className={`text-[#2D2366] text-[16px] cursor-pointer ${
                                activeTab === "profile" ? "font-bold" : ""
                            }`}
                            onClick={() => setActiveTab("profile")}
                        >
                            Profile
                        </p>
                        <p
                            className={`text-[#2D2366] text-[16px] cursor-pointer ${
                                activeTab === "Account Security" ? "font-bold" : ""
                            }`}
                            onClick={() => setActiveTab("Account Security")}
                        >
                            Account Security
                        </p>
                        <p
                            className={`text-[#2D2366] text-[16px] cursor-pointer ${
                                activeTab === "Subscriptions" ? "font-bold" : ""
                            }`}
                            onClick={() => setActiveTab("Subscriptions")}
                        >
                            Subscription
                        </p>
                        <p
                            className={`text-[#2D2366] text-[16px] cursor-pointer ${
                                activeTab === "Notifications" ? "font-bold" : ""
                            }`}
                            onClick={() => setActiveTab("Notifications")}
                        >
                            Notification
                        </p>
                        <p
                            className={`text-[#2D2366] text-[16px] cursor-pointer ${
                                activeTab === "Open Dispute" ? "font-bold" : ""
                            }`}
                            onClick={() => setActiveTab("Open Dispute")}
                        >
                            Open Dispute
                        </p>
                    </div>
                </div>
                <div className="w-[70%] flex flex-col gap-[20px]">
                        {renderContent()}
                </div>
           </div>
        </div>
    );
};

export default StudentSettings;
