import React, {useState, useEffect} from "react";
import HomeIcon from "./Assets/homeIcon.svg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PhoneIcon from "./Assets/phoneIconeRight.svg";
import { ContactSchoolEmergencyContact, GetDrivingInstituteNumberForStudent,
     SendHarassmentEmailAndSMSForStudent, SendLiveLocationLink,
     SendLocationToBeacon } from "../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";


function SOSMobileEmergencyTimerPopup({setOpenSOSMobilePopup,setOpenSOSEmergencyMobileTimerPopup,
     setSelectedSOSProvidedFeatures, selectedSOSProvidedFeatures, EventId, type,
     EnrollmentId, UserId, EmergencyContacts}) {
    const [countdown, setCountdown] = useState(7); // Initialize the countdown
    const Dispatch = useDispatch();

    const {
        institute: { InstituteEmail, InstituteEmergencyContact },
        student: {
          emergencyContacts: { email1, email2, phone1, phone2 },
        },
      } = EmergencyContacts;

    const { SchoolContactNumber } = useSelector(
        (Store) => Store.InstructorReducer
      );

    console.log("SchoolContactNumber is:",SchoolContactNumber)

    let liveLocationLink = `${window.location.protocol}//${window.location.host}/userlocation/${EventId}`;
    let StudentLiveLocationLink = `${window.location.protocol}//${window.location.host}/track-student-livelocation/${UserId}`;

    const featureTexts = {
        '911': 'Call 911 Emergency',
        'Harassment': 'Report Harassment Emergency',
        'Call Driving School': 'Contact Institute Emergency',
        'Location With Beacon': 'Share Live Location'
    };
    
    const featureDescriptionTexts = {
        '911': 'Emergency notification will be sent to 911 after',
        'Harassment': (type === "Student" ? (
            <>
                Emergency notification will be sent to the following contacts:
                <br />
                <b>Email:</b> {email1}, {email2 ? `${email2},` : ''} {InstituteEmail}
                <br />
                <b>SMS:</b> {phone1}, {phone2 ? `${phone2},` : ''} {InstituteEmergencyContact}
                <br />
                after
            </>
        ) : (
            'Emergency notification will be sent to the authorities for harassment after'
        )),
        'Call Driving School': 'Emergency notification will be sent to the driving institute after',
        'Location With Beacon': (
            <>
              Emergency notification will be sent to the following contacts:
              <br />
              <b>Email:</b> {email1}, {email2 ? `${email2},` : ''} 
              <br />
              <b>SMS:</b> {phone1}, {phone2 ? `${phone2},` : ''} 
              <br/>
              after
            </>
          ),
    };
    

    useEffect(() => {
        if (countdown === 0) {
            // When countdown reaches 0, trigger the emergency action
            console.log("Emergency notification sent to 911!");
            if(type=== "Student"){
                if(selectedSOSProvidedFeatures==='911'){
                        localStorage.setItem('selectedSOSFeature', '911'); // Store as a string in localStorage
                        window.location.href = 'tel:411';
                } else if (selectedSOSProvidedFeatures==='Harassment'){
                        localStorage.setItem('selectedSOSFeature', 'Harassment'); // Store as a string in localStorage
                        Dispatch(SendHarassmentEmailAndSMSForStudent(liveLocationLink,EnrollmentId, UserId))
                } else if (selectedSOSProvidedFeatures==='Call Driving School'){
                    localStorage.setItem('selectedSOSFeature', 'Call Driving School'); // Store as a string in localStorage
                    Dispatch(GetDrivingInstituteNumberForStudent(EnrollmentId))

                        if (SchoolContactNumber) {
                            console.log("ContactSchoolEmergencyContact retrieved from db is:", SchoolContactNumber);
                            let schoolEmergencyContactNumber = SchoolContactNumber.emergencyContactNumber;
                            console.log("schoolEmergencyContactNumber retrieved here is:", schoolEmergencyContactNumber);
                            window.location.href = `tel:${schoolEmergencyContactNumber}`;
                        } else {
                            console.log("No contact number available");
                        }
                } else if (selectedSOSProvidedFeatures==='Location With Beacon'){
                    localStorage.setItem('selectedSOSFeature', 'Location With Beacon'); // Store as a string in localStorage
                    Dispatch(SendLocationToBeacon(StudentLiveLocationLink, UserId))
                }
        } else {
            if(selectedSOSProvidedFeatures==='911'){
                localStorage.setItem('selectedSOSFeature', '911'); // Store as a string in localStorage
                window.location.href = 'tel:411';
                } else if (selectedSOSProvidedFeatures==='Harassment'){
                    localStorage.setItem('selectedSOSFeature', 'Harassment'); // Store as a string in localStorage
                    Dispatch(SendLiveLocationLink(liveLocationLink,EventId))
                } else if (selectedSOSProvidedFeatures==='Call Driving School'){
                    localStorage.setItem('selectedSOSFeature', 'Call Driving School'); // Store as a string in localStorage
                    Dispatch(ContactSchoolEmergencyContact(EventId))

                        if (SchoolContactNumber) {
                            console.log("ContactSchoolEmergencyContact retrieved from db is:", SchoolContactNumber);
                            let schoolEmergencyContactNumber = SchoolContactNumber.emergencyContactNumber;
                            console.log("schoolEmergencyContactNumber retrieved here is:", schoolEmergencyContactNumber);
                            window.location.href = `tel:${schoolEmergencyContactNumber}`;
                        } else {
                            console.log("No contact number available");
                        }
                     }
                }
            // setOpenSOSAlertPopup(true)
            // setOpenSOSEmergencyMobileTimerPopup(false)
             // Add a 5-second delay before closing the popup
                setTimeout(() => {
                    setOpenSOSEmergencyMobileTimerPopup(false);
                }, 5000);
            return;
        }

        const timer = setTimeout(() => {
            setCountdown((prev) => prev - 1); // Decrement the countdown
        }, 1000); // Delay of 1 second

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [countdown, SchoolContactNumber]);

    // Handle the "Cancel Alert" button click
  const handleCancelAlert = () => {
    setOpenSOSEmergencyMobileTimerPopup(false); // Close the popup
  };

  // Handle outside click
  const handleOverlayClick = () => {
    setOpenSOSEmergencyMobileTimerPopup(false);
};

    return(
        <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex justify-center items-end"
        onClick={handleOverlayClick} // Close popup on overlay click
        >
            <div className="fixed bottom-0 left-0 w-full bg-[#FFFFFF] rounded-tr-[24px] rounded-tl-[24px] z-[9999] pb-[10px]">
                <div className="w-full flex justify-center items-center mt-[10px]">
                    <div className="w-[61px] h-[5px] bg-[#E6E6E6] rounded-full">
                    </div>
                </div>

                <div className="flex flex-col gap-[20px] px-[14px] py-[15px]">
                    <div className="flex justify-center items-center gap-[10px]">
                        <img src={PhoneIcon} alt="phone" />
                        <p className="text-[20px] font-bold">{featureTexts[selectedSOSProvidedFeatures] || 'Call Emergency Service'}</p>
                    </div>

                    <div className="flex w-full justify-center items-center">
                        <div className="w-[80px] h-[80px] rounded-full bg-white flex justify-center items-center"
                        style={{ boxShadow: '0px 0px 14px #4C34AD' }}>
                            <p className="font-bold text-[27px] text-[#A1A3EF]">{countdown}</p>
                        </div>
                    </div>

                    <div  className="w-full flex flex-col justify-center items-center gap-[10px]">
                        <p className="text-[20px] font-bold w-[250px] text-center">Accidentally Pressed Panic Button?</p>
                        <p className="text-[14px] text-[#636363] font-bold w-[270px] text-center">{featureDescriptionTexts[selectedSOSProvidedFeatures] || 'Emergency notification will be sent after'} {countdown} seconds.</p>
                    </div>

                    <div className="flex w-full justify-center items-center">
                        <button className="rounded-full text-[#4C34AD] w-[330px] h-[40px] text-[18px]"
                        style={{
                            border : "1px solid #4C34AD"
                        }}
                        onClick={handleCancelAlert}
                        >
                            Cancel Alert
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SOSMobileEmergencyTimerPopup;
