import React,{useEffect, useRef} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Player } from "video-react";

const TrackCustomeMediaPlayer = (props) => {
  const {
    title = "Recorded in Vehicle",
    open,
    onClose,
    media,
    controls,
    onTimeUpdate,
    onVideoReady 
  } = props;

  const videoRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;
  //   if (video && onTimeUpdate) {
  //     const handleTimeUpdate = () => onTimeUpdate(video.currentTime);
  //     video.addEventListener("timeupdate", handleTimeUpdate);

  //     return () => video.removeEventListener("timeupdate", handleTimeUpdate);
  //   }
  // }, [onTimeUpdate]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.oncanplay = () => {
        onVideoReady?.(); // Notify parent when the video is ready
      };

      const handleTimeUpdate = () => onTimeUpdate?.(video.currentTime);
      video.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.oncanplay = null;
      };
    }
  }, [onTimeUpdate, onVideoReady]);

  return (
    <div style={{ width: "100%" }}>
        <video
          // ref={(videoEle) => {
          //   console.log(media);
          //   videoEle && (videoEle.src = media);
          // }}
          ref={videoRef}
          controls={controls}
          src={media}
          className="w-full"
          // autoPlay
        ></video>
    </div>
  );
};

export default TrackCustomeMediaPlayer;
