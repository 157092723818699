// react States
import React, { useEffect, useState } from "react";
// Images
import descriptionAddCourse2 from "./Assets/descriptionAddCourse2.png";
// components
import CourseDescription from "./components/CourseDescription.jsx";
import SyllabusDescription from "./components/SyllabusDescription.jsx";
import WeeklyCourseModule from "./components/WeeklyCourseModule.jsx";

// css
import "./courseDetails.css";

const CourseDetails = ({
  Steps,
  setSteps,
  CourseData,
  setCourseData,
  StepsIndicator,
  PageNo,
  OnClickBack,
  OnClickSteps,
  CourseInfo,
  update,
}) => {
  const [CourseDetailsData, setCourseDetailsData] = useState({
    ShortDescription: "",
    LongDescription: "",
    CourseSyllabus: [{ CourseDescription: "" }],
    ClassSchedule: [{ ClassDescription: "", ClassNumber: "", WeekNumber: "" }],
  });

  const [CourseDetailsErr, setCourseDetailsErr] = useState({});
  useEffect(() => {
    // Update CourseDetailsData when CourseData changes
    setCourseDetailsData({
      ShortDescription:
        CourseInfo && CourseInfo.ShortDescription
          ? CourseInfo.ShortDescription
          : "",
      LongDescription:
        CourseInfo && CourseInfo.LongDescription
          ? CourseInfo.LongDescription
          : "",
      CourseSyllabus:
        CourseInfo && CourseInfo.CourseSyllabuses
          ? CourseInfo.CourseSyllabuses
          : [{ CourseDescription: "" }],
      ClassSchedule:
        CourseInfo && CourseInfo.ClassSchedules
          ? CourseInfo.ClassSchedules
          : [{ ClassDescription: "", ClassNumber: "", WeekNumber: "" }],
    });
  }, [CourseInfo]);

  // useEffect(() => {
  //     setCourseData({ ...CourseData, ...CourseDetailsData, })
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [CourseDetailsData])

  useEffect(() => {
    const updatedCourseDetailsData = {
      ...CourseDetailsData,
      ClassSchedule: CourseDetailsData?.ClassSchedule?.map((schedule) => ({
        ...schedule,
        WeekNumber: parseInt(schedule.WeekNumber),
        ClassNumber: parseInt(schedule.WeekNumber),
      })),
    };
    setCourseData({ ...CourseData, ...updatedCourseDetailsData });
  }, [CourseDetailsData]);

  return (
    <div
      className={`addCoursesMainContainer ${Steps !== PageNo ? "hidden" : ""}`}
    >
      <div className="row addCourse1">
        <div className="col-12">
          <div className="mainContainerAddCourse">
            <StepsIndicator Steps={Steps} OnClickSteps={OnClickSteps} />
            <div className="mainContainer1White">
              {/* <center className="descriptionIconTxt">
                                <div>
                                    <img
                                        src={descriptionAddCourse2}
                                        alt="descriptionAddCourse2"
                                    />
                                </div>
                                <h6>Description</h6>
                            </center> */}
              <h2 className="text-[32px] text-[#4C34AD]">Description</h2>
              <CourseDescription
                update={update}
                CourseDetailsData={CourseDetailsData}
                setCourseDetailsData={setCourseDetailsData}
                CourseDetailsErr={CourseDetailsErr}
                setCourseDetailsErr={setCourseDetailsErr}
              />
              <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
                {/* <div className="col5Descriptions"> */}
                {/* <div className="row"> */}
                <div className="installmentsTxt">
                  <h2 className="font-semibold text-[#4C34AD]">
                    Course Syllabus
                  </h2>
                  <p className="text-[20px]  text-[#4C34AD]">
                    Enter Syllabus for this course
                  </p>
                </div>
                {/* </div> */}
                {/* </div> */}
                <div className="flex items-center justify-center">
                  <SyllabusDescription
                    update={update}
                    CourseDetailsData={CourseDetailsData}
                    setCourseDetailsData={setCourseDetailsData}
                    CourseDetailsErr={CourseDetailsErr}
                    setCourseDetailsErr={setCourseDetailsErr}
                  />
                </div>
              </div>

              {/* <div
                                className="col5Descriptions"
                                style={{ marginLeft: "50px" }}
                            > */}

                            
              {/* <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
                <div className="installmentsTxt">
                  <h2 className="font-semibold text-[#4C34AD]">
                    Weekly Course Module
                  </h2>
                  <p className="text-[20px]  text-[#4C34AD]">
                    Enter details for each class in <br /> this course
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <WeeklyCourseModule
                    update={update}
                    CourseDetailsData={CourseDetailsData}
                    setCourseDetailsData={setCourseDetailsData}
                    CourseDetailsErr={CourseDetailsErr}
                    setCourseDetailsErr={setCourseDetailsErr}
                  />
                </div>
              </div> */}


              {/* faqs section */}
              {/* <div
                                className="col5Descriptions"
                                style={{ marginLeft: "50px" }}
                            >
                                <div className="row">
                                    <div className="col-5">
                                        <h6>
                                            Possible FAQ's about <br /> the
                                            course (if any)
                                        </h6>
                                        <p>
                                            Enter Possible Queries
                                            <br /> related to the course.
                                        </p>
                                    </div>
                                    <div className="col-7">
                                        <input
                                            className="addCourse2Texterea1 addCourse2Texterea3"
                                            placeholder="How to enroll for the Course?"
                                            onChange={(e) =>
                                                setCourseDetailsData({
                                                    ...CourseDetailsData,
                                                    Possible_FAQs: [
                                                        e.target.value,
                                                    ],
                                                })
                                            }
                                        />
                                        <p className="addCourse2Texterea1WordsCount">
                                            <button
                                                className="addCourse2AddFAQBtn"
                                                type="button"
                                            >
                                                Add FAQ's
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div> */}
              <br />
              <br />
            </div>
          </div>
          {/* <center>
                        <button
                            className="SaveAndContinueBtn cursor-pointer"
                            type="button"
                            onClick={() => setSteps(4)}
                        >
                            Save & Continue
                        </button>
                    </center> */}

          {!update && (
            <div className="flex items-center justify-center gap-5">
              <button
                className="SaveAndContinueBtn w-[12%]  cursor-pointer"
                type="button"
                onClick={() => OnClickBack(Steps)}
              >
                Back
              </button>
              <button
                className="SaveAndContinueBtn  cursor-pointer"
                type="button"
                onClick={() => setSteps(4)}
              >
                Save & Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
