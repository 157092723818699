import React, {useState, useEffect} from "react";
import ResetSuccess from "../Assets/ResetSuccess.svg";

const ResetPasswordSettingsSuccessPopup = ({setPasswordResetSuccessShowPopup }) => {
   
  const closePopup = () =>{
      console.log("Close icon clicked");
      setPasswordResetSuccessShowPopup(false);
  }
    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative rounded-[9px] bg-white p-[20px] shadow-lg">
          <div className="flex justify-center items-center">
            <img src={ResetSuccess} alt="success reset password" className='w-[40px] h-[40px]'/>
          </div>
          <h2
            className=" mb-4 text-center text-[16px] mt-[10px]"
          >
            Congratulations !!
          </h2>
          <p className="text-center text-[14px] w-[270px]">You have successfully reset your password.</p>
          <div className="w-full flex justify-center items-center gap-[15px] mt-[20px]">
            <button
                className="px-[15px] py-[7px] rounded-[6px] bg-[#A1A3EF] text-[#FFFFFF] text-[14px] cursor-pointer"
                onClick={closePopup}
                style={{border:"2px solid #A1A3EF"}}>
                Done
            </button>
          </div>
        </div>
      </div>
    );
  };

export default ResetPasswordSettingsSuccessPopup;
