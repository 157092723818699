import React, {useState, useEffect} from "react";
import axios from "axios";
import { BaseUrl } from "../../../Actions/Base";

const OtpVerificationSettingsPopup = ({userEmail, setOtpVerificationShowPopup, setCreateNewPasswordShowPopup }) => {
  const Border = "border-[#E2E2E2] border-[1px] border-solid";
  const [otp, setOtp] = useState(new Array(6).fill("")); // Array for OTP inputs
  const [error, setError] = useState(""); // Error message

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ""); // Only allow numbers
    if (value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to next input field if digit entered
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const closePopup = () =>{
      console.log("Close icon clicked");
      setOtpVerificationShowPopup(false);
  }

    const axiosInstance = axios.create({
      baseURL: BaseUrl,
      withCredentials: true,
    });

    const handleSubmit = async () => {
      const fullOtp = otp.join(""); // Combine array to string
      console.log("Submit", fullOtp);
  
      if (fullOtp.length < 6) {
        setError("Please enter a valid 6-digit OTP.");
        return;
      }
  
      const payload = {
        token: JSON.parse(localStorage.getItem("token")),
        OTP: fullOtp,
      };
  
      try {
        const res = await axiosInstance.post("/api/verify-otp", payload);
        console.log(res);
  
        if (res?.data?.success === true) {
          setCreateNewPasswordShowPopup(true);
          setOtpVerificationShowPopup(false);
        } else if (res?.data?.success === false) {
          setError(res?.data?.message);
        }
      } catch (err) {
        console.log(err);
        setError(err?.response?.data?.message || "An error occurred.");
      }
    };

    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative rounded-[9px] bg-white p-[20px] shadow-lg w-[400px]">
          <h2
            className=" mb-2 text-center text-[18px]"
          >
            OTP Verification
          </h2>
          <p className="text-center text-[14px]">A code has been sent to your E-mail <span className="font-bold">{userEmail}</span></p>
          <div className="w-[90%] flex justify-center items-center gap-[10px] mt-[10px] mr-[15px] ml-[17px]">
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(e.target, index)}
                  className={`w-[47px] h-[54px] rounded-md py-2 px-[10px] ${Border} text-bold shadow-[4px_5px_6px_#00000029] text-center`}
                />
              ))}
          </div>
          {error && (
          <p className="text-[#ff0000] text-center mt-2 text-[14px]">{error}</p>
        )}
          <div className="w-full flex justify-center items-center gap-[15px] mt-[15px]">
            <button
                className="py-[7px] w-[100px] rounded-[6px] text-[#A1A3EF] text-[14px] cursor-pointer"
                style={{border:"2px solid #A1A3EF"}}
                onClick={closePopup}>
               Back
            </button>
            <button
                className="w-[100px] py-[7px] rounded-[6px] bg-[#A1A3EF] text-[#FFFFFF] text-[14px] cursor-pointer"
                onClick={handleSubmit}
                style={{border:"2px solid #A1A3EF"}}>
               Verify
            </button>
          </div>
        </div>
      </div>
    );
  };

export default OtpVerificationSettingsPopup;
