import React, { useEffect, useState, useRef, useContext } from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import ClientInfo from "../CourseProgress (Institute)/Components/ClientInfo";
import CourseInstructors from "./Components/CourseInstructors/CourseInstructors";
import StudentTimeTable from "./Components/StudentTimeTable/StudentTimeTable";
import InstructorSlider, {
  SchedulerCompleted,
} from "./Components/ManageStudentCarousel/ManageStudentCarousel";
import { useDispatch, useSelector } from "react-redux";
import { CreateScheduleA, GetClassScheduleA, GetUserByIdA } from "../../../Actions/StudentA";
import { useParams } from "react-router-dom";
import { GetAboutInfoA } from "../../../Actions/CourseA";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import StudentScheduleTimeTable from "./Components/StudentPreferreTimeTable/StudentPreferreTimeTable";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import { InsEnrolledCourseButtons } from "../../../PageNames";
import "./Components/CourseInstructors/courseI.css";
import { useAuthContext } from "../../../context/authContext";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import { RRule, RRuleSet, rrulestr } from "rrule";
import LastLicense from "../CourseProgress (Institute)/Components/LastLicense";
import LicenseFullView from "./Components/CourseInstructors/Components/LicenseFullView";
import { BaseUrl } from "../../../Actions/Base";
import LicenseUpdatePopup from "./Components/CourseInstructors/Components/LicenseUpdatePopup";
import TabsSection from "./Components/CourseInstructors/Components/TabsSection";
import { useLocation } from 'react-router-dom';
import { useTimetable } from "../../../context/timetableContext";
import { GetInstituteSideCoursesTimeSlots } from "../../../Actions/CourseA";
import { StudentClassesContext } from "../../../context/StudentClassesContext";

function ManageNewStudentChild() {
  const location = useLocation(); // Use useLocation to get the state
  const { userId } = location.state || {}; // Destructure userId from the state
  const [success, setSuccess] = useState(false)

  console.log("User ID in manage:", userId); // Use the userId here
  const { studentClassesScheduleAppointments, setStudentClassesScheduleAppointments } = useContext(StudentClassesContext);


  const { lastFiveValues } = useAuthContext();
  const Dispatch = useDispatch();
  const { EnrollmentId } = useParams();
  const { Student, ScheduleTimeTable, loading, CoursePackage, StudentInfo } = useSelector(
    (Store) => Store.StudentReducer
  );
  console.log("Student", Student);
  // console.log("ScheduleTimeTable", ScheduleTimeTable)
  const [Events, setEvents] = useState([]);
  const [licenseFullView, setLicenseFullView] = useState(false);
  const [licenseUpdate, setLicenseUpdate] = useState(false);
  const imageUrl = `${BaseUrl}/api/Images/LatestLicense?url=${StudentInfo?.LatestLicense}`;
  const [StudentPrefrredTimeTable, setStudentPrefrredTimeTable] = useState([]);
  const [StudentEnrollmentSelectedTimeTable, setStudentEnrollmentSelectedTimeTable] = useState([]);
  const [InsEvent, setInsEvent] = useState([]);

  const [OneInstructor, setOneInstructor] = useState();
  const [ShowSlider, setShowSlider] = useState({
    InstructorType: "",
    Show: false,
  });
  console.log("CoursePackage", CoursePackage);
  console.log("StudentInfo", StudentInfo);
  const [Instructors, setInstructors] = useState([
    undefined,
    undefined,
    undefined,
  ]);
  const ClassType = [
    { id: "Driving", text: "Driving" },
    { id: "Online", text: "Online" },
    { id: "InClass", text: "InClass" },
  ];

  useEffect(() => {
    console.log("Success state changed:", success); // Log state changes

    if (success) {
      alert("Schedule created successfully!");
      setSuccess(false); // Reset success state after showing the alert
    }
  }, [success]);

  const [activeTab, setActiveTab] = useState('client');

  const clientRef = useRef(null);
  const instructorsRef = useRef(null);
  const timetableRef = useRef(null);

  const scrollToSection = (section) => {
    const refMap = {
      client: clientRef,
      instructors: instructorsRef,
      timetable: timetableRef,
    };
  
    const sectionRef = refMap[section]?.current;
  
    if (sectionRef) {
      setActiveTab(section); // Set the active tab first
      setTimeout(() => {
        sectionRef.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the timeout as needed
    }
  };

  const InsId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;
  const { courseSideTimeSlots } = useSelector((Store) => Store.CourseReducer);
  console.log("courseSideTimeSlots are:",courseSideTimeSlots)
    useEffect(() => {
    if (InsId) {
        console.log("InsId is:", InsId)
        Dispatch(GetInstituteSideCoursesTimeSlots(InsId)); // Pass the state
    }
}, [Dispatch, InsId]);

  useEffect(() => {
    if (EnrollmentId) {
        Dispatch(GetClassScheduleA(EnrollmentId)); 
        Dispatch(GetAboutInfoA(EnrollmentId)); 
    }
  }, [Dispatch, EnrollmentId, userId]); // Add `userId` if it's used in the effect


  useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

  const [selectedDate, setSelectedDate] = useState(null); // Initialize with current date
  const { userSelectedDbTimetable, setUserSelectedDbTimetable } = useTimetable();

/* useEffect(() => {
  if (ScheduleTimeTable) {
    console.log("schedule time table is:", ScheduleTimeTable);
    const editAble = JSON.parse(JSON.stringify(ScheduleTimeTable));

    const getNextMonday = (date) => {
      const currentDay = date.getDay();
      const daysUntilMonday = currentDay === 0 ? 1 : 8 - currentDay;
      const nextMonday = new Date(date);
      nextMonday.setDate(date.getDate() + daysUntilMonday);
      nextMonday.setHours(0, 0, 0, 0);
      return nextMonday;
    };

    const getMondayOfWeek = (date) => {
      const day = date.getDay();
      const monday = new Date(date);
      const diffToMonday = day === 0 ? -6 : day - 1;
      monday.setDate(date.getDate() - diffToMonday);
      monday.setHours(0, 0, 0, 0);
      console.log("Monday of the selected week is:", monday);
      return monday;
    };

    const getStartDate = () => {
      if (selectedDate) {
        const selectedDay = new Date(selectedDate).getDay();
        console.log("selected day is:", selectedDay);
        if (selectedDay === 1) {  // If Monday
          return new Date(selectedDate);
        } else {
          return getMondayOfWeek(new Date(selectedDate));
        }
      } else {
        return getNextMonday(new Date());
      }
    };

    const addDays = (date, days) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + days);
      return newDate;
    };

    const convertedEvents = editAble.map((value) => {
      let startDate = new Date(value.start);
      const baseDate = getStartDate();
      const originalEventDay = new Date(value.start).getDay();

      // Fix: Subtract 1 from originalEventDay to map correctly to the week starting from Monday
      const daysFromMonday = originalEventDay - 1; // Monday is 1, subtract 1 to align with the week
      startDate = addDays(baseDate, daysFromMonday);

      const originalStartTime = new Date(value.start);
      startDate.setHours(originalStartTime.getHours(), originalStartTime.getMinutes(), originalStartTime.getSeconds());

      let endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);

      return {
        ...value,
        startDate,
        endDate,
        allDay: false,
        description: value.description || "",
        text: value.text || "",
      };
    });

    console.log("converted events are:", convertedEvents);
    if(selectedDate){
      setStudentEnrollmentSelectedTimeTable(convertedEvents);
    } else {
      setStudentPrefrredTimeTable(convertedEvents);
      setStudentEnrollmentSelectedTimeTable(convertedEvents);
    }
    // Set converted events only if the context state is empty
    if (userSelectedDbTimetable.length === 0) {
      setUserSelectedDbTimetable(convertedEvents);
    }
   
  }
}, [ScheduleTimeTable, selectedDate]); */


const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours < 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

  const getNextMonday = (startFromDate) => {
    const today = startFromDate || new Date(); // Use the passed date or default to today
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate how many days to add to get to next Monday (if it's Sunday, it will be 1, etc.)
    const daysUntilNextMonday = (9 - dayOfWeek) % 7 || 7; // If it's Monday, keep it as Monday

    // Create a new Date object set to the next Monday
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilNextMonday);
    nextMonday.setHours(0, 0, 0, 0); // Set time to start of the day

    return nextMonday;
  };

  const getMondayOfWeek = (date) => {
    const selectedDate = new Date(date);
    const dayOfWeek = selectedDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  
    // Calculate the Monday of the current week, adjusting for Sunday (0)
    const daysToSubtract = (dayOfWeek === 0 ? 6 : dayOfWeek - 2); // If it's Sunday (0), subtract 6, otherwise subtract (dayOfWeek - 1)
    
    const monday = new Date(selectedDate);
    monday.setDate(selectedDate.getDate() - daysToSubtract);
    monday.setHours(0, 0, 0, 0); // Set time to start of the day
  
    return monday;
  };
  

// Helper function to parse time range and assign it to the correct date
const parseTimeRange = (day, timeRange, selectedDate) => {
  const [startTime, endTime] = timeRange.split(' - ');

  let startOfWeek;
  
  // If a date is selected, calculate the Monday of that week's date
  if (selectedDate) {
    startOfWeek = getMondayOfWeek(selectedDate); // Get the Monday of the selected date's week
  } else {
    startOfWeek = getNextMonday(); // Fallback to next Monday
  }

  // Get day offset from Monday to the correct day of the week
  const daysOfWeek = { Monday: 0, Tuesday: 1, Wednesday: 2, Thursday: 3, Friday: 4, Saturday: 5, Sunday: 6 };
  const dayOffset = daysOfWeek[day];

  // Create a new Date object for the specific day (starting from that week's Monday)
  const eventDate = new Date(startOfWeek);
  eventDate.setDate(startOfWeek.getDate() + dayOffset); // Adjust to the correct weekday

  // Format the date as 'YYYY-MM-DD'
  const formattedDate = eventDate.toISOString().split('T')[0];

  // Create start and end Date objects by combining the correct date with the time
  const startDate = new Date(`${formattedDate}T${convertTo24Hour(startTime)}:00`);
  const endDate = new Date(`${formattedDate}T${convertTo24Hour(endTime)}:00`);

  return { startDate, endDate };
};


// Transform class hours data to associate the correct date with each event
const transformClassHoursData = async (ScheduleTimeTable, selectedDate, idSuffix) => {
  console.log("ScheduleTimeTable in course instructor is:",ScheduleTimeTable)
  const dayMappings = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
  };

  const transformedData = [];

  // Loop over each day
  for (const day in dayMappings) {
    const slots = ScheduleTimeTable[day];

    // Only process days that have time slots (i.e., non-empty arrays)
    if (slots && slots.length > 0) {
      slots.forEach((slot, index) => {
        // Parse the time range and associate with the correct date for the given day
        const { startDate, endDate } = parseTimeRange(day, slot, selectedDate);

        if (startDate && endDate) {
          const eventData = {
            allDay: false,
            description: `${day} Class`, // You can modify this description as needed
            end: endDate.toLocaleString(),  // Use local string instead of ISO (to keep local time)
            endDate: endDate,  // Date object for end time
            // event_id: `${Date.now()}-${Math.floor(Math.random() * 10000)}-${idSuffix}`, // Unique identifier with suffix            start: startDate.toLocaleString(), // Use local string instead of ISO (to keep local time)
            startDate: startDate, // Date object for start time
            text: '', // Optional text description
          };

          transformedData.push(eventData);
        }
      }); 
    }
  }

  return transformedData;
};

// UseEffect or function to handle transformation and setting the new state
useEffect(() => {
  // Define an async function within the useEffect
  const transformAndSetData = async () => {
    try {
      if (ScheduleTimeTable) {
        console.log("ScheduleTimeTable data is:", ScheduleTimeTable);

        // Perform transformation with a unique idSuffix for each state
        const transformedPreferredData = await transformClassHoursData(ScheduleTimeTable, selectedDate, 'preferred');
        const transformedSelectedData = await transformClassHoursData(ScheduleTimeTable, selectedDate, 'selected');
        
        console.log("Transformed preferred data:", transformedPreferredData);
        console.log("Transformed selected data:", transformedSelectedData);

        // Update the state based on the selected date
        if (selectedDate) {
          setStudentEnrollmentSelectedTimeTable(transformedSelectedData);
        } else {
          setStudentPrefrredTimeTable(transformedPreferredData);
          setStudentEnrollmentSelectedTimeTable(transformedSelectedData);
        }

        // Set converted events only if the context state is empty
        if (userSelectedDbTimetable.length === 0) {
          setUserSelectedDbTimetable(transformedPreferredData);
        }
      }
    } catch (error) {
      console.error("Error transforming or setting data:", error);
    }
  };

  // Call the async function
  transformAndSetData();
}, [ScheduleTimeTable, selectedDate]);




useEffect(() => {
  console.log("Updated enrollment table:", StudentEnrollmentSelectedTimeTable);
  console.log("Updated preferred table:", StudentPrefrredTimeTable);
}, [StudentEnrollmentSelectedTimeTable, StudentPrefrredTimeTable]);


  return (
    <>
      {!loading ? (
        <>
          {licenseFullView && (
            <LicenseFullView
              imageUrl={imageUrl}
              setLicenseFullView={setLicenseFullView}
            />
          )}
          {licenseUpdate && (
            <LicenseUpdatePopup setLicenseUpdate={setLicenseUpdate} />
          )}
          <div
            className={`MyCoursesTopBG flex flex-col px-[3%]
                            pt-28 pb-4 sm:px-[3%] md:px-[5%] lg:px-[14%]
                            
                            xl:px-[20%]`}
          >
            <h2
              className={`Boldfont m-0 text-center
                        text-base text-white sm:text-[30px] md:text-[34px] lg:text-[38px] 
                        xl:text-[45px] pb-3`}
            >
              {CoursePackage?.InstituteCourse?.Course?.CourseName}
            </h2>
            <p className="text-center text-5xs font-semibold text-white lg:text-3xs 2xl:text-2xs">
              {/* {CoursePackage?.DrivingHours +
                " Hours Driving, " +
                CoursePackage?.InClassHours +
                " Hours In Class, " +
                CoursePackage?.OnlineHours +
                " Hours Online"} */}
                {`${CoursePackage?.DrivingHours ?? 0} Hours Driving, ${CoursePackage?.InClassHours ?? 0} Hours In Class, ${CoursePackage?.OnlineHours ?? 0} Hours Online`}
            </p>

            <TabsSection activeTab={activeTab} setActiveTab={setActiveTab} handleTabClick={scrollToSection} />

          </div>
          <div className=" flex w-full flex-col items-center bg-white px-5 py-10 pt-16">
            <div ref={clientRef} className="backColor1 my-5  w-full flex-col items-center">
              <ClientInfo Student={Student} StudentInfo={StudentInfo} />
              <LastLicense
                StudentInfoId={Student?.StudentInfo?.StudentInfoId}
                imageUrl={imageUrl}
                setLicenseFullView={setLicenseFullView}
                setLicenseUpdate={setLicenseUpdate}
              />
            </div>
            <div ref={instructorsRef} className="mb-5 w-full">
              <p className=" box-border  w-full border-4 pb-5 text-center text-xl font-black text-purpal">
                Assign an Instructor
              </p>
              <div className="backColor1 w-full">
                <CourseInstructors
                  ShowSlider={ShowSlider}
                  setShowSlider={setShowSlider}
                  Instructors={Instructors}
                  setOneInstructor={setOneInstructor}
                  OneInstructor={OneInstructor}
                  setStudentPrefrredTimeTable={setStudentPrefrredTimeTable}
                  timeSlots={courseSideTimeSlots}
                  DrivingHours={CoursePackage?.DrivingHours}
                  OnlineHours={CoursePackage?.OnlineHours}
                  InClassHours={ CoursePackage?.InClassHours}
                />
              </div>
            </div>

            {/* {Array.isArray(StudentPrefrredTimeTable) &&
              StudentPrefrredTimeTable.length > 1 && (
                <div className="w-full">
                  <p className=" box-border  w-full border-4  pb-5 text-center text-xl font-black text-purpal">
                    Instructor & Client Schedule
                  </p>

                  <div className="backColor1 w-full">
                     <SchedulerCompleted
            Events={StudentPrefrredTimeTable}
            setEvents={setStudentPrefrredTimeTable}
            /> 
                    <StudentScheduleTimeTable
                      Events={
                        lastFiveValues.length > 1
                          ? lastFiveValues
                          : StudentPrefrredTimeTable
                      }
                      setEvents={setStudentPrefrredTimeTable}
                    />
                  </div>
                </div>
              )} */}

            <div ref={timetableRef}  className="w-full ">
              <p className="box-border  w-full border-4  pb-5 text-center text-xl font-black text-purpal">
                Set Time Table
              </p>
              <div className="backColor1 w-full">
                <StudentTimeTable
                  timeTable={"assignSchedule"}
                  StudentInfo={Student}
                  Events={StudentPrefrredTimeTable}
                  // Events={[...StudentEnrollmentSelectedTimeTable, ...StudentPrefrredTimeTable]}
                  StudentEnrollmentSelectedTimeTable={StudentEnrollmentSelectedTimeTable}
                  InsEvent={InsEvent}
                  selectedDate={selectedDate}
                  setPrefrredTimeTable={setStudentPrefrredTimeTable}
                  setSelectedDate={setSelectedDate}
                  setEvents={setEvents}
                  Resources={[
                    { fieldExpr: "Instructors", dataSource: [...Instructors] },
                    { fieldExpr: "ClassType", dataSource: [...ClassType] },
                  ]}
                  Edit={true}
                  SubmitSchedule={() =>
                    SubmitData(
                      studentClassesScheduleAppointments,
                      Dispatch,
                      Instructors,
                      OneInstructor,
                      EnrollmentId,
                      Student,
                      setSuccess
                    )
                  }
                  CoursePackage={CoursePackage}
                  StudentName={Student?.FirstName + " " + Student?.LastName}
                />
              </div>
            </div>

            {ShowSlider?.Show ? (
              <InstructorSlider
                setInstructors={setInstructors}
                Instructors={Instructors}
                ShowSlider={ShowSlider}
                setShowSlider={setShowSlider}
                InstructorType={ShowSlider?.InstructorType}
                Events={Events}
                setEvents={setEvents}
                setPrefrredTimeTable={setStudentPrefrredTimeTable}
                PrefrredTimeTable={StudentPrefrredTimeTable}
                setInsEvent={setInsEvent}
                SubmitSchedule={() =>
                  SubmitData(studentClassesScheduleAppointments, Dispatch, Instructors, OneInstructor,setSuccess)
                }
              />
            ) : null}
          </div>
        </>
      ) : (
        <LoadingSpinner
          Bg="white"
          Width="full"
          Height="screen"
          Left="0"
          Position="fixed"
        />
      )}
    </>
  );
}

//expand recurring rule events;
function expandRecurringEvents(events) {
  let expandedEvents = [];

  events.forEach((event) => {
    if (event.recurrenceRule) {
      // Parse the recurrence rule
      let rule = rrulestr(`RRULE:${event.recurrenceRule}`, {
        dtstart: new Date(event.startDate),
      });

      // Generate occurrences
      let occurrences = rule.all();

      occurrences.forEach((date) => {
        let newEvent = { ...event };
        newEvent.startDate = new Date(date);
        let endDate = new Date(date);
        endDate.setMinutes(
          endDate.getMinutes() +
            (new Date(event.endDate) - new Date(event.startDate)) / 60000
        );
        newEvent.endDate = new Date(endDate);
        delete newEvent.recurrenceRule;
        expandedEvents.push(newEvent);
      });
    } else {
      expandedEvents.push(event);
    }
  });

  return expandedEvents;
}

const SubmitData = (
  Events,
  Dispatch,
  Instructors,
  OneInstructor,
  EnrollmentId,
  Student,
  setSuccess
) => {
  const InsId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;

  let AssignedInstructors = [];

  Instructors.forEach((element) => {
    // console.log("element",element)
    if (!OneInstructor && element?.InstructorFK)
      AssignedInstructors.push({
        InstructorFK: element && element?.InstructorFK,
        EnrollmentFK: EnrollmentId,
      });
    else if (
      OneInstructor &&
      element &&
      element.InstructorType === "Driving" &&
      element?.InstructorFK
    )
      AssignedInstructors.push({
        InstructorFK: element?.InstructorFK,
        EnrollmentFK: EnrollmentId,
      });
  });

  console.log("AssignedInstructors=>", AssignedInstructors);

  const UserEmail = Student?.Email;
  const UserFirstName = Student?.FirstName;
  const UserLastName = Student?.LastName;

  Events = Events.map((value) => {
    value.EnrollmentFK = EnrollmentId;
    return value;
  });

  let expandedEvents = expandRecurringEvents(Events);

  console.log("Expanded Events", expandedEvents);
  console.log("Events2", Events);

  Dispatch(
    CreateScheduleA({
      Events: expandedEvents,
      AssignedInstructors,
      UserEmail: UserEmail,
      InsId: InsId,
      UserFirstName: UserFirstName,
      UserLastName: UserLastName,
      EnrollmentId: EnrollmentId,
      setSuccess
    })
  );

  alert("Schedule created successfully!");

};

const ManageNewStudent = () => (
  <InstituteTemplate
    Element={ManageNewStudentChild}
    m_top="mt-0"
    bg="bg-[#F0F0F7]"
  />
);

export default ManageNewStudent;
