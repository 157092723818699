import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneRed from "./Assets/Accordion911Icon.svg";
import PhoneWhite from "./Assets/Accordion911IconWhite.svg";
import HarassmentIconRed from "./Assets/AccordionHarassmentRed.svg";
import HarassmentIconWhite from "./Assets/AccordionHarassmentWhite.svg";
import ShareLocationBeaconRed from "./Assets/AccordionBeaconRed.svg"
import ShareLocationBeaconWhite from "./Assets/AccordionBeaconWhite.svg"
import DrivingSchoolRed from "./Assets/AccordionDrivingRed.svg"
import DrivingSchoolWhite from "./Assets/AccordionDrivingWhite.svg"
import AlertTriangleRed from "./Assets/AccordionAlertRed.svg"
import AlertTriangleWhite from "./Assets/AccordionAlertWhite.svg"
import "./SOS.css";

function SOSDetailsAccordion() {
  // Separate expanded state for main and nested accordions
  const [expandedMain, setExpandedMain] = useState(false);
  const [expandedNested, setExpandedNested] = useState(null);

  // Handle change for main accordion
  const handleMainChange = (event, isExpanded) => {
    setExpandedMain(isExpanded);
  };

  // Handle change for nested accordions
  const handleNestedChange = (panel) => (event, isExpanded) => {
    setExpandedNested(isExpanded ? panel : null);
    console.log(`Expanded Panel: ${isExpanded ? panel : "None"}`);
  };

  // Data for the nested accordions (titles and descriptions)
  const nestedAccordionData = [
    {
      id: 1,
      title: "Call 911 Emergency",
      description: "911 number will be dailled in your device.",
      redIcon: PhoneRed,
      whiteIcon: PhoneWhite,
    },
    {
      id: 2,
      title: "Send Emergency alert to beacon",
      description: "Your Live location link and an emergency message will be sent to the beacon.",
      redIcon: ShareLocationBeaconRed,
      whiteIcon: ShareLocationBeaconWhite,
    },
    {
      id: 3,
      title: "Call Driving School",
      description: "The Institute phone number will be dialed in your device.",
      redIcon: DrivingSchoolRed,
      whiteIcon: DrivingSchoolWhite,
    },
    {
      id: 4,
      title: "Report Harassment",
      description: "Your emergency contact and driving school admin will be alerted.",
      redIcon: HarassmentIconRed,
      whiteIcon: HarassmentIconWhite,
    },
    {
      id: 5,
      title: "Share Live Location Link",
      description: "Your emergency contact and driving school admin will be alerted.",
      redIcon: AlertTriangleRed,
      whiteIcon: AlertTriangleWhite,
    },
  ];

  return (
    <div className="w-full flex justify-center items-center">
      {/* Main Accordion */}
      <Accordion
        expanded={expandedMain}
        onChange={handleMainChange}
        className={expandedNested ? "bg-blue-500" : ""} // Conditionally apply the blue background
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#000000" }} />}
          aria-controls="main-content"
          id="main-header"
          className="shadow-[0px_0px_15px_#00000036] rounded-[15px] h-[38px] !min-h-[38px] outline-none border-none focus:outline-none"
        >
          <div className="w-full text-[13px] font-bold text-center">
            {expandedMain ? "Hide Details for Each Action" : "Show Details for Each Action"}
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {/* Nested Accordions */}
          {nestedAccordionData.map((item) => (
            <Accordion
              key={item.id}
              expanded={expandedNested === `nested-${item.id}`}
              onChange={handleNestedChange(`nested-${item.id}`)}
              className={`rounded-[15px] ${
                expandedNested === `nested-${item.id}` ? "LinearGradientAccordion text-[#FFFFFF]" : "bg-white text-[#000000]"
              } `}
            >
              <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{
                color: expandedNested === `nested-${item.id}` ? "#FFFFFF" : "#000000",
              }} />}
                aria-controls={`nested-${item.id}-content`}
                id={`nested-${item.id}-header`}
                className="rounded-[15px] h-[38px] !min-h-[38px] outline-none border-none focus:outline-none"
              >
                <div className="w-full text-[13px] font-bold text-center flex items-center gap-[15px]">
                  <img
                    src={
                      expandedNested === `nested-${item.id}` ? item.whiteIcon : item.redIcon
                    }
                    alt={item.title}
                  />
                  {item.title}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-full text-[13px] text-center">
                  {item.description}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SOSDetailsAccordion;
