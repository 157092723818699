import React, { useState, useEffect } from "react";
import WhiteVehicle from "./Assets/whitevehicle.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BaseUrl } from "../../Actions/Base";

function InstructorInfoDistanceTime({setMinimizeInstructorDetails, minimizeInstructorDetails,
    totalDistance, remainingDistance, expectedArrival, InstructorNameAndOthers,
    data}) {
    console.log("totalDistance for distance is:",totalDistance)
    console.log("remainingDistance for distance is:",remainingDistance)
    console.log("expectedArrival for distance is:",expectedArrival)
    const [ImgUrl, setImgUrl] = useState(null); // State to store the image URL
    const [currentDisplay, setCurrentDisplay] = useState({
        heading: "Left Location",
        time: "00:00",
      });

    useEffect(() => {
        // Determine the current heading and time based on the data received
        if(data){
        if (data?.data?.CompletedInvehicleClassTime) {
          setCurrentDisplay({
            heading: "Completed Class",
            time: data?.data?.CompletedInvehicleClassTime,
          });
        } else if (data?.data?.PickupStudentTime) {
          setCurrentDisplay({
            heading: "Picked Student",
            time: data.data.PickupStudentTime,
          });
        } else if (data?.data?.ReachedLocationTime) {
          setCurrentDisplay({
            heading: "Reached Location",
            time: data.data.ReachedLocationTime,
          });
        } else if (data?.data?.LeftLocationTime) {
          setCurrentDisplay({
            heading: "Left Location",
            time: data.data.LeftLocationTime,
          });
        } else {
          setCurrentDisplay({
            heading: "Left Location",
            time: "00:00",
          });
        }
    }
      }, [data]);


    useEffect(() => {
        if (InstructorNameAndOthers?.ProfileImage) {
            const instructorProfile = InstructorNameAndOthers?.ProfileImage;
            console.log("instructorProfile is:",instructorProfile)
            const imgUrl = `${BaseUrl}/api/images/Instructors?url=${instructorProfile}`;
            console.log("imgUrl is:",imgUrl)
            setImgUrl(imgUrl); // Update state with the URL
        }
    }, [InstructorNameAndOthers]);

    const handleMinimizeClick = () =>{
        setMinimizeInstructorDetails(!minimizeInstructorDetails)
      }

  return (
    <div className="w-full fixed lg:hidden p-[20px]  bottom-0 left-0 z-[999]">
        <div className="w-[330px] p-[10px] rounded-[11px] bg-[#A1A3EF] bg-opacity-60 backdrop-blur-[6px] backdrop-brightness-50 relative" >
            <div className="absolute right-[10px] top-[5px]">
                    <button className="bg-[#FFFFFF] rounded-[5px] text-[#4C34AD] text-[10px] h-[20px] w-[65px]"
                     onClick={handleMinimizeClick} >
                        {!minimizeInstructorDetails ? <KeyboardArrowDownIcon sx={{ color: '#4C34AD', fontSize: 20 }} /> :  <KeyboardArrowUpIcon sx={{ color: '#4C34AD', fontSize: 20 }} /> }
                        {minimizeInstructorDetails ? "Maximize" : "Minimize"}
                    </button>
            </div>
            
            {!minimizeInstructorDetails && (
                <div className="flex gap-[20px]">
                    <div className="w-[90px] h-[80px] flex items-center justify-center overflow-hidden rounded-[19px] relative">
                         <img
                            className="absolute w-full rounded-[19px]"
                            src={ImgUrl}
                            alt={"instructorimg"}
                        />  
                    </div>
                    <div className="flex- flex-col">
                        <p className="text-[16px] text-[#FFFFFF] font-bold">{InstructorNameAndOthers?.FirstName} {InstructorNameAndOthers?.LastName}</p>
                        <p className="text-[16px] text-[#FFFFFF] w-[180px] mt-[15px]">Special License of Class  {InstructorNameAndOthers?.RegularLicenses.join(", ")},{InstructorNameAndOthers?.SpecialLicenses.join(", ")} Licensing.</p>
                    </div>
                </div>
            )}

            <div className="flex justify-between items-center gap-[15px] mt-[15px] px-[5px]">
                <div className="flex flex-col justify-center items-center gap-[10px] bg-white px-[5px] w-[75px] h-[71px] rounded-[12px]">
                    <p className="text-[12px] text-[#A1A3EF] text-center">{currentDisplay.heading}</p>
                    <p className="text-[12px] text-[#A1A3EF] font-bold text-center">{currentDisplay.time}</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] text-[#FFFFFF] text-center">Total Distance</p>
                    <p className="text-[12px] text-[#FFFFFF] font-bold text-center">{totalDistance}</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] text-[#FFFFFF] text-center">Remaining Distance</p>
                    <p className="text-[12px] text-[#FFFFFF] font-bold text-center">{remainingDistance}</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] text-[#FFFFFF] text-center">Expected Arrival</p>
                    <p className="text-[12px] text-[#FFFFFF] font-bold text-center">{expectedArrival}</p>
                </div>
            </div>

            <div className="w-full flex items-center mt-[10px] px-[5px]">
                <p className="text-[10px] text-[#FFFFFF] w-[20px] text-center">Left Location</p>
                <div className="h-[5px] w-[220px] bg-white rounded-[3px] mx-[5px] relative ml-[20px]" >
                    <img src={WhiteVehicle} alt="phone" className="absolute left-[100px] top-[-12px]" />
                </div>
                <p className="text-[10px] text-[#FFFFFF] w-[20px] text-center">Pickup Zone</p>
            </div>
        </div>   
    </div>
  );
}

export default InstructorInfoDistanceTime;
