import React, {useState, useEffect} from "react";
import axios from "axios";
import { BaseUrl } from "../../../Actions/Base";

const ResetPasswordSettingsPopup = ({setResetPasswordShowPopup, userEmail, setOtpVerificationShowPopup }) => {
  const Border = "border-[#E2E2E2] border-[1px] border-solid";

  const closePopup = () =>{
      console.log("Close icon clicked");
      setResetPasswordShowPopup(false);
  }

  //   const OpenOtpPopup = () =>{
  //     setOtpVerificationShowPopup(true);
  //     setResetPasswordShowPopup(false);
  // }

    const axiosInstance = axios.create({
      baseURL: BaseUrl,
      withCredentials: true,
    });

    const OpenOtpPopup = async () => {

      const changePassEmail = userEmail;
        const payload = {
          Email: changePassEmail,
        };
      try {
        const res = await axiosInstance.post("/api/forgot-password", payload);
        console.log(res);
        if (res?.data?.success === true) {
          
          localStorage.setItem(
            "token",
            JSON.stringify(res?.data?.ResetPasswordToken)
          );
          setOtpVerificationShowPopup(true);
          setResetPasswordShowPopup(false);
        } else if (res?.data?.success === false) {
        }

      } catch (err) {
        console.log(err);
      }
    };
    
    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative rounded-[9px] bg-white p-[20px] shadow-lg w-[370px]">
          <h2
            className=" mb-2 text-center text-[18px]"
          >
            Reset Password
          </h2>
          <p className="text-center text-[14px]">An OTP verification will be sent to your E-mail. Once verified you will be able to change your Password</p>
          <div className="flex flex-col gap-[5px] mt-[10px] mr-[15px] ml-[17px]">
                <label
                    htmlFor="Email"
                    className="text-[#404040] text-[14px]"
                >
                    Your E-mail
                </label>
                <input
                    type="text"
                    id="Email"
                    name="Email"
                    readOnly
                    value={userEmail}
                    className={`w-full rounded-md py-2  px-[10px] ${Border} text-bold shadow-[4px_5px_6px_#00000029] `}
                />
            </div>
          <div className="w-full flex justify-center items-center gap-[15px] mt-[15px]">
            <button
                className="py-[7px] w-[124px] rounded-[6px] text-[#A1A3EF] text-[14px] cursor-pointer"
                style={{border:"2px solid #A1A3EF"}}
                onClick={closePopup}>
               Back
            </button>
            <button
                className="px-[15px] py-[7px] rounded-[6px] bg-[#A1A3EF] text-[#FFFFFF] text-[14px] cursor-pointer"
                onClick={OpenOtpPopup}
                style={{border:"2px solid #A1A3EF"}}>
                Reset Password
            </button>
          </div>
        </div>
      </div>
    );
  };

export default ResetPasswordSettingsPopup;
