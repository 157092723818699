import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { BaseUrl } from "../../Actions/Base";
import { useDispatch, useSelector } from "react-redux";
import { GetLiveLocationStudentCoordinates } from "../../Actions/InstructorA";
import { GetStartRecordingFieldFromUsers } from "../../Actions/UserA";

const google = window.google;

const TrackStudentLiveLocation = () => {
  const params = useParams();
  const UserId = params.UserId; // Event ID passed as a parameter
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const Dispatch = useDispatch();


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCWiF-rhJC5vG-_6akKZbSiJ1AIRxJe_mI", // Replace with your actual API key
  });

  const { LiveLocation } = useSelector(
    (Store) => Store.InstructorReducer
  );

  console.log("LiveLocation is:",LiveLocation)

  // Fetch location every 10 seconds
  useEffect(() => {
    if (UserId) {
      const intervalId = setInterval(() => {
        Dispatch(GetLiveLocationStudentCoordinates(UserId));
      }, 10000); // 10 seconds

      return () => clearInterval(intervalId); // Clean up interval on unmount
    }
  }, [Dispatch, UserId]);

  //Update latitude and longitude from LiveLocation
  useEffect(() => {
    if (LiveLocation) {
      setLatitude(LiveLocation.latitude);
      setLongitude(LiveLocation.longitude);
    }
  }, [LiveLocation]);

  useEffect(() => {
    if (isLoaded && latitude && longitude && map) {
      console.log("Setting marker...");

      // Remove existing marker if any
      if (marker) {
        marker.setMap(null);
      }

      // Create a new marker
      const newMarker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        title: "Location Marker",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        },
      });

      // Save the marker instance in state
      setMarker(newMarker);

      // Center the map to the new location
      map.setCenter({ lat: latitude, lng: longitude });
    }
  }, [isLoaded, latitude, longitude, map]);


  return (
    <>
      {!isLoaded ? (
        <p>Map is loading...</p>
      ) : (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100vh" }}
          center={{ lat: latitude, lng: longitude }}
          zoom={12}
          onLoad={(mapInstance) => {
            console.log("Map loaded:", mapInstance);
            setMap(mapInstance);
          }}
        ></GoogleMap>
      )}
    </>
  );
};

export default TrackStudentLiveLocation;
