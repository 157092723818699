import React, {useState, useEffect} from "react";

const CreateNewPasswordSettingsPopup = ({userEmail, setCreateNewPasswordShowPopup, setPasswordResetSuccessShowPopup, UserId }) => {
  const Border = "border-[#E2E2E2] border-[1px] border-solid";
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bothPasswordError, setBothPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const isPasswordValid = (password) => password.length >= 8;

    const matchBothPasswords = async () => {
      // Validate new password and confirm password match
      setBothPasswordError("")
      if (newPassword !== confirmPassword) {
        setBothPasswordError("Passwords do not match.");
        return;
      }
    
      if (!isPasswordValid(newPassword)) {
        setBothPasswordError("Password must be at least 8 characters.");
        return;
      }
    };

    const closePopup = () =>{
        console.log("Close icon clicked");
        setCreateNewPasswordShowPopup(false);
    }

    const OpenCreatePasswordPopup = () =>{
        setPasswordResetSuccessShowPopup(true)
        setCreateNewPasswordShowPopup(false);
    }

    const handleChangePassword = async () => {
      // Reset messages
      setErrorMessage("");
      setSuccessMessage("");
  
      try {
        // Send data to backend
        const response = await fetch("/api/settings/change-password", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newPassword, UserId, userEmail }),
        });
  
        const result = await response.json();
  
        if (response.ok) {
          setPasswordResetSuccessShowPopup(true)
          setCreateNewPasswordShowPopup(false);
        } else {
          setErrorMessage(result.message || "An error occurred.");
        }
      } catch (error) {
        setErrorMessage("An error occurred. Please try again.");
      }
    };

    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative rounded-[9px] bg-white p-[20px] shadow-lg w-[380px]">
          <h2
            className=" mb-2 text-center text-[18px]"
          >
            Create New Password
          </h2>
          <p className="text-center text-[14px]">Enter a new password below to change your password <span className="text-bold">{userEmail}</span></p>
          <div className="w-full flex flex-col gap-[10px] mt-[10px] mr-[15px] ml-[17px]">
                <div className="flex justify-between">
                    <label
                        htmlFor="New Password"
                        className="text-[#404040] text-[16px]"
                    >
                        New Password
                    </label>
                    {bothPasswordError && <p className="text-[#ff0000] text-[16px]">{bothPasswordError}</p>}
                </div>
                <input
                    type="password"
                    id="New Password"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={`w-[90%] rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                />
         </div>
         <div className="w-full flex flex-col gap-[10px] mt-[10px] mr-[15px] ml-[17px]">
                <label
                    htmlFor="New Password"
                    className="text-[#404040] text-[16px]"
                >
                    Re-enter New Password
                </label>
                <input
                    type="password"
                    id="New Password"
                    placeholder="Re-enter New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={matchBothPasswords} // Validate on blur
                    className={`w-[90%] rounded-md py-2  px-[10px] ${Border} shadow-[4px_5px_6px_#00000029] `}
                />
         </div>
          <div className="w-full flex justify-center items-center gap-[15px] mt-[20px]">
            <button
                className="py-[7px] w-[100px] rounded-[6px] text-[#A1A3EF] text-[14px] cursor-pointer"
                style={{border:"2px solid #A1A3EF"}}
                onClick={closePopup}>
               Cancel
            </button>
            <button
                className="px-[15px] py-[7px] rounded-[6px] bg-[#A1A3EF] text-[#FFFFFF] text-[14px] cursor-pointer"
                onClick={handleChangePassword}
                style={{border:"2px solid #A1A3EF"}}>
               Reset Password
            </button>
          </div>
        </div>
      </div>
    );
  };

export default CreateNewPasswordSettingsPopup;
