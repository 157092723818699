import React,{useState} from 'react';
import {  SuspendTheSelectedUser, UnSuspendTheSelectedUser } from "../../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";

const SuspendAndResumePopup = ({setIsOpenSuspendAndResumePopup, ResumeAndSuspendData}) => {
    const { firstName, lastName, role, editLinkID, multipleUserType, idCardNo, PopupType, Image, text } = ResumeAndSuspendData;
    const [loadingSuspendFunction, setLoadingSuspendFunction] = useState(false);

    const Dispatch = useDispatch();

    const handleButtonClick = () =>{
        if(PopupType==="Resume"){
            handleResume();
        } else if(PopupType==="Suspend"){
            handleSuspend()
        }
    }

    const handleSuspend = () => {
        // Track the dispatch status (e.g., using a loading state)
        setLoadingSuspendFunction(true); // Setting loading to true when dispatch starts
        Dispatch(SuspendTheSelectedUser(role, editLinkID, multipleUserType, idCardNo))
            .then(() => {
                // After dispatch completes, refresh the page
                setLoadingSuspendFunction(false); // Set loading to false once done
                window.location.reload(); // Refresh the page
            })
            .catch(error => {
                console.error('Error during suspend:', error);
                setLoadingSuspendFunction(false); // Set loading to false even if there's an error
            });
    };
  
    const handleResume = () => {
        // Track the dispatch status (e.g., using a loading state)
        setLoadingSuspendFunction(true); // Setting loading to true when dispatch starts
        Dispatch(UnSuspendTheSelectedUser(role, editLinkID, multipleUserType, idCardNo))
            .then(() => {
                // After dispatch completes, refresh the page
                setLoadingSuspendFunction(false); // Set loading to false once done
                window.location.reload(); // Refresh the page
            })
            .catch(error => {
                console.error('Error during suspend:', error);
                setLoadingSuspendFunction(false); // Set loading to false even if there's an error
            });
    };

    return (
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
        <div className="relative w-[394px] rounded-[23px] bg-white p-8 shadow-lg">
          <div className="absolute top-2 right-2">
            <img
              className="absolute right-3 top-[6px] cursor-pointer"
              src={require("./Assets/Cross.svg").default}
              alt=""
              onClick={() => setIsOpenSuspendAndResumePopup(false)}
            />
          </div>
          <div className='w-full'>
                <div className='flex flex-col justify-center items-center gap-[15px]'>
                    <img src={Image} alt="Image" />
                    <p className='text-[16px] font-bold'>{PopupType} User?</p>
                    <p className='text-[16px] w-[300px] text-center'>Are you sure you want to {PopupType} {firstName}? {text}</p>
                    <button
                        onClick={() => {
                           handleButtonClick()                
                         }}
                        className=" cursor-pointer rounded-[7px] bg-[#B5AAFF] w-[308px] h-[48px] text-[#FFFFFF]  text-[16px]"
                    >
                        {PopupType}
                    </button>
                    <button
                     onClick={() => setIsOpenSuspendAndResumePopup(false)}
                     className="text-[16px]"
                    >
                        Cancel
                    </button>
                </div>
          </div>
        </div>
      </div>
    );
  };

  export default SuspendAndResumePopup;
