import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LogOut } from "../../../../../../../Actions/UserA";
import {
    auth,
    provider,
} from "../../../../../../../Pages/LoginAndRegister/Components/UserSigning/Components/LeftSide/ContinueWIth/config";
import { signOut } from "firebase/auth";
import { SocketContext } from "../../../../../../../App";

const DropDownOptions = ({ HideOnClick }) => {
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);

    const socket = useContext(SocketContext);
    let LinkStyle =
        "text-[#4D4F5C] whitespace-nowrap text-3xs no-underline SemiBold py-4 px-6 w-56 text-left hover:text-[#A1A3EF] DropDownBorder";
    const Dispatch = useDispatch();
    const ClickMethod = (e, cb) => {
        HideOnClick(e, cb);
    };
    const LogOutUser = () => {
        signOut(auth, provider)
            .then(() => {
                console.log("Sign-out successful.2");
                socket?.emit("offline", { UserId: UserInfo?.UserId });
            })
            .catch((error) => {
                // An error happened.
            });
        Dispatch(LogOut());
    };

    return (
        <div className="z-20 hidden lg:flex w-fit flex-col items-start bg-white">
            <Link
                to="/"
                className={LinkStyle}
                onClick={(e) => ClickMethod(e, null)}
            >
                Profile
            </Link>

            <Link
                to="/mycourses/enrolledcourses"
                className={`${LinkStyle} flex w-full justify-between gap-2`}
                onClick={(e) => ClickMethod(e, null)}
            >
                My Courses
                <img
                    src={require("../../../Assets/MyCoursesIcon.svg").default}
                    alt=""
                />
            </Link>

            <Link
                className={`${LinkStyle} flex w-full justify-between gap-2`}
                onClick={(e) => ClickMethod(e, null)}
            >
                Help & Support
                <img
                    src={require("../../../Assets/HelpIcon.svg").default}
                    alt=""
                />
            </Link>

            <Link
                className={`${LinkStyle} flex w-full justify-between gap-2`}
                onClick={(e) => ClickMethod(e, null)}
            >
                English
                <img
                    src={require("../../../Assets/TranslationIcon.svg").default}
                    alt=""
                />
            </Link>

            <Link
                className={`${LinkStyle} flex w-full justify-between gap-2`}
                onClick={(e) => ClickMethod(e, null)}
            >
                USD
                <img
                    src={require("../../../Assets/DollarIcon.svg").default}
                    alt=""
                />
            </Link>

            <Link
            to="/student/settings"
            className={LinkStyle}
            onClick={(e) => ClickMethod(e, null)}>
                Settings
            </Link>

            <Link
                className={LinkStyle}
                onClick={(e) => ClickMethod(e, LogOutUser)}
            >
                Logout
            </Link>
        </div>
    );
};

export default DropDownOptions;
