import React, { useEffect, useRef, useState } from "react";
import { InputWithImage } from "../../../../../../../Components/InputComps/InputComps";
import { CheckString } from "../../../../../../../Helpers/TypeChecker/CheckString";
import { Country, State } from "country-state-city";


let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === "loaded" ||
                script.readyState === "complete"
            ) {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

const SettingSearchLocationByInputStudent = ({
  setErr,
  setInstructorData,
  InstructorData,
  Err,
  readOnly,
  updateOnly,
  setSelectedCoordinates,
  EnrollmentData,
  newCountryPostalCode,
  Id,
  addressType, // New prop to identify which address to update
}) => {
  const [query, setQuery] = useState(InstructorData?.Address || "");
  const autoCompleteRef = useRef(null);
  const autoComplete = useRef(null);

  const getCountryCode = (countryName) => {
    const selectedCountry = Country.getAllCountries().find(
      (country) => country.name.toLowerCase() === countryName?.toLowerCase()
    );
    return selectedCountry ? selectedCountry.isoCode.toLowerCase() : "";
  };

  useEffect(() => {
    if (InstructorData?.Address && InstructorData.Address !== query) {
      setQuery(InstructorData.Address);
    }
  }, [InstructorData?.Address]);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    const country = EnrollmentData?.StudentData?.Country || newCountryPostalCode || "";
    const countryCode = getCountryCode(country);

    const componentRestrictions = countryCode ? { country: countryCode } : {};

    autoComplete.current = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions,
    });

    autoComplete.current.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.current.getPlace();

    if (!addressObject || !addressObject.formatted_address) {
      setErr({ Address: "No valid address found" });
      setInstructorData({ Address: "" });
      return;
    }

    const selectedAddress = addressObject.formatted_address;
    updateQuery(selectedAddress);

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    setSelectedCoordinates(latLng);
    setInstructorData((prevData) => ({
      ...prevData,
      [addressType]: selectedAddress,
    }));
  };

  const apiKey = "AIzaSyCWiF-rhJC5vG-_6akKZbSiJ1AIRxJe_mI";
  useEffect(() => {
    const loadScript = (url, callback) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [EnrollmentData?.StudentData?.Country, newCountryPostalCode]);

  const handleQueryChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setErr(null); // Clear error on manual input
    setInstructorData((prevData) => ({
      ...prevData,
      [addressType]: value,
    }));
  };

  return (
    <div className="w-full flex flex-col">
      <span className="flex w-full gap-4 rounded-md border-[1px] border-solid border-[#E2E2E2] bg-white py-2 px-4 shadow-[4px_5px_6px_#00000029]">
        <img src={require("../../Assets/Location.svg").default} alt="Location" className="w-[15px] h-[20px]" />
        <input
          ref={autoCompleteRef}
          id={Id}
          type="text"
          disabled={readOnly}
          placeholder="House no. street name city province..."
          className="w-full border-none shadow-none outline-none"
          value={query}
          onChange={handleQueryChange}
        />
      </span>
    </div>
  );
};

export default SettingSearchLocationByInputStudent;

