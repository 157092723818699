import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import {
    DeleteLocalStorage,
    GetLocalStorage,
    SetLocalStorage,
} from "../Helpers/LocalStorage/LocalStorage";
import { BaseUrl } from "./Base";
import { AgainOpenLoginSignUp, DoneLoginSignUp } from "./ToggleSignupA";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

let CheckErrorVar;
function CheckError(error) {
    CheckErrorVar = error ? true : false
}
function navigateWithPromise(id, Navigate, Dispatch) {
    return new Promise((resolve) => {
        Dispatch(DoneLoginSignUp(false));
        resolve();
    })
        .then(() => {
            Navigate(`/Enrollment/Course`, {
                state: { InstituteCourseId: id },
            });
            setTimeout(() => {
                DeleteLocalStorage("navigateToEnroll");
            }, 3000);
        })

        .catch((error) => {
            console.error("Error:", error);
        });
}

export const LoginUser =
    (UserData, Dispatch, PageName, setCaptcha, Navigate) =>
    async (dispatch) => {
        try {
            dispatch({
                type: "LoginRequest",
            });

            const { data } = await axiosInstance.post(`/api/login`, UserData);
            console.log("studentusereinfo", data);
            if (data?.User?.StudentInfos?.Schedule)
                data.User.StudentInfos.Schedule = JSON.parse(
                    data.User.StudentInfos.Schedule
                );

            if (data?.User?.StudentInfos?.FreeHours)
                data.User.StudentInfos.FreeHours = JSON.parse(
                    data.User.StudentInfos.FreeHours
                );

            dispatch({
                type: "LoginSuccess",
                payload: data.User,
                Auth: true,
            });
            dispatch({
                type: "GetNotificationsSuccess",
                payload: data.Notifications,
            });
            SetLocalStorage("UserInfo", {
                ...data.User,
                Notifications: data.Notifications,
            });
            setCaptcha(null);
            dispatch({
                type: "LoginError",
                payload: {},
            });
            if (GetLocalStorage("navigateToEnroll")) {
               await navigateWithPromise(
                    GetLocalStorage("navigateToEnroll"),
                    Navigate,
                    Dispatch
                );
            } else {
               await Dispatch(DoneLoginSignUp(false));
            }
        } catch (error) {
            Dispatch(AgainOpenLoginSignUp(PageName));
            dispatch({
                type: "LoginError",
                payload: error?.response?.data ? error?.response?.data : {},
            });
            setCaptcha(null);
        }
    };
export const GoogleReCapthcaLoading = (PageName) => (dispatch) => {
    try {
        if (PageName === "Sign Up") dispatch({ type: "SignUpRequest" });
        else if (PageName === "Sign In") dispatch({ type: "LoginRequest" });
    } catch (error) {}
};
export const SignUpWithGoogleAction =
    (SignUpInfo, Dispatch, PageName) => async (dispatch) => {
        try {
            dispatch({
                type: "SignUpRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/signup/Google`,
                SignUpInfo
            );

            dispatch({
                type: "SignUpSuccess",
                payload: data?.User,
            });
            dispatch({
                type: "GetNotificationsSuccess",
                payload: data.Notifications,
            });

            SetLocalStorage("UserInfo", {
                ...data.User,
                Notifications: data.Notifications,
            });
            Dispatch(DoneLoginSignUp(false));
            dispatch({
                type: "SignUpFailure",
                payload: {},
            });
        } catch (error) {
            Dispatch(AgainOpenLoginSignUp(PageName));
            dispatch({
                type: "SignUpFailure",
                payload: error?.response?.data ? error?.response?.data : {},
            });
        }
    };
export const LoginWithGoogleAction =
    (LoginInfo, Dispatch, PageName) => async (dispatch) => {
        try {
            dispatch({
                type: "LoginRequest",
            });
            const res = await axiosInstance.post(
                `/api/login/Google`,
                LoginInfo
            );

            const { data } = res;

            dispatch({
                type: "LoginSuccess",
                payload: data.User,
                Auth: true,
            });
            dispatch({
                type: "GetNotificationsSuccess",
                payload: data.Notifications,
            });
            SetLocalStorage("UserInfo", {
                ...data.User,
                Notifications: data.Notifications,
            });
            Dispatch(DoneLoginSignUp(false));
        } catch (error) {
            Dispatch(AgainOpenLoginSignUp(PageName));
            dispatch({
                type: "LoginFailure",
                payload: error,
            });
        }
    };

export const SignUpUser =
    (UserData, Dispatch, PageName, setCaptcha, setOtpPopup, setResync, VerifyPopup) =>
    async (dispatch) => {
  

        try {
            dispatch({
                type: "SignUpRequest",
            });
            const { data } = await axiosInstance.post(`/api/signup`, UserData);

            console.log("data=> ", data)
            dispatch({
                type: "SignUpSuccess",
                payload: data,
               
                // Auth: true,
            });
            
            // dispatch({
            //     type: "GetNotificationsSuccess",
            //     payload: data.Notifications,
            // });

            SetLocalStorage("Usertoken", {
               email: data?.User?.Email,
               token : data?.User?.ResetPasswordToken,
               expire: data?.User?.ResetPasswordExpire
            });
            setCaptcha(null);
           /*  setOtpPopup(true) */


            // Dispatch(DoneLoginSignUp(false));
            // setResync((prev) => !prev);
        } catch (error) {

            Dispatch(AgainOpenLoginSignUp(PageName));
            dispatch({
                type: "SignUpError",
                payload: error?.response?.data ? error?.response?.data : {},
              
            });
            setCaptcha(null);
            CheckError(error)
        }
        if(!CheckErrorVar) VerifyPopup() 

        
    };

    export const verifyOtp =(payload)=> async(dispatch)=> {
        try {
          dispatch({
            type: "verifyOtpRequest"
          });
          const {data} = await axiosInstance.post("/api/verify-registeredEmail", payload);

          dispatch({
            type: "verifyOtpSuccess",
            payload: data,
            Auth: true
          })

          dispatch({
            type: "GetNotificationsSuccess",
            payload: data.Notifications,
        });

        SetLocalStorage("UserInfo", {
                ...data.User,
                Notifications: data.Notifications,
            });

        dispatch(DoneLoginSignUp(false));

        return data

        }catch(error){
            throw error
        }
    }

export const RegisterInstituteA = (UserData, cb) => async (dispatch) => {
    try {
        dispatch({
            type: "RegisterInstituteRequest",
        });

        const { data } = await axiosInstance.post(
            `/api/Institute/Register`,
            UserData
        );
        dispatch({
            type: "RegisterInstituteSuccess",
            payload: data,
            Auth: true,
        });
        dispatch({
            type: "GetNotificationsSuccess",
            payload: data.Notifications,
        });
        SetLocalStorage("UserInfo", {
            ...data.User,
            Notifications: data.Notifications,
        });

        cb();
    } catch (error) {
        dispatch({
            type: "RegisterInstituteError",
            payload: error?.response?.data ? error?.response?.data : {},
        });
    }
};

export const SetUser = (UserInfo) => (dispatch) => {
    try {
        dispatch({
            type: "LoginRequest",
        });

        if (UserInfo)
            dispatch({
                type: "LoginSuccess",
                payload: UserInfo,
                Auth: true,
            });
        else
            dispatch({
                type: "LoginSuccess",
                payload: undefined,
                Auth: false,
            });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "LoginError",
            payload: error,
        });
    }
};

export const LogOut = () => async (dispatch) => {
    try {
        dispatch({
            type: "LoginRequest",
        });

        const { data } = await axiosInstance.get(`/api/logout`);
        console.log("data in logout", data);
        DeleteLocalStorage("UserInfo");

        dispatch({
            type: "LoginSuccess",
            payload: undefined,
            Auth: false,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "LoginError",
            payload: error,
        });
    }
};

export const GetWishList = () => async (dispatch) => {
    try {
        dispatch({
            type: "GetWishListRequest",
        });

        const { data } = await axiosInstance.get(`/api/wishlist`);

        dispatch({
            type: "GetWishListSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "GetWishListError",
            payload: error,
        });
    }
};

export const RemoveFromWishList = (WishId) => async (dispatch) => {
    try {
        dispatch({
            type: "RemoveWishRequest",
        });

        const { data } = await axiosInstance.delete(`/api/wishlist`, {
            data: { WishId },
        });

        dispatch({
            type: "RemoveWishSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "RemoveWishError",
            payload: error,
        });
    }
};

export const AddtoWishList = (InstitutetCourseId) => async (dispatch) => {
    try {
        dispatch({
            type: "AddToWishListRequest",
        });

        const { data } = await axiosInstance.post(`/api/wishlist`, {
            InstitutetCourseId,
        });

        dispatch({
            type: "AddToWishListSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "AddToWishListError",
            payload: error,
        });
    }
};

export const GetPickedStudentFieldFromUsers = (UserId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetPickedStudentFromUsersRequest",
        });

        const { data } = await axiosInstance.post('/api/getpickedstudentfield', {
            UserId,
        });

        dispatch({
            type: "GetPickedStudentFromUsersSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "GetPickedStudentFromUsersError",
            payload: error,
        });
    }
};

export const GetStartRecordingFieldFromUsers = (UserId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetStartRecordingFromUsersRequest",
        });

        const { data } = await axiosInstance.post('/api/getstartrecordingfield', {
            UserId,
        });

        dispatch({
            type: "GetStartRecordingFromUsersSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error, dispatch);
        dispatch({
            type: "GetStartRecordingFromUsersError",
            payload: error,
        });
    }
};
