import { useState, useEffect } from "react";
import { notificationConfig } from "./NotificationConfig";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentNotificationData, UpdateStudentPersonalNotificationData } from "../../../Actions/StudentA";

const PersonalNotifications = ({ userInfo, UserId }) => {
  const Dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const { PersonalNotificationData } = useSelector((Store) => Store.StudentReducer);
  console.log("PersonalNotificationData is:",PersonalNotificationData)

  useEffect(() => {
   if(UserId){
    Dispatch(GetStudentNotificationData(UserId))
   }
  }, [UserId, Dispatch]);

  // Map backend data to `notifications` state
  useEffect(() => {
    if (PersonalNotificationData?.data?.length > 0) {
      const backendData = PersonalNotificationData.data[0]; // Assuming the first element contains the data
      const mappedNotifications = [
        {
          type: "Inbox Message",
          emailChecked: backendData.InboxMessagesEmailChecked,
          webChecked: backendData.InboxMessagesWebChecked,
        },
        {
          type: "Student Enrollment",
          emailChecked: backendData.StudentEnrollmentEmailChecked,
          webChecked: backendData.StudentEnrollmentWebChecked,
        },
        {
          type: "Leave Request",
          emailChecked: backendData.LeaveRequestEmailChecked,
          webChecked: backendData.LeaveRequestWebChecked,
        },
        {
          type: "Course Completion",
          emailChecked: backendData.CourseCompletionEmailChecked,
          webChecked: backendData.CourseCompletionWebChecked,
        },
        {
          type: "Password Change",
          emailChecked: backendData.PasswordChangeEmailChecked,
          webChecked: backendData.PasswordChangeWebChecked,
        },
      ];
      setNotifications(mappedNotifications);
    }
  }, [PersonalNotificationData]);

  // Handle checkbox toggle
  const handleCheckboxChange = (index, field) => {
    setNotifications((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [field]: !item[field] } : item
      )
    );
  };

  // Function to handle updates (e.g., API call to update notifications)
  const handleUpdate = () => {
    console.log("Updated notifications:", notifications);
    // Call an API to save the updated state if required
    Dispatch(UpdateStudentPersonalNotificationData(UserId,notifications))
  };


  return (
    <div className="w-full flex flex-col gap-[10px]">
      <div className="w-[90%] flex flex-col gap-[10px] bg-[#F8FAFA] rounded-[9px] px-[20px] py-[15px]">
        <div className="flex justify-between">
          <p className="text-[#2D2366] text-[18px] font-bold">Type</p>
          <div className="flex gap-[40px]">
            <p className="text-[#2D2366] text-[18px] font-bold">E-mail</p>
            <p className="text-[#2D2366] text-[18px] font-bold">Web</p>
          </div>
        </div>
        {notifications.map((notification, index) => (
          <div key={index} className="flex justify-between">
            <p className="text-[#2D2366] text-[18px]">{notification.type}</p>
            <div className="flex gap-[60px] mr-[10px]">
              <input
                type="checkbox"
                checked={notification.emailChecked} // Set checkbox state based on backend data
                onChange={() => handleCheckboxChange(index, "emailChecked")}
                style={{
                  width: "16px",
                  height: "16px",
                  border: "1px solid black",
                  backgroundColor: "transparent",
                  position: "relative",
                }}
              />
              <input
                type="checkbox"
                checked={notification.webChecked} // Set checkbox state based on backend data
                onChange={() => handleCheckboxChange(index, "webChecked")}
                style={{
                  width: "16px",
                  height: "16px",
                  border: "1px solid black",
                  backgroundColor: "transparent",
                  position: "relative",
                }}
              />
            </div>
          </div>
        ))}
        <div className="w-[100%] flex justify-end mt-[20px]">
          <button
            className="w-[100px] h-[30px] rounded-[8px] bg-[#A1A3EF] text-[#FFFFFF] text-[18px]"
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalNotifications;
