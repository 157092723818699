import { createReducer } from "@reduxjs/toolkit";

const InstructorInitialState = {
    loading: false,
    Instructor: {},
    Instructors: [],
    Staffs:[],
    error: {},
    SInstructor: {},
    CourseInstructors: [],
    InstructorTimeTable: {},
    InstructorCourses: [],
    InstructorAddress:"",
    LiveLocation: null, // Add this field for live location coordinates
    SchoolContactNumber:"",
    InstituteUserType:null,
    EmergencyContacts:null,
    StaffTableData: null,
    InstructorNameAndOthers: null,
    ClassLocationsDetails: null,
};

export const InstructorReducer = createReducer(
    InstructorInitialState,
    (builder) => {
        builder.addCase("CreateInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("CreateInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Instructor = action.payload;
        });

        builder.addCase("CreateInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //Update Instructor  
        builder.addCase("UpdateToInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdateToInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.StaffTableData = action.payload;
        });

        builder.addCase("UpdateToInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //Instructors listing 
        builder.addCase("GetInstructorsRequest", (state) => {
            state.loading = true;
        });

       

       

        // //staff listings
        builder.addCase("GetStaffRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetStaffSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Staffs = action.payload;
        });

        builder.addCase("GetStaffFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });



        builder.addCase("GetInstructorsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Instructors = action.payload;
        });

        //Related to course Instructors list,
        builder.addCase("GetCourseInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetCourseInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.CourseInstructors = action.payload;
        });

        builder.addCase("GetCourseInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        

        builder.addCase("GetInstructorsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetInstructorStudentsRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorStudentsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Students = action.payload;
        });

        builder.addCase("GetInstructorStudentsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetSInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetSInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.SInstructor = action.payload;
        });

        builder.addCase("GetSInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("DeleteInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("DeleteInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            // Handle delete success if needed
        });

        builder.addCase("DeleteInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetCoursesOfSInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetCoursesOfSInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstructorCourses = action.payload;
        });

        builder.addCase("GetCoursesOfSInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetInstructorAndStudentAddressRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorAndStudentAddressSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstructorAddress = action.payload;
        });

        builder.addCase("GetInstructorAndStudentAddressFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //student otp send api
        builder.addCase("GetStudentNumberAndSendOTPRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetStudentNumberAndSendOTPSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetStudentNumberAndSendOTPFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


        //bypass otp feedback
        builder.addCase("GetBypassOtpFeedBackUpdateRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetBypassOtpFeedBackUpdateSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetBypassOtpFeedBackUpdateFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //instructor left location update
        builder.addCase("GetInstructorLeftLocationUpdateRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorLeftLocationUpdateSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetInstructorLeftLocationUpdateFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //update instructor side times in db
        builder.addCase("UpdateLocationsTimeRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdateLocationsTimeSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UpdateLocationsTimeFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //live location sending link
        builder.addCase("SendLiveLocationLinkRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("SendLiveLocationLinkSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("SendLiveLocationLinkFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })


        //upload screen recording to s3
        builder.addCase("UploadScreenRecordingToS3Request", (state) => {
            state.loading = true;
        });

        builder.addCase("UploadScreenRecordingToS3Success", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UploadScreenRecordingToS3Failure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //update live location lat and long
        builder.addCase("UpdateLiveLocationCoordinatesRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdateLiveLocationCoordinatesSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UpdateLiveLocationCoordinatesFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get live location lat and long
        builder.addCase("GetLiveLocationCoordinatesRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetLiveLocationCoordinatesSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.LiveLocation = action.payload;
            // state.LiveLocation = {
            //     latitude: action.payload.latitude,
            //     longitude: action.payload.longitude,
            // }; // Store the coordinates here
        
        });

        builder.addCase("GetLiveLocationCoordinatesFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //Make a call to school Institute
        builder.addCase("MakeACallToSchoolContactRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("MakeACallToSchoolContactSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.SchoolContactNumber = action.payload;
        });

        builder.addCase("MakeACallToSchoolContactFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //send Harassment Email and SMS for StudentEmergency
        builder.addCase("SendHarassmentStudentEmergencyAlertRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("SendHarassmentStudentEmergencyAlertSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("SendHarassmentStudentEmergencyAlertFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

         //get beacon and institute emergency contacts
         builder.addCase("GetBeaconAndInstituteEmergencyContactRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetBeaconAndInstituteEmergencyContactSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.EmergencyContacts = action.payload;
        });

        builder.addCase("GetBeaconAndInstituteEmergencyContactFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get institute userType Data
        builder.addCase("GetInstituteUserTypeRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstituteUserTypeSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstituteUserType = action.payload;
        });

        builder.addCase("GetInstituteUserTypeFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

         //change institute userType Data
         builder.addCase("ChangeInstituteUserTypeRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("ChangeInstituteUserTypeSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("ChangeInstituteUserTypeFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


         //change institute userType Data
         builder.addCase("SuspendInstituteUserRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("SuspendInstituteUserSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("SuspendInstituteUserFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //update institute userRole  to Staffmembers
        builder.addCase("UpdateInstituteUserRoleToStaffMembersRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdateInstituteUserRoleToStaffMembersSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UpdateInstituteUserRoleToStaffMembersFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //Update PickedStudent User Table Field
        builder.addCase("UpdatePickedStudentUserFieldRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdatePickedStudentUserFieldSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UpdatePickedStudentUserFieldFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

         //Update Instructor Live Location Coordinates
         builder.addCase("UpdateInstructorLiveLocationCoordinatesRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("UpdateInstructorLiveLocationCoordinatesSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("UpdateInstructorLiveLocationCoordinatesFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


         //Get Instructor Live Location Coordinates
         builder.addCase("GetInstructorLiveLocationCoordinatesRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorLiveLocationCoordinatesSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.LiveLocation = action.payload;
        });

        builder.addCase("GetInstructorLiveLocationCoordinatesFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Instructor Name, profile and Speciality Details
        builder.addCase("GetInstructorNameAndOtherDetailsRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorNameAndOtherDetailsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstructorNameAndOthers = action.payload;
        });

        builder.addCase("GetInstructorNameAndOtherDetailsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Instructor Name, profile and Speciality Details
        builder.addCase("GetClassLocationsDetailsRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetClassLocationsDetailsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.ClassLocationsDetails = action.payload;
        });

        builder.addCase("GetClassLocationsDetailsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //Save Student Live Location Coordinates
        builder.addCase("SaveStudentLiveLocationCoordinatesRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("SaveStudentLiveLocationCoordinatesSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("SaveStudentLiveLocationCoordinatesFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // getting the all classes of particular Teacher with their students
    }
);
