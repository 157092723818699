import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const GetStudentsA = () => async (dispatch) => {
    try {
        dispatch({
            type: "GetStudentsListRequest",
        });

        const { data } = await axiosInstance.get(`/api/Enrolled/students`);

        dispatch({
            type: "GetStudentsListSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStudentsListError",
            payload: error,
        });
    }
};

//Student list Enrollment Status Inprogress
export const GetStatusInProgressStudentsA = () => async (dispatch) => {
    try {
        dispatch({
            type: "GetStatusInProgressStudentsListRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Enrolled/StatusInProgress/students`
        );

        dispatch({
            type: "GetStatusInProgressStudentsListSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStatusInProgressStudentsListError",
            payload: error,
        });
    }
};

//GET Students with Course Enrollment status on Hold
export const GetStatusHoldStudentsA = () => async (dispatch) => {
    try {
        dispatch({
            type: "etStatusHoldStudentsListRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Enrolled/StatusHold/students`
        );

        dispatch({
            type: "GetStatusHoldStudentsListSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStatusHoldStudentsListError",
            payload: error,
        });
    }
};

export const GetClassScheduleA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetClassScheduleRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/course/schedule/${EnrollmentId}`
        );
        dispatch({
            type: "GetClassScheduleSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetClassScheduleError",
            payload: error,
        });
    }
};

// export const GetProgressAndCourse = (EnrollmentId) => async (dispatch) => {
//     try {
//         dispatch({
//             type: "GetAboutClientRequest",
//         });

//         const { data } = await axiosInstance.get(
//             `/api/courseprogress/${EnrollmentId}`
//         );
//         dispatch({
//             type: "GetAboutClientSuccess",
//             payload: data,
//         });
//     } catch (error) {
//         CheckLoginServer(error?.response?.data, dispatch);
//         dispatch({
//             type: "GetAboutClientError",
//             payload: error,
//         });
//     }
// };
export const CreateScheduleA = (Events,setSuccess) => async (dispatch) => {
    try {
        dispatch({
            type: "CreateScheduleRequest",
        });

        const { data } = await axiosInstance.post(
            `/api/schedule/create`,
            Events
        );
        setSuccess(true)
        dispatch({
            type: "CreateScheduleSuccess",
            payload: data,
        });
        
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "CreateScheduleFailure",
            payload: error,
        });
    }
};

export const UpdateScheduleA = (Events) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdateScheduleRequest",
        });

        const { data } = await axiosInstance.post(
            `/api/schedule/update`,
            Events
        );
        dispatch({
            type: "UpdateScheduleSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateScheduleFailure",
            payload: error,
        });
    }
};


export const ChangeScheduleStatus = (EventId, status, ClassFeedback) => async (dispatch) => {
    try {
        dispatch({
            type: "ChangeScheduleStatusRequest",
        });

        // Send EventId and status in the request body
        const { data } = await axiosInstance.put(
            `/api/schedule/changestatus`,
            { EventId, status, ClassFeedback }  // Send as an object
        );

         // Log the response data to ensure it's structured correctly
         console.log("Response data:", data);


        dispatch({
            type: "ChangeScheduleStatusSuccess",
            payload: data,  // Assuming data contains the updated schedule
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        
        dispatch({
            type: "ChangeScheduleStatusFailure",
            payload: error?.response?.data?.message || error.message, // Provide a specific error message
        });
    }
};


export const GetScheduleA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetTimeTableRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/timetable/${EnrollmentId}`
        );
        dispatch({
            type: "GetTimeTableSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetTimeTableError",
            payload: error,
        });
    }
};

export const GetSubscriptionStatus = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "SubscriptionRequest",
        });
        const { data } = await axiosInstance.get(
            `/api/Status/payment/${EnrollmentId}`
        );

        dispatch({
            type: "SubscriptionSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SubscriptionFailure",
            payload: error,
        });
    }
};

// Action to get user by ID
export const GetUserByIdA = (userId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetUserByIdRequest",
        });

        console.log("user id in action is:", userId)

        const { data } = await axiosInstance.get(`/api/getuserbyid/${userId}`);

        dispatch({
            type: "GetUserByIdSuccess",
            payload: data,
        });
    } catch (error) {
       // Serialize the error
       const serializedError = {
        message: error.message,
        code: error.code,
        response: error.response ? {
            status: error.response.status,
            data: error.response.data,
        } : null,
    };
        dispatch({
            type: "GetUserByIdFailure",
            payload: serializedError,

        });
    }
};

//update student settings field at the backend
export const UpdateSettingsField = (UserId,formData) => async (dispatch) => {
    try {
        console.log("UserId in UpdateSettingsField action is:", UserId)
        console.log("formData in UpdateSettingsField action is:", formData)

        dispatch({
            type: "UpdateSettingsFieldsRequest",
        });
        const { data } = await axiosInstance.post(
            `/api/student/settings/${UserId}`,
            formData
        );

        dispatch({
            type: "UpdateSettingsFieldsSuccess",
            payload: data,
        });
        return Promise.resolve(); // Indicate success
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateSettingsFieldsFailure",
            payload: error,
        });
        return Promise.reject(); // Indicate failure
    }
};

//Get student Notification data
export const GetStudentNotificationData = (UserId) => async (dispatch) => {
    try {
        console.log("UserId in GetStudentNotificationData action is:", UserId)

        dispatch({
            type: "GetStudentNotificationDataRequest",
        });
        const { data } = await axiosInstance.post(
            `/api/student/settings/NotificationsData/${UserId}`
        );

        dispatch({
            type: "GetStudentNotificationDataSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStudentNotificationDataFailure",
            payload: error,
        });
    }
};

//Update student Notification Personal data
export const UpdateStudentPersonalNotificationData = (UserId, notifications) => async (dispatch) => {
    try {
        console.log("UserId in UpdateStudentPersonalNotificationData action is:", UserId)
        console.log("notifications in UpdateStudentPersonalNotificationData action is:", notifications)

        dispatch({
            type: "UpdateStudentNotificationDataRequest",
        });
        const { data } = await axiosInstance.post(
            `/api/student/settings/UpdateNotificationsData/${UserId}`,
            { notifications }
        );

        dispatch({
            type: "UpdateStudentNotificationDataSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateStudentNotificationDataFailure",
            payload: error,
        });
    }
};
