import { createReducer } from "@reduxjs/toolkit";

const StudentInitialState = {
    loading: false,
    Student: {},
    Students: [],
    error: {},
    Schedule: [],
    StatusInProgressStudents: [],
    StatusHoldStudents: [],
    StatusChanged: null,
    statusChangeSuccess: null,  // To track the success/failure of the status change
    PersonalNotificationData:"",
};

export const StudentReducer = createReducer(StudentInitialState, (builder) => {
    builder.addCase("GetStudentsListRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetStudentsListSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Students = action.payload;
    });

    builder.addCase("GetStudentsListFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    //Student list Enrollment Status Inprogress
    builder.addCase("GetStatusInProgressStudentsListRequest", (state) => {
        state.loading = true;
    });

    builder.addCase(
        "GetStatusInProgressStudentsListSuccess",
        (state, action) => {
            state.loading = false;
            state.error = {};
            state.StatusInProgressStudents = action.payload;
        }
    );

    builder.addCase("GetStatusInProgressStudentsListError", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    //Students list course Enrollment status on Hold
    builder.addCase("GetStatusHoldStudentsListRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetStatusHoldStudentsListSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.StatusHoldStudents = action.payload;
    });

    builder.addCase("GetStatusHoldStudentsListError", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder.addCase("GetAboutClientRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("GetAboutClientSuccess", (state, action) => {
        console.log("GetAboutClientSuccess State", state);
        console.log("GetAboutClientSuccess Action", action);

        state.loading = false;
        state.error = {};
        state.CourseProgress = action.CourseProgress;
        state.CoursePackage = action.CoursePackage;
        state.Student = action.Student;
        state.ScheduleTimeTable = action.ScheduleTimeTable;
        state.StudentInfo = action.StudentInfo;
    });

    builder.addCase("GetAboutClientFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder.addCase("GetClassScheduleRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("GetClassScheduleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Schedule = action.payload;
    });

    builder.addCase("GetClassScheduleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder.addCase("CreateScheduleRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("CreateScheduleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Schedule = action.payload;
    });

    builder.addCase("CreateScheduleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder.addCase("UpdateScheduleRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("UpdateScheduleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Schedule = action.payload;
    });

    builder.addCase("UpdateScheduleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    //
    builder.addCase("GetTimeTableRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("GetTimeTableSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Schedule = action.payload;
    });

    builder.addCase("GetTimeTableFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder.addCase("SubscriptionRequest", (state, action) => {
        state.loading = true;
    });

    builder.addCase("SubscriptionSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Subscription = action.payload;
    });

    builder.addCase("SubscriptionFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    // New cases for fetching user by ID
    builder.addCase("GetUserByIdRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetUserByIdSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Student = action.payload; // Set Student instead of User
    });

    builder.addCase("GetUserByIdFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });


    builder.addCase("ChangeScheduleStatusRequest", (state, action) => {
        state.loading = true;
        state.statusChangeSuccess = null;  // Reset to null when the request starts
    });
    
    builder.addCase("ChangeScheduleStatusSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.StatusChanged = action.payload;
        state.statusChangeSuccess = action.payload.success;  // Set to true when the status change succeeds
    });
    
    builder.addCase("ChangeScheduleStatusFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.statusChangeSuccess = false;  // Set to false when the status change fails
    });


    // New cases for UpdateSettingsFieldsRequest  
    builder.addCase("UpdateSettingsFieldsRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("UpdateSettingsFieldsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
    });

    builder.addCase("UpdateSettingsFieldsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

     // New cases for GetStudentNotificationData 
     builder.addCase("GetStudentNotificationDataRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetStudentNotificationDataSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.PersonalNotificationData = action.payload;
    });

    builder.addCase("GetStudentNotificationDataFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    // New cases for GetStudentNotificationData 
    builder.addCase("UpdateStudentNotificationDataRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("UpdateStudentNotificationDataSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
    });

    builder.addCase("UpdateStudentNotificationDataFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });


    
});
