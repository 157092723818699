import React from "react";
import { DateInput } from "../../../../Components/InputComps/DateComps";
import Gender from "./ContactAndOtherInfoComp/Gender";
import {
    Input,
    SelectList,
} from "../../../../Components/InputComps/InputComps";
import { GenderOptions } from "../../../Institute/AddStaff/OptionsArr/OptionsArr";
import Schedule from "./schedule/Schedule";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './Contact.css'

const ContactAndOtherInfo = ({
    Styling,
    EnrollmentData,
    setEnrollmentData,
    Err,
    setShowTimeSlots,
    OnChange,
    UserInformation,
    countryCode
}) => {
    const { InputTypeTextStyle, DivStyle, HeadingStyle } = Styling;
    console.log("Country Code in phone contact is :",countryCode)

    return (
        <>
            <div className={DivStyle}>
                {/* <label htmlFor='PhoneNumber' className={HeadingStyle}>Phone Number</label>

                <h4 className='font-normal text-[red]'> {Err?.PhoneNumber ? Err?.PhoneNumber : null}</h4>

                <input className={InputTypeTextStyle} type="text" placeholder='0123-456-7891' id='PhoneNumber' required
                    value={EnrollmentData?.StudentData?.PhoneNumber}
                    onChange={(e) => OnChange(e, "PhoneNumber")}
                /> */}
                <div className="w-full ">
                {/* Error Message Display */}
                
                <label htmlFor="PhoneNumber" className="text-3xs md:text-2xs xl:text-xs mb-5">
                    Phone Number *
                </label>

                {Err?.PhoneNumber && (
                <h4 className="font-normal text-[red] text-right">{Err.PhoneNumber}</h4>
                )}
                       
                <PhoneInput  // library to automatically show the country phone code  
        country={countryCode} // Automatically sets the country code
        value={EnrollmentData?.StudentData?.PhoneNumber}
        onChange={(phone) => OnChange({ target: { value: phone } }, "PhoneNumber")}
        inputProps={{
          name: "PhoneNumber",
          required: true,
          readOnly: UserInformation && UserInformation?.PhoneNumber ? true : false,
        }}
        containerClass={"PhoneNumberInput"}
        inputClass={"CustomPhoneNumberInput"}
        specialLabel={"Phone Number *"}
        inputStyle={{
          width: "100%",
          height: "45px",
        //   padding: "5px",
        //   fontSize: "16px",
        }}
      />
       </div>
      </div>

      {/* <div className={DivStyle}>
        <div className="w-full ">
            <label htmlFor="PhoneNumber" className="text-3xs md:text-2xs xl:text-xs mb-5">
                Emergency Phone Number *
            </label>
                
               
            <PhoneInput  // library to automatically show the country phone code  
            country={countryCode} // Automatically sets the country code
            value={EnrollmentData?.StudentData?.EmergencyPhoneNumber}
            onChange={(phone) => OnChange({ target: { value: phone } }, "EmergencyPhoneNumber")}
            inputProps={{
            name: "EmergencyPhoneNumber",
            required: true,
            readOnly: UserInformation && UserInformation?.EmergencyPhoneNumber ? true : false,
            }}
            containerClass={"PhoneNumberInput"}
            inputClass={"CustomPhoneNumberInput"}
            specialLabel={"Emergency Phone Number *"}
            inputStyle={{
            width: "100%",
            height: "45px",
            //   padding: "5px",
            //   fontSize: "16px",
            }}
        />
        </div>
      </div> */}

            <div className={DivStyle}>
                {/* <label htmlFor="Email" className={HeadingStyle}>
                    Email Address
                </label>

                <h4 className="font-normal text-[red]">
                    {" "}
                    {Err?.Email ? Err?.Email : null}
                </h4>

                <input
                    className={InputTypeTextStyle}
                    type="email"
                    placeholder="abc@gmail.com"
                    id="Email"
                    required
                    value={EnrollmentData?.StudentData?.Email}
                    onChange={(e) => OnChange(e, "Email")}
                /> */}

                <Input
                    readOnly={UserInformation && UserInformation?.Email ? true : false}
                    Label="Email Address *"
                    Placeholder="abc@gmail.com"
                    Id="Email"
                    Err={Err?.Email}
                    State={EnrollmentData?.StudentData?.Email}
                    // State={InstructorData.Email}
                    onChange={(e) => OnChange(e, "Email")}
                />
            </div>
            <div className={DivStyle}>

                <DateInput
                    instructor={true}
                    readOnly={UserInformation && UserInformation?.StudentInfo?.Gender ? true : false}
                    style="w-full"
                    Id={"DOB"}
                    Err={Err?.DOB}
                    // State={InstructorData.DOB}
                    State={EnrollmentData?.StudentData?.DOB}
                    onChange={(e) => OnChange(e, "DOB")}
                />
            </div>
            {/* <DateInput
                State={EnrollmentData?.StudentData?.DOB}
                Err={Err?.DOB}
                onChange={(e) => OnChange(e, "DOB")}
            /> */}

            {/* <Gender
                Styling={Styling}
                EnrollmentData={EnrollmentData}
                Err={Err}
                onChange={(e) => OnChange(e, "Gender")}
            /> */}
            <div className={DivStyle}>
                <div className="w-full sm:mt-[20px] lg:mt-[0px]">

                    <SelectList
                        readOnly={UserInformation && UserInformation?.StudentInfo?.Gender ? true : false}
                        Label="Gender *"
                        Id="Gender"
                        defaultValue=""
                        selectedValue={'Male'}
                        Text="Gender"
                        Err={Err?.Gender}
                        OptionsArr={GenderOptions}
                        State={EnrollmentData?.StudentData?.Gender === undefined ? "" : EnrollmentData?.StudentData?.Gender}
                        onChange={(e) => OnChange(e, "Gender")}
                    />
                </div>
            </div>
           
            {/* <div
                className={
                    "mt-7 flex w-full max-w-[575px] flex-col items-start  justify-center sm:w-[45%]"
                }
            >
                <label htmlFor="Schedule" className={""}>
                    Create Your Own Class Schedule
                </label>

                <h4 className="font-normal text-[red]">
                    {" "}
                    {Err?.Schedule ? Err?.Schedule : null}
                </h4>

                <button
                    type="button"
                    id="Schedule"
                    className="cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-5 py-2 text-base text-white"
                    onClick={() => setShowTimeSlots(true)}
                >
                    Create Schedule
                </button>
            </div> */}
        </>
    );
};

export default ContactAndOtherInfo;
