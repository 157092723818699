import React, { useEffect, useState } from 'react'
import PickupServiceImage from './Assets/PickupServiceImage.svg';
import MyCourses from '../../Components/MyCourses/MyCourses'
import { EnrolledCourseButtonsInfo } from '../../PageNames'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GetStudentTimeTableForTheEnrolledCourse } from '../../Actions/CourseA';
import { GetPickedStudentFieldFromUsers } from '../../Actions/UserA';
import { GetLocalStorage } from '../../Helpers/LocalStorage/LocalStorage';
import { useNavigate } from 'react-router-dom';

const PickupServiceComponentPage = () => {
    const { EnrollmentId } = useParams();
    console.log("EnrollmentId in PickupServiceComponentPage is:",EnrollmentId)
    const Dispatch = useDispatch()
    const navigate = useNavigate();
    const { enrolledCourseTimeTable } = useSelector((Store) => Store.CourseReducer);
    const { PickedStudentField } = useSelector((store) => store.LoginSignupReducer);
    const UserId = GetLocalStorage("UserInfo")?.UserId;
    console.log("enrolledCourseTimeTable in PickupServiceComponentPage is:",enrolledCourseTimeTable)
    console.log("UserId in PickupServiceComponentPage is:",UserId)
    console.log("PickedStudentField is:",PickedStudentField?.PickedStudentField)
    const userPickedStudentField = PickedStudentField?.PickedStudentField;
    const [nextClass, setNextClass] = useState(null); // State to hold the next class
    const [studentAddress, setStudentAddress] = useState("");

    useEffect(() => {
        // Dispatching the action when the component mounts
        if(UserId){
            Dispatch(GetPickedStudentFieldFromUsers(UserId));
        }
    }, [Dispatch]); // Dependency array includes dispatch

    useEffect(() => {
        if (EnrollmentId) Dispatch(GetStudentTimeTableForTheEnrolledCourse(EnrollmentId))
      }, [Dispatch, EnrollmentId]);

      useEffect(() => {
        if (enrolledCourseTimeTable) {
          // Extract the timetable and address from the data
          const { timetable, studentAddress } = enrolledCourseTimeTable;
          setStudentAddress(studentAddress);
    
          // Find the next class by filtering and sorting by date
          const upcomingClasses = timetable
            .map((classInfo) => ({
              ...classInfo,
              startDate: new Date(classInfo.startDate),
            }))
            .filter((classInfo) => classInfo.startDate > new Date())
            .sort((a, b) => a.startDate - b.startDate);
    
          if (upcomingClasses.length > 0) {
            setNextClass(upcomingClasses[0]); // Set the nearest upcoming class
          }
        }
      }, [enrolledCourseTimeTable]);

    return (
        <div className='w-full'>
            <div className='flex flex-col justify-center items-center'>
                <img src={PickupServiceImage} alt="pickup image" className='w-[300px] h-[300px] lg:w-[400px] lg:h-[400px] mt-[-10px]' />
                <p className='font-bold text-[24px] lg:text-[28px] text-center w-[320px] lg:w-[500px] text-[#4C34AD] mt-[-20px]'>The Navigation System Will Work Once The Instructor Initiate Class.</p>
                <p className='text-[16px] lg:text-[18px] text-center w-[320px] lg:w-[500px] mt-[15px]'>
                    You will be able to see instructor location and pickup location in the map once the instructor leave for the pickup zone.
                </p>
                {/* <p className='text-[18px] text-center mt-[20px]'>Your next In-Vehicle class is on</p>
                <p className='text-[18px] text-center font-bold mt-[5px]'>17/10/2024 | 04 : 30 PM.</p>
                <p className='text-[18px] text-center font-bold mt-[5px]'>Pickup Zone</p>
                <p className='text-[18px] text-center font-bold mt-[5px]'>205 Birch Pl, Ignace, ON P0T 1T0, Canada</p> */}

                {nextClass ? (
                    <>
                        <p className="text-[18px] text-center mt-[20px]">
                        Your next In-Vehicle class is on
                        </p>
                        <p className="text-[18px] text-center font-bold mt-[5px]">
                        {nextClass.startDate.toLocaleDateString()} |{" "}
                        {nextClass.startDate.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}
                        </p>
                        <p className="text-[18px] text-center font-bold mt-[5px]">
                        Pickup Zone
                        </p>
                        <p className="text-[18px] text-center font-bold mt-[5px]">
                        {studentAddress}
                        </p>
                    </>
                    ) : (
                    <p className="text-[18px] text-center mt-[20px]">
                        No upcoming classes found.
                    </p>
                    )}


                <div className='flex justify-center items-center gap-[10px] lg:gap-[15px] mt-[30px]'>
                    <button className='w-[150px] lg:w-[180px] h-[40px] text-[14px] lg:text-[16px] text-center text-[#4C34AD] rounded-[10px] font-bold cursor-pointer'
                    style={{
                        border: '1px solid #4C34AD'
                    }}>
                        Update Pickup Zone
                    </button>

                    <button className={`w-[150px] lg:w-[180px] h-[40px] text-[14px] lg:text-[16px] text-center rounded-[10px] text-[#FFFFFF] font-bold ${
                            userPickedStudentField
                                ? 'bg-[#4C34AD] cursor-pointer'
                                : 'bg-[#D7DAE2] cursor-not-allowed'
                        }`}
                        disabled={!userPickedStudentField} // Disable if false or null
                        onClick={() => navigate(`/track-instructor-location/${EnrollmentId}`)}
                    >
                        Track Instructor
                    </button>
                </div>
           </div>
        </div>
    )
}

const PickupService = () =>
  <MyCourses ButtonsInfo={EnrolledCourseButtonsInfo} PageName="PickupService" Component={PickupServiceComponentPage} />


export default PickupService